import { FormBuilder, Validators, AbstractControl, FormGroup } from '@angular/forms';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { NotificationService } from '../../../core/services';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-phone-confirmation',
    templateUrl: './phone-confirmation.component.html',
    styleUrls: ['./phone-confirmation.component.scss'],
})
export class PhoneConfirmationComponent implements OnInit {
    @Output() codeRequest = new EventEmitter<string>();
    @Output() codeConfirm = new EventEmitter<any>();
    @Input() phoneSended: boolean;
    @Input() sending: boolean;
    @Input() showErrorConfirmedCode: boolean;
    public lastPhoneNumber: string;
    public form: FormGroup;
    private sendedPhones: string[] = [];
    private _confirmed: boolean;
    private _value: string;
    private _sendedCode: boolean;
    constructor(
        private notificationService: NotificationService,
        private fb: FormBuilder,
        private cookieService: CookieService,
    ) {
        this.form = this.fb.group({
            phoneNumber: ['+7 (', [Validators.required, Validators.minLength(17)]],
            code: ['', Validators.required],
        });
    }

    get confirmed(): boolean {
        return this._confirmed;
    }
    get sendedCode(): boolean {
        return this._sendedCode;
    }

    @Input()
    set confirmed(confirmed: boolean) {
        let func = 'disable';

        if (!confirmed) {
            func = 'enable';
        }

        Object.values(this.form.controls).forEach(it => it[func]());

        this._confirmed = confirmed;
    }

    get value(): string {
        return this._value;
    }

    @Input()
    set value(val: string) {
        if (val) {
            const raw = val.replace(/(\+7)|(\D)/g, '');
            const phone = raw.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '+7 ($1) $2-$3');
            this.form.controls.phoneNumber.patchValue(phone);
        }
    }

    getError(control: AbstractControl, error: string) {
        return control.touched && control.errors && error in control.errors;
    }

    requestCode() {
        const phones = 'phones';
        this.sendedPhones = this.cookieService.get(phones).split(',');
        const value = this.form.controls.phoneNumber.value;
        const phone = '+' + value.replace(/(\D)/g, '');
        if (this.sendedPhones.includes(phone)) {
            const text = 'На этот номер уже был отправлен код. Попробуйте позже';
            this.notificationService.showSnackNotification(text, 'Закрыть', 3000);
            return;
        }
        const today = new Date();
        const expiresValue = new Date(today);
        expiresValue.setSeconds(today.getSeconds() + 300);
        this.cookieService.set(phones, phone, expiresValue);
        this.sendedPhones.push(phone);
        this.codeRequest.emit(phone);
        this.lastPhoneNumber = phone;
    }

    confirmCode() {
        const value = {
            code: this.form.controls.code.value,
            phoneNumber: this.lastPhoneNumber,
        };
        this._sendedCode = true;
        this.codeConfirm.emit(value);
    }

    ngOnInit() {}
}
