import { FormGroup } from '@angular/forms';

export class FormGroupExtended {
    public static markAllAsTouched(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            if (control.controls) { // control is a FormGroup
                FormGroupExtended.markAllAsTouched(control);
            } else { // control is a FormControl
                control.markAsTouched();
            }
        });
    }
}
