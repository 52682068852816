import {
    Component,
    ViewChild,
    ViewContainerRef,
    OnInit,
    ComponentFactoryResolver,
    Type,
    OnDestroy,
} from '@angular/core';
import { ISaveSupport } from '../../isavesupport';
import { NotificationService } from '../../../core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentProcessingService } from '../../../core/services/document-processing.service';
import { ChangesService } from '../../../core/services/changes.service';
import { DocumentMediator } from '../../services/document-mediator.service';
import { MediatorRequest } from '../../../core/mediator/IMediatorRequest';

@Component({
    selector: 'document-edit-page',
    templateUrl: './document-edit-page.component.html',
    styleUrls: ['./document-edit-page.component.scss'],
})
export class DocumentEditPageComponent implements OnInit, OnDestroy {
    @ViewChild('documentCardEditComponent') documentCardEditComponent: ISaveSupport;
    @ViewChild('documentTypeEditComponent', { read: ViewContainerRef }) documentTypeEditComponent: ViewContainerRef;
    private docTypeComponent: ISaveSupport;
    private currentDocumentType: string;
    private uniqueId: string;
    private hasAnyChanges: boolean;
    private docTypeComponentRef: any;
    constructor(
        private factoryResolver: ComponentFactoryResolver,
        private notificationService: NotificationService,
        private documentProcessingService: DocumentProcessingService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private _changesService: ChangesService,
        private mediator: DocumentMediator,
    ) {}
    ngOnInit(): void {
        this.activatedRoute.params.subscribe(x => {
            this.currentDocumentType = x.documentType;
            this.uniqueId = x.uniqueCode;
            const type = <Type<ISaveSupport>>(
        this.documentProcessingService.getEditDocumentDetailsComponent(this.currentDocumentType)
      );
            const docTypeFactory = this.factoryResolver.resolveComponentFactory(type);
            this.docTypeComponentRef = docTypeFactory.create(this.documentTypeEditComponent.parentInjector);
            this.docTypeComponent = <ISaveSupport>this.docTypeComponentRef.instance;
            this.documentTypeEditComponent.insert(this.docTypeComponentRef.hostView);
        });
        this._changesService.changed = false;
        this.mediator.subscribe('modelChanged', handleChanges => {
            if (handleChanges) {
                this.hasAnyChanges = true;
            } else {
                this.hasAnyChanges = false;
            }
        });
    }

    saveDocument() {
        const isDocumentCardPageValid = this.mediator.send(new MediatorRequest('validateDocumentCard'));
        if (!isDocumentCardPageValid) {
            return;
        }
        const isDetailsPageValid = this.mediator.send(new MediatorRequest('validateDetailsData'));
        if (!isDetailsPageValid) {
            return;
        }
        const documentCount = this.docTypeComponentRef.instance.getCountDocument();
        if (this.currentDocumentType !== 'avr') {
            if (documentCount < 1) {
                this.notificationService.showNotification('Количество файлов не должно быть меньше 1');
                return;
            }
        }
        const cardData = this.mediator.send<any>(new MediatorRequest('getCardData'));
        const detailsData = this.mediator.send(new MediatorRequest('getDetailsData'));
        this.documentProcessingService
            .editDocument(cardData, detailsData, this.currentDocumentType, this.uniqueId)
            .subscribe(resp => {
                if (resp.data) {
                    this.notificationService.showNotification(`Данные успешно сохранены - ${resp.data}`, () => {
                        this.router.navigate([`cabinet/view/${this.currentDocumentType}/${this.uniqueId}`]);
                    });
                } else {
                    this.notificationService.showNotification(resp.error.text);
                }
            });
    }
    isChanges() {
        return this._changesService.changed ? this._changesService.changed : this.hasAnyChanges;
    }
    goBack() {
        const documentCount = this.docTypeComponentRef.instance.getCountDocument();
        if (this.currentDocumentType !== 'avr') {
            if (documentCount < 1) {
                this.notificationService.showNotification('Количество файлов не должно быть меньше 1');
                return;
            }
        }
        this.router.navigate([`cabinet/view/${this.currentDocumentType}/${this.uniqueId}`]);
    }
    ngOnDestroy(): void {
        this.mediator.unsubscribe('modelChanged');
    }
}
