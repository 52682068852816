import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../models/apiresult.model';
import { NotificationSettings } from '../models/notification-settings.model';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    constructor(private http: HttpClient) {}

    saveSettings(params: { oldPassword: string; password: string; confirmPassword: string }) {
        return this.http.post<ApiResult<boolean>>('/api/registration/changepassword', params).pipe(
            map(resp => resp),
        );
    }
    saveNotificationSettings(param: any) {
        return this.http.post<ApiResult<boolean>>('/api/settings', param).pipe(
            map(result => result),
        );
    }
    getNotificationSettings() {
        return this.http.get<ApiResult<NotificationSettings>>('/api/settings').pipe(
            map(response => response),
        );
    }
    savePhone(params: any) {
        return this.http.post<ApiResult<boolean>>('/api/registration/changephone', params).pipe(
            map(resp => resp),
        );
    }
    getPhone() {
      return this.http.get<ApiResult<string>>('/api/registration/getPhone').pipe(
            map(resp => resp),
        );
    }
}
