import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
    selector: 'ofert-popup',
    templateUrl: './ofert.popup.component.html',
})
export class OfertPopupComponent {
    constructor(private route: ActivatedRoute, private router: Router) {}
}
