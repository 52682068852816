import { Counterparty, Contract } from './../models/avr-detail.info';
import { IMediator } from './../../core/mediator/IMediator';
import { FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { Subject, Subscription, of } from 'rxjs';
import { IDocumentStateBase } from './iDocumentStateBase';
import { AvrDetail } from '../models/avrDetail';
import { formatDate } from '@angular/common';
import { AvrDocumentHttpService } from '../../core/http.services/avr-document-http.service';
import { nonZero } from '../../core/validators/nonZero';
import { NotificationService } from '../../core/services';
import { NullString } from '../../../app/core/helpers/nullStringDecorator';
import { FormGroupExtended } from '../../../app/core/helpers/form-group.extension';
import { xinValidator } from '../../core/validators/xin.validator';
import { PhoneMaskHelper } from '../../core/directives/phone-mask.directive';

export class AvrState implements IDocumentStateBase<any> {
    today: string;
    onStateChanged: Subject<any> = new Subject();
    public contractForm: FormGroup;
    public contractorForm: FormGroup;
    public fileAttachments: IFileAttachmentResponse[] = [];
    public completedWorkList: AvrDetail[] = [];
    public docList: any[] = [];
    public subscriptions: Subscription[] = [];
    public hasAnyChanges = false;
    public isQrInDocument = false;

    /**
     *
     */
    constructor(
        private avrDocumentHttpService: AvrDocumentHttpService,
        private fb: FormBuilder,
        private mediator: IMediator,
        private notificationService: NotificationService,
    ) {
        this.today = new Date().toISOString().substring(0, 10);
        this.contractorForm = this.fb.group({
            senderName: ['', Validators.required],
            senderBin: ['', Validators.required],
            senderAddress: [''],
            senderPhone: ['', Validators.minLength(17)],
            recipientName: ['', Validators.required],
            recipientBin: ['', [Validators.required, xinValidator('any')]],
            recipientAddress: [''],
            recipientPhone: ['', Validators.minLength(17)],
        });
        this.contractForm = this.fb.group({
            contractName: ['', AvrState.requiredContractInfoIfContractIsExists],
            contractDate: [this.today, AvrState.requiredContractInfoIfContractIsExists],
            inputAppStock: [''],
            inputAppPages: ['', Validators.compose([Validators.required, nonZero()])],
            isContractAbsent: [false],
        });
        this.contractForm.valueChanges.subscribe(r => {
            this.mediator.publish({ type: 'modelChanged', message: true });
        });
        this.contractorForm.valueChanges.subscribe(() => {
            this.mediator.publish({ type: 'modelChanged', message: true });
        });
        this.mediator.publish({ type: 'setDocumentType', message: 'avr' });
        mediator.handle('tryChangeQrAndGetValue', (isQr: boolean) => {
          if (this.validateAttachmentsForQr(isQr)) {
            this.isQrInDocument = isQr;
          }
          return this.isQrInDocument;
        });
        this.initValueChangeSubscription();
        this.initMediator();
    }

    static requiredContractInfoIfContractIsExists(formControl: AbstractControl) {
        if (!formControl.parent) {
            return null;
        }
        if (!formControl.parent.get('isContractAbsent').value) {
            return Validators.required(formControl);
        }
        return null;
    }
    public getRawValues() {
        return {
            ...this.contractForm.getRawValue(),
            ...this.contractorForm.getRawValue(),
        };
    }

  public finalizeState(): void {
    this.mediator.unsubscribe('setId');
    this.subscriptions.forEach(it => it.unsubscribe());
    this.mediator.unsubscribe('disableContractorFields');
    this.mediator.unsubscribe('avrDocumentDataReceived');
    this.mediator.unsubscribe('setDocumentContractorInfo');
    this.mediator.unHandle('getDetailsData');
    this.mediator.unHandle('validateDetailsData');
    this.mediator.unHandle('tryChangeQrAndGetValue');
  }

    private initMediator() {
        this.mediator.subscribe('setId', messageData => {
            this.setId(messageData);
        });
        this.mediator.subscribe('disableContractorFields', data => {
            this.disableContractorFields();
        });
        this.mediator.subscribe('avrDocumentDataReceived', data => {
            this.initializedEditing(data);
        });
        this.mediator.subscribe('setDocumentContractorInfo', data => {
            if (!this.contractForm.get('isContractAbsent').value) {
                this.contractForm.patchValue(data);
            }
        });
        this.mediator.handle('getDetailsData', () => this.getAvrData());
        this.mediator.handle('validateDetailsData', () => this.validate());
    }

    private disableContractorFields() {
        this.contractorForm['recipientName'].reset();
        this.contractorForm['recipientName'].enable();
        this.contractorForm['recipientBin'].reset();
        this.contractorForm['recipientBin'].enable();
        this.contractorForm['recipientAddress'].reset();
        this.contractorForm['recipientAddress'].enable();
        this.contractorForm['recipientPhone'].reset();
        this.contractorForm['recipientPhone'].enable();
    }

    private setId(id: string) {
        this.setRecipient(id);
    }


  private validateAttachmentsForQr(qrAttached: boolean) {
    if (qrAttached && this.fileAttachments.length > 0) {
      var allExtensionAllowed = this.fileAttachments.every(x => x.fileName.endsWith(".pdf") || x.fileName.endsWith(".docx"));
      if (!allExtensionAllowed) {
        this.notificationService.showNotification('Размещение QR-кода возможно только на файлах формата docx и pdf');
        return false;
      }
    }
    return true;
  }

  private validate() {
    this.contractForm.updateValueAndValidity();
    const rawVal = this.contractForm.getRawValue();
    if (!this.contractForm.valid || !this.contractorForm.valid) {
      FormGroupExtended.markAllAsTouched(this.contractForm);
      FormGroupExtended.markAllAsTouched(this.contractorForm);
      return false;
    }
    if (!this.validateAttachmentsForQr(this.isQrInDocument)) {
      return false;
    }
    if (this.completedWorkList.length < 1) {
      this.notificationService.showNotification('Необходимо добавить сведения о выполненных работах');
      return false;
    }
    if (this.fileAttachments.length > 0 || this.docList.length > 0) {
      if (this.contractForm.controls['inputAppPages'].invalid) {
        this.notificationService.showNotification('Необходимо удалить вложения или указать количество страниц приложения больше 0');
        return false;
      }
      if (rawVal.inputAppPages < 1) {
        this.notificationService.showNotification(
          'Необходимо удалить вложения или указать количество страниц приложения больше 0',
        );
        return false;
      }
    }
    if (this.contractForm.controls['contractName'].invalid) {
      this.notificationService.showNotification('Необходимо заполнить контракт');
      return false;
    }
    if (this.contractForm.controls['contractDate'].errors) {
      this.notificationService.showNotification('Необходимо заполнить дату контракта');
      return false;
    }
    return true;
  }

    private initValueChangeSubscription() {
        this.contractForm.get('isContractAbsent').valueChanges.subscribe(value => {
            if (value) {
                this.contractForm.patchValue({
                    contractName: null,
                    contractDate: null,
                });
            }
            this.contractForm.get('contractName').updateValueAndValidity();
            this.contractForm.get('contractDate').updateValueAndValidity();
        });
    }

  private initializedEditing(data: AvrDocument) {
    // TODO KIRILL ADD CHECK FOR contract.date;
    const isContractAbsent = !data.contract.name;
    this.contractorForm.setValue({
      senderName: data.sender.name,
      senderBin: data.sender.bin,
      senderAddress: data.sender.address ? data.sender.address : '',
      senderPhone: data.sender.phone ? PhoneMaskHelper.process(data.sender.phone, false) : '',
      recipientName: data.recipient.name,
      recipientBin: data.recipient.bin,
      recipientAddress: data.recipient.address ? data.recipient.address : '',
      recipientPhone: data.recipient.phone ? PhoneMaskHelper.process(data.recipient.phone, false) : ''
    });
    this.contractForm.setValue({
      contractName: data.contract.name ? data.contract.name : '',
      contractDate: formatDate(data.contract.date, 'yyyy-MM-dd', 'en'),
      inputAppStock: data.stock,
      inputAppPages: data.contract.page,
      isContractAbsent: isContractAbsent,
    });
    data.workList.forEach(work => {
      this.completedWorkList.push(work);
    });
    this.mediator.publish({ type: 'setBasisDropdownValue', message: data.contract.name });
    this.mediator.publish({ type: 'modelChanged', message: false });
  }

    private getAvrData() {
        const rawVal = { ...this.contractForm.getRawValue(), ...this.contractorForm.getRawValue() };
        const workList: Array<AvrDetail> = this.completedWorkList;
        // potential deleted field;
        const stock = rawVal.inputAppStock;
        const files = this.fileAttachments;
        const senderPhone = this.normalizePhone(rawVal.senderPhone);
        const recipientPhone = this.normalizePhone(rawVal.recipientPhone);
        const sender: Counterparty = {
            name: rawVal.senderName,
            bin: rawVal.senderBin,
            address: rawVal.senderAddress,
            phone: senderPhone,
        };
        const recipient: Counterparty = {
            name: rawVal.recipientName,
            bin: rawVal.recipientBin,
            address: rawVal.recipientAddress,
            phone: recipientPhone,
        };
        const contract: Contract = {
            name: rawVal.contractName,
            date: rawVal.contractDate,
            page: rawVal.inputAppPages,
      };
        const isContractAbsent = rawVal.isContractAbsent;
        return {
            sender,
            recipient,
            contract,
            isContractAbsent,
            workList,
            stock,
            files,
        };
    }

    private normalizePhone(phone: string) {
        if (phone && phone.length > 4) {
            return this.formatterPhone(phone);
      }
      return null;
    }

    private formatterPhone(value) {
        return '+' + value.replace(/(\D)/g, '');
    }

  // TODO move to actions;
  private setRecipient(id) {
    const frm = this.contractorForm;
    let ctrl;
    // todo: дополнить основание | проверить дополнил ли я основание
    if (id) {
      const sub = this.avrDocumentHttpService.getRecipientById(id).subscribe(recipient => {
        if (!recipient) {
          this.mediator.publish({ type: 'modelChanged', message: false });
          return;
        }
        const phone = recipient.phone === undefined ? '' : recipient.phone;
        const address = `${NullString(recipient.city)} ${NullString(recipient.address)}`;
        ctrl = frm.get('recipientName');
        ctrl.setValue(recipient.orgName);
        ctrl.disable();
        ctrl = frm.get('recipientBin');
        ctrl.setValue(recipient.xin);
        ctrl.disable();
        ctrl = frm.get('recipientAddress');
        if (address) {
          ctrl.setValue(address);
        }
        ctrl = frm.get('recipientPhone');
        if (phone) {
          ctrl.setValue(PhoneMaskHelper.process(phone, false));
        }
        this.mediator.publish({ type: 'modelChanged', message: false });
      });
      this.subscriptions.push(sub);
    }
  }
  // private markFormGroupTouched(formGroup: FormGroup) {
  //     (<any>Object).values(formGroup.controls).forEach(control => {
  //         if (control.controls) { // control is a FormGroup
  //             this.markFormGroupTouched(control);
  //         } else { // control is a FormControl
  //             control.markAsTouched();
  //         }
  //     });
  // }
}

export interface AvrDocument {
    sender: Counterparty;
    recipient: Counterparty;
    contract: Contract;
    stock: string;
    workList: AvrDetail[];
    files: IFileAttachmentResponse[];
}
