import { DirectivesModule } from './../../core/directives/directives.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneConfirmationComponent } from './phone-confirmation/phone-confirmation.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule, MatDialogModule } from '@angular/material';
import { DsStorageTypeSelectComponent } from './ds-storage-type-select/ds-storage-type-select.component';
import { DxRadioGroupModule, DxTemplateModule } from 'devextreme-angular';

@NgModule({
    declarations: [PhoneConfirmationComponent, DsStorageTypeSelectComponent],
    imports: [
        CommonModule,
        MatSnackBarModule,
        ReactiveFormsModule,
        DxRadioGroupModule,
        DxTemplateModule,
        MatDialogModule,
        DirectivesModule,
    ],
    entryComponents: [PhoneConfirmationComponent, DsStorageTypeSelectComponent],
    exports: [PhoneConfirmationComponent, DsStorageTypeSelectComponent],
})
export class RegistrationComponentsModule {}
