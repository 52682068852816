import { CanAccessDirective } from './hasAccess.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneMaskDirective } from './phone-mask.directive';
import { TwoDigitDecimaNumberDirective } from './two-digit-decima-number.directive';
import { OnlyPositiveDirective } from './only-positive.directive';
import { DigitOnlyDirective } from './digit-only.directive';
import { OrganizationContractionsDirective, OrganizationContractionsPipe } from './organization-contractions.directive';

@NgModule({
    declarations: [
        PhoneMaskDirective,
        TwoDigitDecimaNumberDirective,
        OnlyPositiveDirective,
        DigitOnlyDirective,
        CanAccessDirective,
        OrganizationContractionsDirective,
        OrganizationContractionsPipe,
    ],
    imports: [CommonModule],
    exports: [
        PhoneMaskDirective,
        TwoDigitDecimaNumberDirective,
        OnlyPositiveDirective,
        DigitOnlyDirective,
        CanAccessDirective,
        OrganizationContractionsDirective,
        OrganizationContractionsPipe,
    ],
    providers: [OrganizationContractionsPipe]
})
export class DirectivesModule {}
