import { Subject, Observable } from 'rxjs';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { Injectable } from '@angular/core';

interface ISubscribingModalDialog {
    unsubscribe();
}

interface ISimpleModalDialog {
    /** the instance of current modal reference for the case when you have to  close  modal inside modal component */
    currentModal: NgbModalRef;
}

/** Simple dialog class that doesn't return any value */
export class SimpleModalDialog implements ISimpleModalDialog {
    currentModal: NgbModalRef;
}

export interface IModalDialog<T> extends ISimpleModalDialog, ISubscribingModalDialog {
    /** the observable stream */
    subject: Subject<T>;
    /** invoke that function when you want to get the modal dialog result */
    returnResult(dialogResult: T): void; // should it be public function?! i don't know
    /** get async result of modal dialog e.g rxjs.observable */
    getResult(): Observable<T>;
}

/** base class should be an extender for your modal dialog component
 * also your component should be declared as a part of shared.module.ts and
 * also MUST be included in app.module.ts as an entryComponents:[]
 */
export class ModalDialogBase<T> implements IModalDialog<T> {
    currentModal: NgbModalRef;
    subject: Subject<T> = new Subject<T>();
    returnResult(dialogResult: T): void {
        this.subject.next(dialogResult);
    }
    getResult(): Observable<T> {
        return this.subject;
    }
    unsubscribe() {
        this.subject.unsubscribe();
    }
}

export class ModalDialogWithArgBase<TResult, TArgument> extends ModalDialogBase<TResult> {
    protected customData: TArgument;

    public setCustomData(customData: TArgument): void {
        if (customData == null) {
            throw Error(' You should not pass null value here');
        }
        this.customData = customData;
    }
}

@Injectable()
export class ModalDialogService {
    /**
   *
   */
    constructor(private readonly _ngbModal: NgbModal) { }
    /** show modal dialog
   * TResult - is a modal dialog result model
   * component - is a Component extended by ModalDialogBase<T>
   * options - is a modal dialog options watch NgbModalOptions
   */
    show<TResult>(component: any, options: NgbModalOptions): [NgbModalRef, ModalDialogBase<TResult>] {
        return this.internalShow<ModalDialogBase<TResult>>(component, options);
    }
    show2<TResult>(component: any, options: NgbModalOptions, componentOptions): [NgbModalRef, ModalDialogBase<TResult>] {
        const modalResult = this.internalShow<ModalDialogBase<TResult>>(component, options);
        const componentInstance = modalResult[1].currentModal.componentInstance as ModalDialogBase<TResult>;
        Object.getOwnPropertyNames(componentOptions).forEach(propName => {
            componentInstance[propName] = componentOptions[propName];
        });
        return modalResult;
    }

    // TODO KIRILL: delete it if show2 works
    showWithArgument<TResult, TArgument>(
        component: any,
        options: NgbModalOptions,
        customData: TArgument,
    ): [NgbModalRef, ModalDialogWithArgBase<TResult, TArgument>] {
        const modalResult = this.internalShow<ModalDialogWithArgBase<TResult, TArgument>>(component, options);
        const componentInstance = modalResult[1].currentModal.componentInstance as ModalDialogWithArgBase<TResult, TArgument>;
        componentInstance.setCustomData(customData);

        return modalResult;
    }

    /** show modal dialog without waiting for a specialResult
   * component - is a Component extended by ModalDialogBase<T>
   * options - is a modal dialog options watch NgbModalOptions
   */
    showSimple(component: any, options: NgbModalOptions): [NgbModalRef, SimpleModalDialog] {
        return this.internalShow<SimpleModalDialog>(component, options);
    }

    /** hide modal dialog
* T - is a Component extended by ModalDialogBase<T>
* modalRef - is a modal dialog reference
*/
    hide<T extends ISubscribingModalDialog>(modalRef: NgbModalRef) {
        const componentInstance = modalRef.componentInstance as T;
        componentInstance.unsubscribe();
        modalRef.close();
    }

    private internalShow<T extends ISimpleModalDialog>(component: any, options: NgbModalOptions): [NgbModalRef, T] {
        options.backdrop = 'static';
        const modalRef = this._ngbModal.open(component, options);
        const componentInstance = modalRef.componentInstance as T;
        componentInstance.currentModal = modalRef;
        return [modalRef, componentInstance];
    }


}
