import { Component, ViewChild, ViewContainerRef, OnInit, ComponentFactoryResolver, Type } from '@angular/core';
import { ISaveSupport } from '../../isavesupport';
import { NotificationService, AuthService } from '../../../core/services';
import { Router, ActivatedRoute } from '@angular/router';
import { DocumentProcessingService } from '../../../core/services/document-processing.service';
import { ChangesService } from '../../../core/services/changes.service';
import { MediatorRequest } from '../../../core/mediator/IMediatorRequest';
import { DocumentMediator } from '../../services/document-mediator.service';

@Component({
    selector: 'document-create-page',
    templateUrl: './document-create-page.component.html',
    styleUrls: ['./document-create-page.component.scss'],
})
export class DocumentCreatePageComponent implements OnInit {
    @ViewChild('documentCardCreateComponent') documentCardCreateComponent: ISaveSupport;
    @ViewChild('documentTypeCreateComponent', { read: ViewContainerRef }) documentTypeCreateComponent: ViewContainerRef;
    private docTypeComponent: ISaveSupport;
    private currentDocumentType: string;

    constructor(
        private factoryResolver: ComponentFactoryResolver,
        private notificationService: NotificationService,
        private documentProcessingService: DocumentProcessingService,
        private router: Router,
        private _authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private _changesService: ChangesService,
        private mediator: DocumentMediator
    ) {}

  ngOnInit(): void {
        this.activatedRoute.params.subscribe(x => {
            this.currentDocumentType = x.documentType;
            const type = <Type<ISaveSupport>>(
                this.documentProcessingService.getCreateDocumentDetailsComponent(this.currentDocumentType)
                );
            const docTypeFactory = this.factoryResolver.resolveComponentFactory(type);
            const docTypeComponentRef = docTypeFactory.create(this.documentTypeCreateComponent.parentInjector);
            this.docTypeComponent = <ISaveSupport>docTypeComponentRef.instance;
            this.documentTypeCreateComponent.clear();
            this.documentTypeCreateComponent.insert(docTypeComponentRef.hostView);
        });
        this._changesService.changed = false;
    }
    get isSubmitDisabled() {
        return !this._changesService.changed || !this.mediator.send(new MediatorRequest('validateDocumentCard')); ;
    }
    saveDocument() {
        const isDocumentCardPageValid = this.mediator.send(new MediatorRequest('validateDocumentCard'));
        if (!isDocumentCardPageValid) {
            return;
        }
        const isDetailsPageValid = this.mediator.send(new MediatorRequest('validateDetailsData'));
        if (!isDetailsPageValid) {
            return;
        }
        const cardData = this.mediator.send<any>(new MediatorRequest('getCardData'));
        const detailsData = this.mediator.send(new MediatorRequest('getDetailsData'));
        this.documentProcessingService.createDocument(cardData, detailsData, this.currentDocumentType).subscribe(resp => {
            if (resp.data) {
                this.notificationService.showNotification(`Данные успешно сохранены - ${resp.data}`, () => {
                    this.router.navigate([`cabinet/view/${this.currentDocumentType}/${resp.data}`]);
                });
            } else {
                this.notificationService.showNotification(resp.error.text);
            }
        });
    }
    cancelDocument() {
        this.router.navigate(['cabinet/']);
        this._authService.setBreadcrumb('Исходящие', 'На подписании');
        return;
    }

}
