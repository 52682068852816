import {Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy} from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import {AuthorizedStoreCreator} from '../../../../core/helpers/authorized-store.creator';
import {AuthService} from '../../../../core/services/auth.service';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ChangesService} from '../../../../core/services/changes.service';
import {ActivatedRoute} from '@angular/router';
import {ISaveSupport} from '../../../isavesupport';
import * as deepEqual from 'deep-equal';
import {DocumentHistory} from '../../../../core/models/document-history.model';
import {DocumentMediator} from '../../../services/document-mediator.service';
import {ContractionsHelper} from '../../../../core/directives/organization-contractions.directive';
import {TemplateCard} from '../../../../core/models/template-card.model';
import {TemplateCardState} from '../../../../documents/state/TemplateCardState';
import {TemplateProcessingService} from '../../../../core/services/template-processing.service';
import {MediatorRequest} from "../../../../core/mediator/IMediatorRequest";

@Component({
  selector: 'template-card-edit',
  templateUrl: './template-card-edit.component.html',
  styleUrls: ['./template-card-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateCardEditComponent implements OnInit, OnDestroy, ISaveSupport {
  state: TemplateCardState;
  public templateCardForm: FormGroup;
  public errorMessage = '';
  public successMessage = '';
  public uniqueCode = '';
  public systemRegistrationDate = '';
  public dataSource: DataSource;
  public templateCard: TemplateCard = new TemplateCard();
  public documentHash: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private store: AuthorizedStoreCreator,
    private auth: AuthService,
    private templateProcessingService: TemplateProcessingService,
    private activatedRoute: ActivatedRoute,
    private _changes: ChangesService,
    private mediator: DocumentMediator,
  ) {
  }

  ngOnInit(): void {
    this.state = new TemplateCardState(this.formBuilder, this.mediator);
    this.templateCardForm = this.state.templateCardForm;

    const sub = this.activatedRoute.params.subscribe(x => {
      this.uniqueCode = x.uniqueCode;
      this.loadCardByTemplateInfo(this.uniqueCode);
    });
    this.subscriptions.push(sub);
  }

  get f() {
    return this.templateCardForm.controls;
  }

  changeDocWithQr(event) {
    const isQrCodeApplicableToAttachments = this.mediator.send(new MediatorRequest('tryChangeQrAndGetValue', event.target.checked));
    this.templateCardForm.controls["documentWithQr"].setValue(isQrCodeApplicableToAttachments);
  }

  get documentType(): string {
    return this.state.documentType;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
    this.state.finalizeState();
  }

  public loadCardByTemplateInfo(uniqueCode: string): void {
    const sub = this.templateProcessingService.loadCardInfo(uniqueCode).subscribe(resp => {
      if (resp.data) {
        this.systemRegistrationDate = resp.data.systemRegistrationDate;
        this.templateCardForm.patchValue({
          systemRegistrationDate: resp.data.systemRegistrationDate,
          name: resp.data.name,
          summary: resp.data.summary,
          isActive: resp.data.isActive,
          documentWithQr: resp.data.documentWithQr
        });
        this.mediator.publish(new MediatorRequest("changeDocWithQr", resp.data.documentWithQr));
        const initialForm = this.templateCardForm.value;
        this.templateCardForm.valueChanges.subscribe(value => {
          if (!deepEqual(this.templateCardForm.value, initialForm)) {
            this._changes.changed = true;
          } else {
            this._changes.changed = false;
          }
        });
      } else {
        this.errorMessage = resp.error.text;
      }
    });
    this.subscriptions.push(sub);

  }
}
