import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AuthService } from '../../../../core/services/auth.service';
import { FileService } from '../../../../core/services/file.service';
import { OtherDocumentService } from '../../../../core/services/otherdocument.service';
import { ChangesService } from '../../../../core/services/changes.service';
import { Subscription, Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizedStoreCreator } from '../../../../core/helpers/authorized-store.creator';
import { ISaveSupport } from '../../../isavesupport';
import { custom } from 'devextreme/ui/dialog';
import { NotificationService } from '../../../../core/services/notification.service';
import { TemplateDetailState } from '../../../state/TemplateDetailState';
import { DocumentMediator } from '../../../services/document-mediator.service';

@Component({
    selector: 'template-edit',
    templateUrl: './template-edit.component.html',
    styleUrls: ['./template-edit.component.scss'],
})
export class TemplateEditComponent implements OnInit, OnDestroy, ISaveSupport {
    public state: TemplateDetailState;
    public errorMessage = '';
    public successMessage = '';
    public dataSource: DataSource;
    public profileUniqueId: string;
    public _templateUniqueId = '';
    public uploadUrl = '';
    public headers: any;
    public documentCount: number;
    public isEdited = false;
    public documentHash: string;
    private subscriptions: Subscription[] = [];
    constructor(
        private _authService: AuthService,
        private _fileService: FileService,
        private _otherDocumentService: OtherDocumentService,
        private store: AuthorizedStoreCreator,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private baseNotificationService: NotificationService,
        private _changesService: ChangesService,
        private mediator: DocumentMediator,
    ) {
        this.headers = {
            'Authorization': `Bearer ${this._authService.getToken()}`,
            'PersonUniqueId': this._authService.currentUserValue.profile.uniqueId,
        };
        this.profileUniqueId = this._authService.currentUserValue.profile.uniqueId;
    }

    ngOnInit(): void {
        const sub = this.activatedRoute.params.subscribe(x => {
            this._templateUniqueId = x.uniqueCode;
            this.uploadUrl = `/api/fileAttachmentTemplate/addFileToTemplate?uniqueCode=${this._templateUniqueId}`;
            this.refreshAttachmentGrid();
        });
        this.subscriptions.push(sub);
        this.state = new TemplateDetailState(this.mediator, this.baseNotificationService);
    }

    onInitializedEventHandler(e) {
        const component = this;
        e.component.instance()._validateMaxFileSize = function(file) {
            const fileSize = file.value.size;
            const maxFileSize = this.option('maxFileSize');
            const maxFileCount = 10;
            const filesSum = (component.state.templateDocuments.length + this._files.length);
            if (filesSum >= maxFileCount) {
                return false;
            }
            return maxFileSize > 0 ? fileSize <= maxFileSize : true;
        };
    }

    refreshAttachmentGrid() {
        const store = this.store.createStore({
            key: 'uniqueId',
            loadUrl: `/api/template/attachments?templateUniqueId=${this._templateUniqueId}`,
            deleteUrl: `/api/fileAttachmentTemplate?uniqueCode=${this._templateUniqueId}`,
        });
        store.on('loaded', (fileAttachmentResult: any[]) => {
            this.documentCount = fileAttachmentResult.length;
            this.state.templateDocuments.splice(0, this.state.templateDocuments.length);
            fileAttachmentResult.forEach(item => {
                this.state.templateDocuments.push(item);
            });

        });
        this.dataSource = new DataSource(store);

    }

    getCountDocument() {
        return this.documentCount;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.state.finalizeState();
    }

    onUploadErrorEventHandler(e) {
        if (e.request.status === 401) {
            this._authService.logout();
            this.router.navigate(['/auth']);
        }
    }

    onUploadedEventHandler(e) {
        this.refreshAttachmentGrid();
        this.isEdited = true;
        this.mediator.publish({ type: 'modelChanged', message: true });
    }
  getAvailableDocType(): Array<string> {
    if (!this.state.isQrInDocument) {
      return new Array<string>('.jpg', '.jpeg', '.png', '.img', '.bmp', '.docx', '.doc', '.xlsx', '.xls', '.pptx', '.ppt', '.txt', '.pdf', '.rar', '.zip');
    } else {
      return new Array<string>('.docx', '.pdf');
    }
  }
  getAvailableDocMimeType() {
    if (this.state.isQrInDocument) {
      return "application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    } else {
      return "image/jpeg,image/jpg,image/png,image/img,image/bmp,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain, application/x-rar-compressed,application/zip,.rar";
    }
  }
    onRowRemoving(e) {
        const fileName = e.data.fileName;
        const customDialog = custom({
            title: 'Внимание',
            message: 'Вы действительно хотите удалить файл: ' + fileName + '?',
            buttons: [
                {
                    text: 'Да',
                    onClick: d => {
                        this.isEdited = true;
                        return true;
                    },
                },
                { text: 'Нет', onClick: d => false },
            ],
        });

        e.cancel = new Promise((resolve, reject) => {
            customDialog
                .show()
                .done(dialogResult => {
                    resolve(!dialogResult);
                })
                .fail(x => {
                    resolve(true);
                });
        });
        this.mediator.publish({ type: 'modelChanged', message: true });
    }
}
