import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../models/apiresult.model';
import { InviteRequest } from '../../../app/core/models/invite-request.model';
@Injectable({
    providedIn: 'root',
})
export class InviteService {
    constructor(private http: HttpClient) {}
    validateInviteCode(codeRequest: any) {
        return this.http.post<ApiResult<any>>('/api/registration/getInformationByInviteCode', codeRequest);
    }
    sendInvitation(inviteRequest: InviteRequest) {
        return this.http.post<ApiResult<any>>('/api/registration/ContragentRegistration', inviteRequest);
    }
}
