import {Injectable} from '@angular/core';

import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiResult} from '../models/apiresult.model';
import {DocumentCard} from '../../core/models/document-card.model';
import {DocumentHistory} from '../../core/models/document-history.model';
import {AuthService} from '../../core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class OtherDocumentService {
  constructor(private _http: HttpClient, private _authService: AuthService) {
  }

  removeAllAttachments(attachments: IFileAttachmentResponse[]) {
    return this._http.post<ApiResult<boolean>>('/api/fileattachment/removeattachments', attachments).pipe(
      map(resp => resp),
    );
  }

  saveOtherDocument(card: DocumentCard, details: IFileAttachmentResponse[]) {
    const request = {card: card, details: details};
    return this._http
      .post<ApiResult<string>>('/api/document/savedraft?profileXin=' + this._authService.getProfile().xin, request)
      .pipe(
        map(resp => resp),
      );
  }

  loadCardInfo(uniqueCode: string) {
    return this._http
      .get<ApiResult<DocumentCard>>(
        `/api/document/${uniqueCode}/card`, //?profileXin= //this._authService.getProfile().xin,
      )
      .pipe(
        map(resp => resp),
      );
  }

  loadHistoryInfo(uniqueCode: string) {
    return this._http
      .get<ApiResult<DocumentHistory[]>>(`/api/document/${uniqueCode}/history`) //+ this._authService.getProfile().xin)
      .pipe(
        map(resp => resp),
      );
  }

  public downloadFile(uniqueCode: string, fileId: number) {
    const url = `/api/document/downloadFile?uniqueCode=${uniqueCode}&fileId=${fileId}`;
    const body = {fileId: `${fileId}`};
    return this._http.post(url, body, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
    });
  }
}
