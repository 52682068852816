import { Directive, ElementRef, HostListener } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';

@Directive({
    selector: '[positiveOnly]',
})
export class OnlyPositiveDirective {
    // Allow decimal numbers and negative values
    private regex = new RegExp(/^\d*\.?\d{0,2}$/g);
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

    private forbidenCharacters = ['-', '-'];
    constructor(private el: ElementRef) {}
    ngOnInit() {
        fromEvent(this.el.nativeElement, 'keydown').subscribe((e: any) => {
            this.onKeyDown(e);
        });
    }
    onKeyDown(event: KeyboardEvent) {
        if (this.forbidenCharacters.some(x => x === event.key)) {
            event.preventDefault();
        }
        if (this.specialKeys.indexOf(event.key) >= -1) {
            return;
        }
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }
}
