import { Component, OnInit, Injector } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AuthorizedStoreCreator } from '../../../../../core/helpers/authorized-store.creator';
import { AuthService } from '../../../../../core/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DocumentProcessingService } from '../../../../../core/services/document-processing.service';
import { MainViewerBaseComponent } from '../../base/main-viewer-base.component';
import {
  ContractionsHelper,
  OrganizationContractionsPipe,
} from '../../../../../core/directives/organization-contractions.directive';
import { DocumentPackageService } from '../../../../../documents/services/document-package.service';
import { LetterStatus } from '../../../../../core/models/statuses';


@Component({
  selector: 'sc-main-viewer-in-letters',
  templateUrl: './main-viewer-in-letters.component.html',
  styleUrls: ['./main-viewer-in-letters.component.scss']
})
export class MainViewerInLettersComponent extends MainViewerBaseComponent {
  public letterStatuses: LetterStatus = new LetterStatus();
  constructor(injector: Injector) {
    super(injector);
  }

  public Load() {
    this.dataSource = new DataSource(
      this.store.createStore({
        key: 'uniqueCode',
        loadUrl: '/api/document/getUserEmails?documentGroupType=Inbox',
      }),
    );
  }
  public setContractionForContragent = (val) => ContractionsHelper.transformContraction(val.senderContragentName);
}
