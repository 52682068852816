export class BalanceOperation {
    statuses = {
        'ru': [
            {
                'id': 0,
                'name': 'Списание',
            },
            {
                'id': 1,
                'name': 'Пополнение',
            },
        ],
    };
}
export class PacketStatus {
    statuses = {
        'ru': [
            {
                'id': 0,
                'name': 'Создан',
            },
            {
                'id': 1,
                'name': 'В ожидании',
            },
            {
                'id': 2,
                'name': 'Активный',
            },
            {
                'id': 3,
                'name': 'Истек',
            },
            {
                'id': 4,
                'name': 'Отозван',
            },
        ],
    };
}
export class ClientType {
    statuses = {
        'ru': [
            {
                'id': 0,
                'name': 'Физическое лицо',
            },
            {
                'id': 1,
                'name': 'Юридическое лицо',
            },
            {
                'id': 2,
                'name': 'Индивидуальный предприниматель',
            },
        ],
    };
}
export class NotificationType {
    data = {
        'ru': [
            {
                'id': 0,
                'name': 'Вы отправили документ на подписание',
            },
            {
                'id': 1,
                'name': 'Вы подписали документ',
            },
            {
                'id': 2,
                'name': 'Вы отклонили документ',
            },
            {
                'id': 3,
                'name': 'Вы отозвали документ',
            },
            {
                'id': 4,
                'name': 'Вам отправили документ на подписание',
            },
            {
                'id': 5,
                'name': 'Контрагент отклонил документ',
            },
            {
                'id': 6,
                'name': 'Контрагент отозвал документ',
            },
            {
                'id': 7,
                'name': 'Контрагент подписал документ',
            },
            {
                'id': 8,
                'name': 'Приглашенный вами пользователь зарегистрировался в SmartContract',
            },
            {
                'id': 9,
                'name': 'Вы отправили приглашения в SmartContract',
            },
            {
                'id': 10,
                'name': 'Добавлен профиль',
            },
            {
                'id': 11,
                'name': 'Профиль удален',
            },
            {
                'id': 12,
                'name': 'Профиль изменен',
            },
        ],
    };
}
export class PackTypes {
    statuses = {
        'ru': [
            {
                'code': 'Y200',
                'name': 'Пакет 200',
            },
            {
                'code': 'Y500',
                'name': 'Пакет 500',
            },
            {
                'code': 'Y1000',
                'name': 'Пакет 1000',
            },
            {
                'code': 'Y2000',
                'name': 'Пакет 2000',
            },
            {
                'code': 'F10',
                'name': 'Бонусный пакет',
            },
            {
                'code': 'M30',
                'name': 'Ежемесячный пакет с лимитом 30 документов',
            },
            {
                'code': 'M50',
                'name': 'Ежемесячный пакет с лимитом 50 документов',
            },
            {
                'code': 'M100',
                'name': 'Ежемесячный пакет с лимитом 100 документов',
            },
        ],
    };
}

export class LetterStatus {
  viewed = {
    'ru': [
      {
        'id': 5,
        'name': 'Нет',
      },
      {
        'id': 6,
        'name': 'Да',
      }
    ]
  };
}
