import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusDocument } from '../../../app/core/models/document-status.model';
import { AuthService } from '../../../app/core/services';
import { ApiResult } from '../../../app/core/models/apiresult.model';
import { DocumentValidationModel } from '../../../app/core/models/document-validation.model';

@Component({
    selector: 'document-validation',
    templateUrl: './document-validation.component.html',
    styleUrls: ['./document-validation.component.scss'],
})
export class DocumentValidationComponent implements OnInit {
    public errorMessage = '';
    public successMessage = '';
    public language = 'ru';
    public uploaded = false;
    public progress = false;
    public size = '';
    public status: StatusDocument = new StatusDocument();
    documentPackages: any[] = [];
    constructor(private auth: AuthService, private router: Router, private activatedRoute: ActivatedRoute) {
        const title = activatedRoute.snapshot.data['title'];
    }

    ngOnInit(): void {
        this.auth.setBreadcrumb('', 'Проверка пакета документов');
    }

    onUploadErrorEventHandler(e) {
    // console.log(e);
        e = e;
        this.uploaded = true;
        this.progress = false;
        this.errorMessage = 'Не удалось загрузить';
    }
    onUploadedEventHandler(e: any) {
        this.progress = false;
        const s: number = e.file.size;
        this.size = `Загружено(${s}`;
        const validationResult: ApiResult<DocumentValidationModel> = <ApiResult<DocumentValidationModel>>(
      JSON.parse(e.request.response)
    );
        this.successMessage = '';
        this.errorMessage = '';
        if (validationResult.data != null) {
            if (validationResult.data.validationResult != null) {
                this.errorMessage = validationResult.data.validationResult;
            } else {
                this.successMessage = `Пакет, зарегистрированный в системе под номером ${validationResult.data.uniqueCode},
                               успешно проверен : документ ${
    this.status.data[this.language][validationResult.data.status].name
}`;
            }
        } else {
            this.errorMessage = validationResult.error.text;
        }
        this.uploaded = true;
    }
    isUploaded() {
        return this.uploaded;
    }
    isFileNotSelected() {
        return !this.uploaded;
    }
    clear() {
        this.errorMessage = '';
        this.successMessage = '';
    }
    on(event) {
        this.progress = true;
    }
    onAborted(event) {
        this.auth.redirectTo(this.router.url);
    }
}
