import { Component, ViewChild, ViewContainerRef, OnInit, ComponentFactoryResolver, AfterViewChecked } from '@angular/core';
import { NotificationService, NcalayerService } from '../../../core/services';
import { ActivatedRoute } from '@angular/router';
import { Subscription, of, Observable } from 'rxjs';
import { SignEntity } from '../../../core/models/signEntity';
import { DocumentMediator } from '../../services/document-mediator.service';
import { MediatorRequest } from '../../../core/mediator/IMediatorRequest';
import { ModalDialogService } from '../../../shared/services/modal-dialog.service';
import { switchMap } from 'rxjs/operators';
import { NCAResponse } from '../../../core/models/nca-response.model';
import { ApiResult } from '../../../core/models/apiresult.model';
import { TemplateCardViewComponent } from '../../card-types/template-card-component/view/template-card-view.component';
import { TemplateViewComponent } from '../../document-types/template/view/template-view.component';
import { TemplateProcessingService } from '../../../core/services/template-processing.service';
import { email } from '../../../core/validators';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
    selector: 'template-view-page',
    templateUrl: './template-signer-view-page.component.html',
    styleUrls: ['./template-signer-view-page.component.scss'],
})
export class TemplateSignerViewPageComponent implements OnInit {
    @ViewChild('templateCardViewComponent')
    templateCardViewComponent: TemplateCardViewComponent;
    @ViewChild('templateViewComponent')
    templateViewComponent: TemplateViewComponent;
    public canBeSigned: boolean;
    private subscriptions: Subscription[] = [];
    private uniqueId: string;
    public emailForm: FormGroup;
    public allFilesDownloaded: boolean;
    constructor(
        private templateProcessing: TemplateProcessingService,
        private ncalayer: NcalayerService,
        private notification: NotificationService,
        private activatedRoute: ActivatedRoute,
        //public dialog: MatDialog,
        private mediator: DocumentMediator,
        private modalDialogService: ModalDialogService, 
        private formBuilder: FormBuilder,
        private router: Router,

    ) {
        this.emailForm = this.formBuilder.group({
        signerEmail: ['', Validators.compose([Validators.required, Validators.email])]}); 
    }

    ngOnInit(): void {
        
        const subs = this.activatedRoute.params.subscribe(roueteParams => {
            this.uniqueId = roueteParams.uniqueCode;
          const cards = this.templateProcessing.loadCardInfo(this.uniqueId).subscribe(loadCardResult => {
                if (loadCardResult.data.canBeEdited == false && loadCardResult.data.isActive == false) {
                  this.router.navigate(['/404']);
                  return;
                }
                this.canBeSigned = loadCardResult.data.canBeSigned;
                this.mediator.publish(new MediatorRequest('setCardInfo', loadCardResult));
            });
            this.subscriptions.push(cards);
        });
        this.subscriptions.push(subs);
        
    }

    signAndSend() {
        const documentCount = this.templateViewComponent.getCountDocument();
        if (documentCount < 1) {
            this.notification.showNotification('Количество файлов не должно быть меньше 1');
            return;
        }
        this.templateProcessing.getSignEntity(this.uniqueId).subscribe(x => {
            if (x.error != null) {
                this.notification.showNotification(x.error.text);
                return;
            }
            this.sign(x.data).subscribe(result => {
                this.send(<string>result.responseObject);
            },
                error => {
                    this.notification.showSnackNotification(this.ncalayer.getLocalizedErrorMessage(error));
                })
        });
    }

    sign(entity: SignEntity): Observable<NCAResponse> {
        if (entity.lastChainSignature) {

            return this.ncalayer.addSignatureToExistingOne(entity.xmlData, entity.lastChainSignature)
        } else {
            return this.ncalayer.createCMSSignatureFromData(entity.xmlData)
        }
    }
    get f() {
        return this.emailForm.controls;
    }
    get isSignDisabled() {
        return this.emailForm.invalid;
    }
    send(signedXml: string): void {
        if(this.emailForm.invalid){
            return;
        }
        this.templateProcessing.sendToSignTemplate(this.uniqueId, signedXml, this.emailForm.get('signerEmail').value).subscribe(response => {
            if (response.error != null) {
                this.notification.showNotification(response.error.text);
                return;
            }
             this.canBeSigned = false;
            this.notification.showNotification('Документ успешно подписан', () => {
            });
        });
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
    }
}
