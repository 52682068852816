import { Component, OnInit, OnDestroy } from '@angular/core';
import { DocumentCard } from '../../../../core/models/document-card.model';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../../../core/services';
import { DocumentProcessingService } from '../../../../core/services/document-processing.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../core/services/auth.service';
import { DocumentHistory } from '../../../../core/models/document-history.model';
import { DocumentMediator } from '../../../services/document-mediator.service';
import { SignerModel } from '../../../../core/models/signer.model';

@Component({
  selector: 'document-check-card-view',
  templateUrl: './document-check-card-view.component.html',
  styleUrls: ['./document-check-card-view.component.scss'],
})
export class DocumentCheckCardViewComponent implements OnInit, OnDestroy {
  public uniqueCode = '';
  public systemRegistrationDate = '';
  public internalNumber = '';
  public registrationDate: Date = null;
  public summary = '';
  public documentWithQr = false;
  public documentUrl = '';
  public documentHistoryItems: DocumentHistory[] = [];
  public reasonForRejection = '';
  public documentCard: DocumentCard = new DocumentCard();
  private subscriptions: Subscription[] = [];
  public currentDocumentType: string;
  public signers: SignerModel[] = null;

  constructor(
    private _documentService: DocumentProcessingService,
    private activatedRoute: ActivatedRoute,
    private notification: NotificationService,
    private mediator: DocumentMediator,
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
    const subs = this.activatedRoute.params.subscribe(roueteParams => {
      this.currentDocumentType = roueteParams.documentType;
      console.log(this.currentDocumentType);
      this.subscribeToCardInfoChanging();
    });
    this.subscriptions.push(subs);
    const sub = this.activatedRoute.params.subscribe(x => {
      this._documentService.loadHistoryInfoAnonymously(x.uniqueCode);
    });

    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
    this.mediator.unsubscribe('setCardInfo');
  }

  public loadHistoryByDocumentInfo(uniqueCode: string): void {
    let sub;
    if (this._authService.isLoggedIn()) {
      sub = this._documentService.loadHistoryInfo(uniqueCode).subscribe(resp => {
        if (resp.data) {
          this.documentHistoryItems = resp.data;
        } else {
        }
      });
    }
    else {
      sub = this._documentService.loadHistoryInfoAnonymously(uniqueCode).subscribe(resp => {
        if (resp.data) {
          this.documentHistoryItems = resp.data;
        } else {
        }
      });
    }
    this.subscriptions.push(sub);
  }

  public subscribeToCardInfoChanging(): void {
    this.mediator.subscribe('setCardInfo', (info) => {
      if (info.data) {
        this.systemRegistrationDate = info.data.systemRegistrationDate;
        this.internalNumber = info.data.internalNumber;
        this.uniqueCode = info.data.documentUniqueId;
        this.documentWithQr = info.data.documentWithQr;
        this.documentUrl = info.data.documentUrl;
        this.signers = info.data.signers;
      } else {
        this.notification.showNotification(info.error.text);
      }
    })

  }
}
