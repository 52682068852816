import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../models/apiresult.model';
import { User } from '../models/user.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { IProfile } from '../models/profileresponse.model';

interface IBreadCrumb {
    header: string;
    name: string;
}
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public redirectUrl: string;
    public expiresValue: Date;
    public tabs: { code: string; docType: number; status: number }[] = [];
    public selectedTab = 0;
    public breadcrumb: BehaviorSubject<IBreadCrumb>;
    private _intervalId: any;
    private currentUserSubject: BehaviorSubject<User>;

    constructor(private cookieService: CookieService, private http: HttpClient, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        const today = new Date();
        this.expiresValue = new Date(today);
        this.expiresValue.setSeconds(today.getSeconds() + 4 * 60 * 1000);
        this.breadcrumb = new BehaviorSubject<IBreadCrumb>({ header: 'Входящие', name: 'На подписании' });
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
    getRefreshToken(): string | null {
        if (this.currentUserValue == null) {
            return null;
        }
        return this.currentUserValue.jwt.refreshToken;
    }
    refreshToken() {
    // вот тут улетает нулл
        const obj = { 'refreshtoken': this.getRefreshToken() };
        return this.http.post<ApiResult<JWT>>('/api/authenticate/refreshaccesstoken/', obj).pipe(
            map(resp => {
                if (resp.data) {
                    const user: User = this.currentUserValue;
                    user.jwt = resp.data;
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.cookieService.set('auth-jwt', JSON.stringify(user.jwt.jwt), this.expiresValue, '/');
                }
                return resp;
            }),
        );
    }
    login(params: { login: string; password: string }) {
        return this.http.post<ApiResult<JWT>>('/api/authenticate', params).pipe(
            map(resp => {
                if (resp.data) {
                    const user = new User();
                    user.jwt = resp.data;
                    user.userName = params.login;
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.cookieService.set('auth-jwt', JSON.stringify(user.jwt.jwt), this.expiresValue, '/');
                    this.currentUserSubject.next(user);
                    this.triggerRefreshToken();
                }
                return resp;
            }),
        );
    }
    loadProfile() {
        return this.http.get<ApiResult<IProfile[]>>('/api/profile').pipe(
            map(resp => {
                if (resp.data) {
                    const fillData: IProfile[] = [];
                    for (let i = 0; i < resp.data.length; i++) {
                        const profile: IProfile = resp.data[i];
                        if (profile.isDefault) {
                            const iUser: User = this.currentUserValue;
                            iUser.profile = profile;
                            localStorage.setItem('currentUser', JSON.stringify(iUser));
                            this.cookieService.set('auth-jwt', JSON.stringify(iUser.jwt.jwt), this.expiresValue, '/');
                        }
                        fillData.push(profile);
                    }
                    const user: User = this.currentUserValue;
                    user.profiles = fillData;
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.cookieService.set('auth-jwt', JSON.stringify(user.jwt.jwt), this.expiresValue, '/');
                }
                return resp;
            }),
        );
    }
    isNeedRefreshToken(): Boolean {
        if (
            !this.isLoggedIn() &&
      localStorage.getItem('currentUser') != null &&
      this.cookieService.get('currentUser') != null
        ) {
            return true;
        }
        return false;
    }
    isLoggedIn(): Boolean {
        const token = this.getToken();
        return !!token;
    }
    isTokenExpired(jwt: JWT): Boolean {
        const date = new Date(jwt.accessTokenExpiration);
        if (date > new Date()) {
            return false;
        }
        return true;
    }
    getToken(): string {
        const user: User = this.currentUserValue;
        if (user) {
            const jwt = user.jwt;
            if (this.isTokenExpired(jwt)) {
                // this.logout();
                return null;
            }
            return jwt.jwt;
        }
        return null;
    }
    getPermissions(): string[] {
        const user: User = this.currentUserValue;
        if (user) {
            return user.jwt.permission;
        }
        return null;
    }
  logout(): void {
        this.getTabs().splice(0);
        this.setBreadcrumb('', '');
        localStorage.removeItem('currentUser');
        this.cookieService.deleteAll('auth-jwt', 'localhost');
        this.currentUserSubject.next(null);
        if (this._intervalId != null) {
            clearInterval(this._intervalId);
        }
    }
    public forceLogout() {
        this.logout();
        alert('Сессия завершена');
        location.reload(true);
    }
    swithProfile(uniqueId: string) {
        const user: User = this.currentUserValue;
        const profiles = this.getProfiles();
        profiles.forEach(x => {
            if (x.uniqueId == uniqueId) {
                user.profile = x;
                this.http
                    .get<ApiResult<string>>(`/api/Authenticate/SwitchProfileToken?uId=${uniqueId}&userName=${user.userName}`)
                    .subscribe(s => {
                        user.jwt.jwt = s.data;
                        localStorage.setItem('currentUser', JSON.stringify(user));
                        this.cookieService.set('auth-jwt', JSON.stringify(user.jwt.jwt), this.expiresValue, '/');
                    });
            }
        });
    }
    getRandom(min, max) {
        return Math.random() * (max - min) + min;
    }
    getProfile(): IProfile {
        const user: User = this.currentUserValue;
        return user.profile;
    }
    getPermissionCurrentProfile() {
        const profile = this.getProfile();
        return profile.permissions;
    }
    setProfile(profile: IProfile): void {
        this.currentUserValue.profile = profile;
    }
    getProfiles(): IProfile[] {
        const user: User = this.currentUserValue;
        return user.profiles;
    }
    redirectTo(uri: string) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([uri]));
    }
    forgotpassword(params: { email: string }) {
        return this.http.post<ApiResult<string>>('/api/registration/forgotpassword', params).pipe(
            map(resp => resp),
        );
    }
    resetpassword(params: { password: string; confirmPassword: string; code: string; userId: string }) {
        return this.http.post<ApiResult<boolean>>('/api/registration/resetpassword', params).pipe(
            map(resp => resp),
        );
    }
    confirmemail(params: { code: string; userId: string }) {
        return this.http.post<ApiResult<boolean>>('/api/registration/confirmemail', params).pipe(
            map(resp => resp),
        );
    }
    triggerRefreshToken() {
        const user: User = this.currentUserValue;
        if (user == null) {
            return;
        }
        const randomInSec = this.getRandom(1, 60);
        const timeoutInMs = 4 * (60 + randomInSec) * 1000;
        const tokenExpirationDateInMs = Number(new Date(this.currentUserValue.jwt.accessTokenExpiration));
        if (tokenExpirationDateInMs - Date.now() - timeoutInMs < 0) {
            this.refreshToken().subscribe(resp => {
                if (!resp.data) {
                    return;
                }
                this.setIntervalRefreshToken();
            });
        } else {
            this.setIntervalRefreshToken();
        }
    }
    setIntervalRefreshToken() {
        const tokenExpirationDateInMs = Number(new Date(this.currentUserValue.jwt.accessTokenExpiration));
        const randomInSec = this.getRandom(1, 60);
        const timeoutInMs = 4 * (60 + randomInSec) * 1000;
        this._intervalId = setInterval(() => {
            this.refreshToken().subscribe(resp => {
                if (resp.error) {
                    clearInterval(this._intervalId);
                    this.logout();
                } else {
                    clearInterval(this._intervalId);
                    this.triggerRefreshToken();
                }
            });
        }, tokenExpirationDateInMs - Date.now() - timeoutInMs);
    }
    

    public getBreadcrumb(): Observable<IBreadCrumb> {
        return this.breadcrumb.asObservable();
    }
    public setBreadcrumb(header: string, value: string) {
        this.breadcrumb.next({ header, name: value });
    }
    getTabs() {
        return this.tabs;
    }
   
}
