import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollapseModule, BsDropdownModule } from 'ngx-bootstrap';
import { UserComponentModule } from '../core/shared-component/user-component/user.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ModalDialogService } from '../shared/services/modal-dialog.service';
import { DirectivesModule } from '../core/directives/directives.module';
import { SharedModule } from '../shared/shared.module';
import { ServiceCenter } from '../core/services';
import {
  MatTabsModule,
  MatIconModule,
  MatMenuModule,
  MatButtonModule,
  MatSelectModule,
  MatFormFieldModule,
  MatToolbarModule,
} from '@angular/material';
import { SupportCenterComponent } from '../support/support-center/support-center.component';
import { AvrDocumentHttpService } from '../core/http.services/avr-document-http.service';
import { HttpClient } from '@angular/common/http';
import { LayoutModule } from '../layout/layout.module';
import { Router, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { DocumentCreatePageComponent } from './page/create/document-create-page.component';
import { DocumentEditPageComponent } from './page/edit/document-edit-page.component';
import { DocumentViewPageComponent } from './page/view/document-view-page.component';
import { DocumentCardCreateComponent } from './card-types/document-card-component/create/document-card-create.component';
import { DocumentCardEditComponent } from './card-types/document-card-component/edit/document-card-edit.component';
import { DocumentCardViewComponent } from './card-types/document-card-component/view/document-card-view.component';
import { DocumentCheckCardViewComponent } from './card-types/document-card-component/check/document-check-card-view.component';
import { TemplateCardCreateComponent } from './card-types/template-card-component/create/template-card-create.component';
import { TemplateCardEditComponent } from './card-types/template-card-component/edit/template-card-edit.component';
import { TemplateCardViewComponent } from './card-types/template-card-component/view/template-card-view.component';
import { OtherDocumentCreateComponent } from './document-types/other-document/create/other-document-create.component';
import { OtherDocumentEditComponent } from './document-types/other-document/edit/other-document-edit.component';
import { OtherDocumentViewComponent } from './document-types/other-document/view/other-document-view.component';
import { AvrDocumentCreateComponent } from './document-types/avr-document/create/avr-document-create.component';
import { CheckDocumentViewComponent } from './document-types/check-document/check-document-view.component';
import { AvrDocumentEditComponent } from './document-types/avr-document/edit/avr-document-edit.component';
import { AvrDocumentViewComponent } from './document-types/avr-document/view/avr-document-view.component';
import { TemplateViewComponent } from './document-types/template/view/template-view.component';
import { TemplateCreateComponent } from './document-types/template/create/template-create.component';
import { TemplateEditComponent } from './document-types/template/edit/template-edit.component';
import { LetterDocumentCreateComponent } from './document-types/letter-document/create/letter-document-create.component';
import { LetterDocumentEditComponent } from './document-types/letter-document/edit/letter-document-edit.component';
import { LetterDocumentViewComponent } from './document-types/letter-document/view/letter-document-view.component';
import { DocumentValidationComponent } from './document-validation-component/document-validation.component';
import { DocumentValidationPageComponent } from './page/validation/document-validation-page.component';
import { AvrInfoComponent } from './document-types/avr-document/shared/avr-info/avr-info.component';
import { ContractorInfoComponent } from './document-types/avr-document/shared/contractor-info/contractor-info.component';
import { ContractInfoComponent } from './document-types/avr-document/shared/contract-info/contract-info.component';
import { AttachedDocumentsComponent } from './document-types/avr-document/shared/attached-documents/attached-documents.component';
import { BasisSelectionComponentComponent } from './shared/base-selection-component/basis-selection-component.component';
import { DocumentViewPageWithoutAuthComponent } from './page/anonymous-view/document-view-page-without-auth.component';
import { DocumentCheckPageWithoutAuthComponent } from './page/check/document-check-page.component';
import { CommonModule, DatePipe } from '@angular/common';
import { DxDataGridModule, DxSelectBoxModule, DxFileUploaderModule } from 'devextreme-angular';
import { DocumentPackageService } from './services/document-package.service';
import { TemplateSignerViewPageComponent } from './template-page/template-signer-view/template-signer-view-page.component';
import { TemplateCreatePageComponent } from './template-page/create/template-create-page.component';
import { TemplateEditPageComponent } from './template-page/edit/template-edit-page.component';
import { TemplateViewPageComponent } from './template-page/view/template-view-page.component';
import { ClipboardModule } from 'ngx-clipboard';



@NgModule({
  declarations: [
    DocumentCreatePageComponent,
    DocumentEditPageComponent,
    DocumentViewPageComponent,
    DocumentViewPageWithoutAuthComponent,
    DocumentCheckPageWithoutAuthComponent,

    TemplateSignerViewPageComponent,
    TemplateCreatePageComponent,
    TemplateEditPageComponent,
    TemplateViewPageComponent,

    DocumentCardCreateComponent,
    DocumentCardEditComponent,
    DocumentCardViewComponent,
    DocumentCheckCardViewComponent,

    TemplateCardCreateComponent,
    TemplateCardEditComponent,
    TemplateCardViewComponent,

    OtherDocumentCreateComponent,
    OtherDocumentEditComponent,
    OtherDocumentViewComponent,

    AvrDocumentCreateComponent,
    AvrDocumentEditComponent,
    AvrDocumentViewComponent,

    TemplateViewComponent,
    TemplateCreateComponent,
    TemplateEditComponent,

    CheckDocumentViewComponent,
    // TemplateChildDocumentEditComponent,
    // TemplateChildDocumentViewComponent,

        LetterDocumentCreateComponent,
        LetterDocumentEditComponent,
        LetterDocumentViewComponent,

        DocumentValidationComponent,
        DocumentValidationPageComponent,

    AvrInfoComponent,
    ContractorInfoComponent,
    ContractInfoComponent,
    AttachedDocumentsComponent,
    BasisSelectionComponentComponent,


  ],
  imports: [
    CommonModule,
    DxDataGridModule,
    DxSelectBoxModule,
    DxFileUploaderModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    CollapseModule,
    UserComponentModule,
    FormsModule,
    DirectivesModule,
    SharedModule,
    MatTabsModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    LayoutModule,
    ModalModule.forRoot(),
    RouterModule,
    ClipboardModule
  ],

  providers: [
    DatePipe,
    ModalDialogService,
    { provide: ServiceCenter, useClass: ServiceCenter },
    AvrDocumentHttpService,
    DocumentPackageService,
    HttpClient
  ],
  bootstrap: [],
  exports: [
    OtherDocumentCreateComponent,
    OtherDocumentEditComponent,
    OtherDocumentViewComponent,
    AvrDocumentCreateComponent,
    AvrDocumentEditComponent,
    AvrDocumentViewComponent,

    TemplateCardCreateComponent,
    TemplateCardEditComponent,
    TemplateCardViewComponent,

        TemplateCreateComponent,
        TemplateEditComponent,
        TemplateViewComponent,

        LetterDocumentCreateComponent,
        LetterDocumentEditComponent,
        LetterDocumentViewComponent
    ],
    entryComponents: [
        OtherDocumentCreateComponent,
        OtherDocumentEditComponent,
        OtherDocumentViewComponent,
        AvrDocumentCreateComponent,
        AvrDocumentEditComponent,
        AvrDocumentViewComponent,

    TemplateCardCreateComponent,
    TemplateCardEditComponent,
    TemplateCardViewComponent,

        TemplateCreateComponent,
        TemplateEditComponent,
        TemplateViewComponent,

        LetterDocumentCreateComponent,
        LetterDocumentEditComponent,
        LetterDocumentViewComponent
    ],
})
export class DocumentsModule {
  constructor(router: Router) { }
}
