import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../../../core/services';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../core/services/auth.service';
import { DocumentHistory } from '../../../../core/models/document-history.model';
import { DocumentMediator } from '../../../services/document-mediator.service';
import { TemplateCard } from '../../../../core/models/template-card.model';

@Component({
  selector: 'template-card-view',
  templateUrl: './template-card-view.component.html',
  styleUrls: ['./template-card-view.component.scss'],
})
export class TemplateCardViewComponent implements OnInit, OnDestroy {
  public uniqueCode = '';
  public systemRegistrationDate = '';
  public name = '';
  public updateDate = '';
  public version = '';
  public documentWithQr: boolean;
  public isActive: boolean;
  public summary = '';
  public recipientName = '';

  public showAllInfo = false;
  public templateCard: TemplateCard = new TemplateCard();
  private subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private notification: NotificationService,
    private mediator: DocumentMediator,
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
    const subs = this.activatedRoute.params.subscribe(x => {
      this.uniqueCode = x.uniqueCode;
      this.subscribeToCardInfoChanging();
      this.subscribeToTemplateStatusChanging();
    });
    this.subscriptions.push(subs);
    const sub = this.activatedRoute.params.subscribe(x => {
    });

    this.subscriptions.push(sub);
    if (this._authService.isLoggedIn()) {
      this.showAllInfo = true;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
    this.mediator.unsubscribe('setCardInfo');
  }
  public subscribeToTemplateStatusChanging(): void {
    this.mediator.subscribe('changeTemplateStatus', () => { this.isActive = !this.isActive; })
  }
  public subscribeToCardInfoChanging(): void {
    this.mediator.subscribe('setCardInfo', (info) => {
      if (info.data) {
        this.systemRegistrationDate = info.data.systemRegistrationDate;
        this.documentWithQr = info.data.documentWithQr;
        this.isActive = info.data.isActive;
        this.summary = info.data.summary;
        this.name = info.data.name;
        this.updateDate = info.data.updateDate;
        this.version = info.data.version;
        this.recipientName = info.data.recipientContractedName ? info.data.recipientContractedName : info.data.recipientFullName;
      } else {
        this.notification.showNotification(info.error.text);
      }
    })
  }
  public copiedCallback(event) {
    this.notification.showNotification(`Ссылка скопирована`);
  }
}
