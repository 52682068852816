
import { FormBuilder, FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { OnInit, Component } from '@angular/core';
import { min } from 'date-fns';
import { ModalDialogBase } from '../../../../../app/shared/services/modal-dialog.service';
import { AvrDetail } from '../../../../../app/cabinet/models/avrDetail';
import { VAT } from '../../../../../app/cabinet/models/VAT';
import { untilToday } from '../../../../../app/core/validators';
import { formatDate } from '@angular/common';

@Component({
  selector: 'avr-detail',
  templateUrl: './avr-detail.component.html',
  styleUrls: ['./avr-detail.component.scss'],
})
export class AvrDetailComponent extends ModalDialogBase<AvrDetail> implements OnInit {
  public workForm: FormGroup;
  public VATSource = [new VAT('Без НДС', 0), new VAT('0%', 1), new VAT('12%', 2)];
  public today = '';
  public isEditing: boolean = false;
  /**
 *
 */
  constructor(private fb: FormBuilder) {
    super();
    // TODO create extension func
    this.today = new Date().toISOString().substring(0, 10);
    this.initFormGroup();
  }

  set initialData(value: AvrDetail) {
    Object.getOwnPropertyNames(value)
      .filter(x => x != 'vat')
      .map((name: keyof AvrDetail) => {
        if (name == 'date') {
          this.workForm.controls[name].patchValue(formatDate(value[name], 'yyyy-MM-dd', 'en'), {
            emitEvent: false,
          });
        } else {
          this.workForm.controls[name].patchValue(value[name], {
            emitEvent: false,
          });
        }
      });

    const index = this.VATSource.findIndex(x => x.value == +value.vat);
    this.workForm.controls['vat'].patchValue(this.VATSource[index].value, {
      emitEvent: false,
    });
  }
  ngOnInit(): void {
    if (!this.workForm.get('vat').value) {
      this.workForm.controls['vat'].setValue(this.VATSource[0].value, {
        emitEvent: false,
      });
    }
    this.workForm.get('count').valueChanges.subscribe(() => this.updateFields());
    this.workForm.get('price').valueChanges.subscribe(() => this.updateFields());
    this.workForm.get('vat').valueChanges.subscribe(() => this.updateFields());
    this.workForm.get('costWithoutIndirectTaxes').valueChanges.subscribe(() => this.updateFields());
    this.workForm.get('cost').valueChanges.subscribe(() => this.updateFieldsReverse());
  }
  public onSubmit(): void {
    if (this.workForm.valid) {
      this.returnResult(AvrDetail.createFromForm(this.workForm));
      this.workForm.reset();
    } else {
      (<any>Object).values(this.workForm.controls).forEach(control => {
        control.markAsTouched();
        if (control.controls) {
          this.markFormGroupTouched(control);
        }
      });
    }
  }
  get isFieldDisabled() {
    return !this.getFieldValue('withoutFormulas') ? true : null;
  }
  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control: any) => {
      if (control instanceof FormControl) {
        control.markAsTouched();
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
  public closeBtnClick(): void {
    this.returnResult(null);
  }
  private initFormGroup() {
    this.workForm = this.fb.group({
      name: ['', Validators.maxLength(255)],
      date: [this.today, untilToday('Дата не может быть больше сегоднящнего числа')],
      info: ['', Validators.maxLength(2000)],
      unit: ['', Validators.maxLength(50)],
      count: [''],
      withoutFormulas: [''],
      costWithoutIndirectTaxes: ['', [Validators.maxLength(17), Validators.pattern('^[0-9]*\.[0-9][0-9]$') ] ],
      productionTurnoverRate: ['', [Validators.maxLength(17), Validators.pattern('^[0-9]*\.[0-9][0-9]$')]],
      vat: [''],
      vatSum: ['', [Validators.maxLength(17), Validators.pattern('^[0-9]*\.[0-9][0-9]$')]],
      price: ['', Validators.min(1)],
      cost: ['', [Validators.maxLength(17), Validators.pattern('^[0-9]*\.[0-9][0-9]$')]],
    });
  }

  private updateFields() {
    if (!this.getFieldValue('withoutFormulas')) {
      this.updateCostWithoutIndirectTaxes();
      this.updateProductionTurnoverRate();
      this.updateVATSum();
      this.updateCost();
    }
  }

  private updateFieldsReverse() {
    if (!this.getFieldValue('withoutFormulas')) {
      this.updateVATSumRevere();
      this.updateProductionTurnoverRateReverse();
      this.updateCostWithoutIndirectTaxesReverse();
      this.updatePriceReverse();
    }
  }
  private getValueOfVat(vat): number {
    switch (vat) {
      case '2':
        return 12;
      default:
        return 0;
    }
  }

  // count * price;
  private updateCostWithoutIndirectTaxes() {
    const value = this.getFieldValueAsNumber('count') * this.getFieldValueAsNumber('price');
    const decimalValue = this.getTwoDigitDecimal(value);
    this.workForm.controls['costWithoutIndirectTaxes'].setValue(decimalValue, {
      emitEvent: false,
    });
  }
  private updateProductionTurnoverRate() {
    const value = this.getFieldValueAsNumber('costWithoutIndirectTaxes');
    const decimalValue = this.getTwoDigitDecimal(value);
    this.workForm.controls['productionTurnoverRate'].setValue(decimalValue, {
      emitEvent: false,
    });
  }
  private updateVATSum() {
    let value = 0;
    if (this.getFieldValue('vat') == 0) {
      value = this.getFieldValueAsNumber('productionTurnoverRate') * 0;
    } else {
      const vatAsDecimal = this.getValueOfVat(this.getFieldValue('vat')) / 100;
      value = this.getFieldValueAsNumber('productionTurnoverRate') * vatAsDecimal;
    }
    const decimalValue = this.getTwoDigitDecimal(value);
    this.workForm.controls['vatSum'].setValue(decimalValue, {
      emitEvent: false,
    });
  }

  private updateCost() {
    const value = this.getFieldValueAsNumber('vatSum') + this.getFieldValueAsNumber('productionTurnoverRate');
    const decimalValue = this.getTwoDigitDecimal(value);
    this.workForm.controls['cost'].setValue(decimalValue, {
      emitEvent: false,
    });
  }
  // const * VAT / (1 + VAT)
  private updateVATSumRevere() {
    const vat: number = this.getFieldValue('vat') == 0 ? 0 : this.getValueOfVat(this.getFieldValue('vat'));
    const vatAsDecimal = vat / 100;
    const value = (this.getFieldValueAsNumber('cost') * vatAsDecimal) / (1 + +vatAsDecimal);
    const decimalValue = this.getTwoDigitDecimal(value);
    this.workForm.controls['vatSum'].setValue(decimalValue, {
      emitEvent: false,
    });
  }

  // cost - VATSum
  private updateProductionTurnoverRateReverse() {
    const value = this.getFieldValueAsNumber('cost') - this.getFieldValueAsNumber('vatSum');
    const decimalValue = this.getTwoDigitDecimal(value);
    this.workForm.controls['productionTurnoverRate'].setValue(decimalValue, {
      emitEvent: false,
    });
  }
  private updateCostWithoutIndirectTaxesReverse() {
    const value = this.getFieldValueAsNumber('productionTurnoverRate');
    const decimalValue = this.getTwoDigitDecimal(value);
    this.workForm.controls['costWithoutIndirectTaxes'].setValue(decimalValue, {
      emitEvent: false,
    });
  }
  private updatePriceReverse() {
    const count = this.getFieldValueAsNumber('count');
    if (!count) {
      this.workForm.controls['price'].setValue(0, {
        emitEvent: false,
      });
      return;
    }
    const value = this.getFieldValueAsNumber('costWithoutIndirectTaxes') / count;
    const decimalValue = this.getTwoDigitDecimal(value);
    this.workForm.controls['price'].setValue(decimalValue, {
      emitEvent: false,
    });
  }
  private getTwoDigitDecimal(value: number) {
    if (Number.isNaN(value)) {
      return 0;
    }
    return parseFloat(`${value}`).toFixed(2);
  }
  private getFieldValueAsNumber(fieldName: string) {
    return +this.workForm.controls[fieldName].value;
  }
  private getFieldValue(fieldName: string) {
    return this.workForm.controls[fieldName].value;
  }
}
