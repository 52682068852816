/* eslint-disable no-console */
import { IMediator } from './IMediator';
import { IMediatorRequest } from './IMediatorRequest';

export abstract class MediatorBase implements IMediator {
    protected events: Object = {};
    protected handlers: Object = {};

    public subscribe(eventKey: string, callback: Function): void {
        if (!this.events[eventKey]) {
            this.events[eventKey] = [];
        }
        this.events[eventKey].push(callback);
    }

    public send<T>(request: IMediatorRequest): T | void {
        return this.handlers[request.type](request.message);
    }

    public publish(request: IMediatorRequest) {
        for (const prop in this.events) {
            if (!this.events.hasOwnProperty(prop)) {
                continue;
            }
            if (prop == request.type) {
                this.logEvent(request);
                this.events[prop].forEach((callback: Function) => callback(request.message));
            }
        }
    }

    public handle(eventKey: string, callback: Function) {
        if (!this.handlers[eventKey]) {
            this.handlers[eventKey] = callback;
        } else {
            throw new Error('You cant set multiple handles for 1 mediator.type');
        }
    }

    public unsubscribe(type: string) {
        // TODO think about unsubscribe 1by1
        this.events[type] = [];
    }

    public unHandle(type: string) {
        delete this.handlers[type];
    }

    private logEvent(request: IMediatorRequest) {
        const loggingEntity = this.buildMessage(request.type, request.message);
        if (console['logAction']) {
            console['logAction'](request.type, loggingEntity);
        }
    }

    private buildMessage = (type, message) => `%c
       [ACTIONS LOGGER]
       [TIME-${new Date().toLocaleTimeString()}]
       [ACTION   ${type}]
       [PAYLOAD ${JSON.stringify(message)}]
      `;
}
