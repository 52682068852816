import { Component, ViewChild,  OnInit} from '@angular/core';
import { NotificationService, NcalayerService, AuthService } from '../../../core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangesService } from '../../../core/services/changes.service';
import { TemplateCardViewComponent } from '../../card-types/template-card-component/view/template-card-view.component';
import {  FormBuilder} from '@angular/forms';
import { MatDialog,  } from '@angular/material';

import { Subscription, of } from 'rxjs';
import { DocumentMediator } from '../../services/document-mediator.service';
import { MediatorRequest } from '../../../core/mediator/IMediatorRequest';
import { ModalDialogService } from '../../../shared/services/modal-dialog.service';
import { TemplateViewComponent } from '../../document-types/template/view/template-view.component';
import { TemplateProcessingService } from '../../../core/services/template-processing.service';

@Component({
    selector: 'template-view-page',
    templateUrl: './template-view-page.component.html',
    styleUrls: ['./template-view-page.component.scss'],
})
export class TemplateViewPageComponent implements OnInit {
    @ViewChild('templateCardViewComponent')
    templateCardViewComponent: TemplateCardViewComponent;
    @ViewChild('templateViewConponent') templateViewComponent: TemplateViewComponent;
    public canBeSigned: boolean;
    public canBeEdited: boolean;
    public isActive: boolean;
    private subscriptions: Subscription[] = [];
    private uniqueId: string;
    constructor(
        private templateProcessing: TemplateProcessingService,
        private ncalayer: NcalayerService,
        private notification: NotificationService,
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private _changesService: ChangesService,
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private mediator: DocumentMediator,
        private modalDialogService:ModalDialogService
    ) {}

    ngOnInit(): void {
        const subs = this.activatedRoute.params.subscribe(roueteParams => {
            this.uniqueId = roueteParams.uniqueCode;
            const cards = this.templateProcessing.loadCardInfo(this.uniqueId).subscribe(loadCardResult => {
                this.canBeSigned = loadCardResult.data.canBeSigned;
                this.canBeEdited = loadCardResult.data.canBeEdited;
                this.isActive = loadCardResult.data.isActive;
                this.mediator.publish(new MediatorRequest('setCardInfo', loadCardResult));
            });
            this.subscriptions.push(cards);
        });
        this.subscriptions.push(subs);
    }
    public edit() {
        this.router.navigate([`/cabinet/template/edit/${this.uniqueId}`]);
    }
    public makeArchive() {
      if (confirm(`Шаблон не будет доступен, вы уверены?`)) {
        this.templateProcessing.makeArchive(this.uniqueId).subscribe(x => {
          if (x.error != null) {
            this.notification.showNotification(x.error.text);
            return;
          }
          this.changeStatus();
        })
      }
    }
    public makeActive() {
      if (confirm(`Шаблон будет опубликован, вы уверены?`)) {
        this.templateProcessing.makeActive(this.uniqueId).subscribe(x => {
          if (x.error != null) {
            this.notification.showNotification(x.error.text);
            return;
          }
          this.changeStatus();
        })
      }
    }
    private changeStatus(){
        this.isActive = !this.isActive;
        this.mediator.publish(new MediatorRequest('changeTemplateStatus'));
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
    }
}
