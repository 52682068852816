import { Component, ViewChild, ViewContainerRef, OnInit, ComponentFactoryResolver, Type } from '@angular/core';
import { ISaveSupport } from '../../isavesupport';
import { NotificationService, AuthService } from '../../../core/services';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangesService } from '../../../core/services/changes.service';
import { MediatorRequest } from '../../../core/mediator/IMediatorRequest';
import { DocumentMediator } from '../../services/document-mediator.service';
import { TemplateCreateComponent } from '../../document-types/template/create/template-create.component';
import { TemplateProcessingService } from '../../../core/services/template-processing.service';

@Component({
    selector: 'template-create-page',
    templateUrl: './template-create-page.component.html',
    styleUrls: ['./template-create-page.component.scss'],
})
export class TemplateCreatePageComponent implements OnInit {
    @ViewChild('templateCardCreateComponent') documentCardCreateComponent: ISaveSupport;
    @ViewChild('templateCreateComponent') templateCreateComponent: TemplateCreateComponent;

    constructor(
        private notificationService: NotificationService,
        private templateProcessingService: TemplateProcessingService,
        private router: Router,
        private _authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private _changesService: ChangesService,
        private mediator: DocumentMediator
    ) {}

    ngOnInit(): void {
        this._changesService.changed = false;
    }
    get isSubmitDisabled() {
        return !this._changesService.changed || !this.mediator.send(new MediatorRequest('validateTemplateCard')); ;
    }
    saveDocument() {
        const isTemplateCardPageValid = this.mediator.send(new MediatorRequest('validateTemplateCard'));
        if (!isTemplateCardPageValid) {
            return;
        }
        const isDetailsPageValid = this.mediator.send(new MediatorRequest('validateDetailsData'));
        if (!isDetailsPageValid) {
            return;
        }
        const cardData = this.mediator.send<any>(new MediatorRequest('getCardData'));
        const detailsData = this.mediator.send(new MediatorRequest('getDetailsData'));
        this.templateProcessingService.createTemplate(cardData, detailsData).subscribe(resp => {
            if (resp.data) {
                this.notificationService.showNotification(`Данные успешно сохранены - ${resp.data}`, () => {
                    this.router.navigate([`cabinet/template/view/${resp.data}`]);
                });
            } else {
                this.notificationService.showNotification(resp.error.text);
            }
        });
    }
  cancelDocument() {
      if (confirm(`Вы действительно хотите отменить создание шаблона?`)) {
        this.router.navigate(['cabinet/']);
        this._authService.setBreadcrumb('Исходящие', 'На подписании');
        return;
      }
    }
}
