import { ModalDialogBase } from './../../../../shared/services/modal-dialog.service';
import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService, AuthService } from '../../../services';
import { ProfileService } from '../../../services/profile-operations.service';
import { IProfile } from '../../../models/profileresponse.model';
import { ApiResult } from "../../../models/apiresult.model";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { custom } from 'devextreme/ui/dialog';
import { formatDate } from '@angular/common';

// TODO KIRILL: make a dummpu component from it.
@Component({
  selector: 'dialog-profile-change-component',
  templateUrl: './dialog-profile-change.component.html',
  styleUrls: ['./dialog-profile-change.component.scss'],
})
export class DialogProfileChangeComponent extends ModalDialogBase<any> {
  public Form: FormGroup;
  public legal = false;
  public src: any;
  public fileToUpload: File = null;
  public phone: string;
  public city: string;
  public address: string;
  public dataset2: any[] = [];

  public uniqueId: string;
  public proxyUniqueId: string = '';
  public proxyNumber: string;
  public proxyExpirationDate: string;
  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public profileService: ProfileService,
    private notification: NotificationService,
    private _authService: AuthService,
  ) {
    super();
    const formatter = '+7 (';
    this.Form = this.formBuilder.group({
      phone: [formatter],
      city: [''],
      address: [''],
      uniqueId: ['']

    });
    // todo ner avatar
  }

  ngOnInit(): void {
    if (this.phone != null) {
      const formatter = this.deFormatterPhone(this.phone);
      this.Form.get('phone').setValue(formatter);
    }
    if (this.address != null) {
      this.Form.get('address').setValue(this.address);
    }
    if (this.city != null) {
      this.Form.get('city').setValue(this.city);
    }
    this.setProxyInfo();
    this.getKato().subscribe(s => {
      this.dataset2 = s;
    });
    this.src = '/api/profile/getavatar?uniqueId=' + this.uniqueId;
  }

  getKato() {
    return this.http.get<ApiResult<any>>('/api/Reference/GetKato').pipe(
      map(res => res.data),
    );
  }

  setProxyInfo() {
    return this.http.get<ApiResult<any>>('/api/Proxy/GetInfoForProfile?uniqueId=' + this.uniqueId).subscribe(response => {
      if (response.data) {
        this.proxyUniqueId = response.data.proxyUniqueId;
        this.proxyNumber = response.data.proxyNumber;
        this.proxyExpirationDate = formatDate(response.data.proxyExpirationDate, 'yyyy-MM-dd', 'en');
      }
    });
  }

  public proxyDownload(): void {
    if (confirm(`Скачать доверенность?`)) {
      const downloadLink = '/api/Proxy/GetProxyPdf?uniqueId=' + this.proxyUniqueId;
      this.http.get(downloadLink,
        { responseType: 'arraybuffer' }).subscribe(pdfFileBytes => this.downLoadFile(pdfFileBytes));
    }
  }

  private downLoadFile(data) {
    const dataType = 'application/zip';
    const binaryData = [];
    binaryData.push(data);
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
    downloadLink.setAttribute('download', `proxy.zip`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  public proxyDelete(): void {
    const customDialog = custom({
      title: 'Внимание',
      message: 'Вы действительно хотите удалить доверенность?',
      buttons: [
        {
          text: 'Да',
          onClick: d => {
            const deleteLink = '/api/Proxy/Delete?uniqueId=' + this.proxyUniqueId;
            this.http.delete(deleteLink).subscribe(response => {
              this.proxyUniqueId = '';
            });
            return true;
          },
        },
        { text: 'Нет', onClick: d => false },
      ],
    });
    customDialog
      .show();
  }
  ngOnDestroy(): void {}

  deFormatterPhone(value: string) {
    return '+7 (' + value.substr(2, 3) + ') ' + value.substr(5, 3) + '-' + value.substring(8);
  }
  inputPhone(event: any) {
    if (this.Form.get('phone').value.length >= 5) {
      this.Form.get('phone').setValidators([Validators.minLength(17)]);
    } else {
      this.Form.get('phone').clearValidators();
    }
  }
  getError(control: AbstractControl, error: string): boolean {
    if (control.touched && control.errors && error in control.errors) {
      return true;
    }
  }

  public cancelClick(): void {
    this.returnResult(null);
  }
  saveChanges() {
    const param = {
      'uniqueId': this.uniqueId,
      'phone': this.formatterPhone(this.Form.get('phone').value),
      'city': this.Form.get('city').value,
      'address': this.Form.get('address').value,
    };
    this.profileService.sarc = this.src;
    this.returnResult({ fileToUpload: this.fileToUpload, params: param });
    const profile: IProfile = this._authService.getProfile();
    profile.address = this.Form.get('address').value;
    profile.city = this.Form.get('city').value;
    profile.phone = this.Form.get('phone').value;
    this._authService.setProfile(profile);
  }
  formatterPhone(value) {
    if (value.length < 17) {
      return '';
    }
    return '+' + value.replace(/(\D)/g, '');
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.src = event.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);
  }
}
