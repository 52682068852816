import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function password(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const passVal = control.value;
        const valid = !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,15}$/.exec(passVal);
        if (passVal.length && valid) {
            return {
                password:
          'Пароль должен содержать:<br/>- Символы английского алфавита, <br/>- не менее 1 цифры,<br/>- не менее 8 и не более 15 символов,<br/>  в том числе:<br/>- строчные и заглавные буквы.',
            };
        }
    };
}
