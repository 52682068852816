import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../models/apiresult.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { map } from 'rxjs/internal/operators/map';
import { AvrDocumentHttpService } from '../http.services/avr-document-http.service';
import { nonZero } from '../validators/nonZero';

// TODO KIRILL : figure out why here static service.
@Injectable({
    providedIn: 'root',
})
export class AvrDocumentService {
    public today = '';
    constructor(private avrDocumentHttpService: AvrDocumentHttpService, private fb: FormBuilder) {
        this.today = new Date().toISOString().substring(0, 10);
    }

    static minLengthForPhone(control: AbstractControl): { [key: string]: any } {
        const wrongPatternText = 'Номер телефона введен не корректно';
        if (!control.value) {
            return;
        }
        // first input triggers before mask applying
        if (control.value.length == 1) {
            return { min: wrongPatternText };
        }
        // it means +7_(
        if (control.value.length <= 4) {
            return;
        }
        if (control.value.length < 17) {
            return { min: wrongPatternText };
        }
    }
}
