import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {ModalDialogBase} from '../../../../shared/services/modal-dialog.service';
import {FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';
import {AuthService} from '../../../services';
import {email} from '../../../validators';
import {InviteRequest} from '../../../models/invite-request.model';
import {xinValidator} from '../../../validators/xin.validator';

@Component({
  selector: 'dialog-invite-component',
  templateUrl: './dialog-invite.component.html',
  styleUrls: ['./dialog-invite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogInviteComponent extends ModalDialogBase<InviteRequest> implements OnInit {
  public inviteForm: FormGroup;

  constructor(
    formBuilder: FormBuilder,
    private authService: AuthService,
  ) {
    super();
    this.inviteForm = formBuilder.group({
      xin: ['', [Validators.required, xinValidator('any')]],
      isIe: [false, Validators.required],
      contragentType: [''],
      email: ['', [Validators.required, email()]],
      comments: ['']
    });
  }

  ngOnInit(): void {
    // throw new Error("Method not implemented.");
  }

  public isIin(control: AbstractControl): boolean {
    if (control.value.length > 5 && control.value[4] < 3) {
      this.inviteForm.controls['contragentType'].setValidators([Validators.required]);
      this.inviteForm.controls['contragentType'].updateValueAndValidity();
      return true;

    }
    this.inviteForm.controls['contragentType'].clearValidators();
    this.inviteForm.controls['contragentType'].setValue(1);
    this.inviteForm.controls['contragentType'].updateValueAndValidity();
    return false;
  }

  public submit() {
    const currentProfile = this.authService.getProfile();
    const rawValue = this.inviteForm.getRawValue();
    const res = new InviteRequest({
      senderFullName: currentProfile.fullName,
      senderXin: currentProfile.xin,
      inviteClientRequest: {
        email: rawValue.email,
        xin: rawValue.xin,
        fullName: rawValue.xin,
        comment: rawValue.comments,
        isJuridic: false,
        contragentType: rawValue.contragentType
      }
    });
    this.returnResult(res);
  }

  public cancel() {
    this.returnResult(null);
  }

  getCurrentContragentType() {
    const individualEnum = 0;
    const legalEnum = 1;
    const individualEnterpreneurEnum = 2;

    return this.inviteForm.get('isIe').value ? individualEnterpreneurEnum :
      this.isIin(this.inviteForm.get('xin')) ? individualEnum : legalEnum;
  }
}

class XinHelper {

  public static GetControlDigit(xin: string, offset: number) {
    let controlDigit = 0;
    for (let index = 0; index < 11; index++) {
      const hz = +xin.charAt(index);
      controlDigit = (controlDigit + hz * (((index + offset) % 11) + 1)) % 11;
    }
    return controlDigit;
  }

  public static isBinNotValid(control: AbstractControl) {
    const xin = control.value;
    if (xin.length > 11) {
      let controlDigit = XinHelper.GetControlDigit(xin, 0);
      if (controlDigit == 10) {
        controlDigit = this.GetControlDigit(xin, 2);
      }
      const last = +xin.slice(-1);
      if (controlDigit != last) {
        return true;
      }
      const mon = XinHelper.GetNumber(xin, 2);
      if (mon < 1 || mon > 12) {
        return true;
      }
      const type = XinHelper.GetNumber(xin, 2);
      const isBin = type > 3;
      if (isBin) {
        // return true
      } else {
        const metadata = XinHelper.GetNumber(xin, 6, 1);
        if (metadata < 0 || metadata > 8) {
          return true;
        }
        const century = (metadata - 1) / 2;
        const date = new Date(XinHelper.GetNumber(xin, 0) + (18 + century) * 100, XinHelper.GetNumber(xin, 2), 0).getDate();
        const day = XinHelper.GetNumber(xin, 4);
        if (day < 1 || day > date) {
          return true;
        }
      }
    }
    return false;
  }

  private static GetNumber(xin: string, offset: number, len = 2): number {
    return +xin.substr(offset, len);
  }
}
