import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainViewerInboxOnsigningComponent } from '../../cabinet/main-viewer/main-viewer-types/inbox/onsigning/main-viewer-onsigning.component';
import { MainViewerInboxSignedComponent } from '../../cabinet/main-viewer/main-viewer-types/inbox/signed/main-viewer-signed.component';
import { MainViewerOutgoingDraftComponent } from '../../cabinet/main-viewer/main-viewer-types/outgoing/draft/main-viewer-draft.component';
import { MainViewerInboxRejectedComponent } from '../../cabinet/main-viewer/main-viewer-types/inbox/rejected/main-viewer-rejected.component';
import { MainViewerInboxRetiredComponent } from '../../cabinet/main-viewer/main-viewer-types/inbox/retired/main-viewer-retired.component';
import { MainViewerOutgoingOnSigningComponent } from '../../cabinet/main-viewer/main-viewer-types/outgoing/onsigning/main-viewer-onsigning.component';
import { MainViewerOutgoingSignedComponent } from '../../cabinet/main-viewer/main-viewer-types/outgoing/signed/main-viewer-signed.component';
import { MainViewerOutgoingRejectedComponent } from '../../cabinet/main-viewer/main-viewer-types/outgoing/rejected/main-viewer-rejected.component';
import { MainViewerOutgoingRetiredComponent } from '../../cabinet/main-viewer/main-viewer-types/outgoing/retired/main-viewer-retired.component';
import { MainViewerTemplateActiveComponent } from '../../cabinet/main-viewer/main-viewer-types/template/active/main-viewer-template-active.component';
import { MainViewerTemplateArchiveComponent } from '../../cabinet/main-viewer/main-viewer-types/template/archive/main-viewer-template-archive.component';
import { MainViewerInLettersComponent } from '../../../app/cabinet/main-viewer/main-viewer-types/inbox/letters/main-viewer-in-letters.component';
import { MainViewerOutLettersComponent } from '../../../app/cabinet/main-viewer/main-viewer-types/outgoing/letters/main-viewer-out-letters.component';

@Injectable({
    providedIn: 'root',
})
export class MainViewerService {
    constructor(private _http: HttpClient) {}

    getMainViewerDetailsComponent(groupType: string, status: string) {
        switch (groupType) {
            case 'outgoing':
                switch (status) {
                    case 'draft':
                        return MainViewerOutgoingDraftComponent;
                    case 'onsigning':
                        return MainViewerOutgoingOnSigningComponent;
                    case 'signed':
                        return MainViewerOutgoingSignedComponent;
                    case 'rejected':
                        return MainViewerOutgoingRejectedComponent;
                    case 'retired':
                        return MainViewerOutgoingRetiredComponent;
                    case 'lettersent':
                            return MainViewerOutLettersComponent;
                    default:
                        return MainViewerOutgoingDraftComponent;
                }
            case 'template':{
                switch(status){
                    case 'active':
                        return MainViewerTemplateActiveComponent;
                    case 'archive':
                        return MainViewerTemplateArchiveComponent;
                    default:
                        return MainViewerTemplateActiveComponent;
                }
            }
            case 'inbox':
            default:
                switch (status) {
                    case 'onsigning':
                        return MainViewerInboxOnsigningComponent;
                    case 'signed':
                        return MainViewerInboxSignedComponent;
                    case 'rejected':
                        return MainViewerInboxRejectedComponent;
                    case 'retired':
                        return MainViewerInboxRetiredComponent;
                    case 'lettersent':
                        return MainViewerInLettersComponent;
                    default:
                        return MainViewerInboxOnsigningComponent;
                }
        }
    }
}
