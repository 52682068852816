import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AvrDocumentService } from '../../../core/services/avr-document.service';
import { DatePipe } from '@angular/common';
import { AvrDocumentHttpService } from '../../../core/http.services/avr-document-http.service';
import DataSource from 'devextreme/data/data_source';
import { AuthorizedStoreCreator } from '../../../core/helpers/authorized-store.creator';
import { DocumentCardState } from '../../state/DocumentCardState';
import { DocumentMediator } from '../../services/document-mediator.service';
@Component({
    selector: 'sc-cause-selection-component',
    templateUrl: './basis-selection-component.component.html',
    styleUrls: ['./basis-selection-component.component.scss'],
})
export class BasisSelectionComponentComponent implements OnInit, OnDestroy {

    public basisDataSource: DataSource;
    @ViewChild('dxSelectBoxComponent') private dxSelectBox;
    constructor(
        private _avrDocumentService: AvrDocumentService,
        private avrDocumentHttpService: AvrDocumentHttpService,
        private store: AuthorizedStoreCreator,
        private mediator: DocumentMediator,
        private datepipe: DatePipe
    ) {
        this.mediator.subscribe('setBasisDropdownValue', (contractUniqueNumber) => {
            this.dxSelectBox.value = contractUniqueNumber;
        });
    }

    ngOnInit() {
        this.basisDataSource = new DataSource(this.store.createStore({
            key: 'uniqueCode',
            loadUrl: '/api/document/GetAvrBaseDocs'
        }));
    }
    ngOnDestroy(): void {
        this.mediator.unsubscribe('setBasisDropdownValue');
    }

    okClicked(e) {
        const uniqueCode = e.itemData.uniqueCode;
        const date = new Date(e.itemData.registrationDate);
        this.setContract(uniqueCode, date);
    }

    onFocusIn() {
        this.focusEvent('hidden');
    }

    onFocusOut() {
        this.focusEvent('auto');
    }

    private focusEvent(value: string) {
        document.getElementsByTagName('body')[0].style.overflow = value;
    }

    private setContract(uCode, date: Date) {
        this.mediator.publish({type: 'setDocumentContractorInfo', message: {contractName: uCode, contractDate: this.datepipe.transform(date, 'yyyy-MM-dd')}});
    }
}
