// eslint-disable max-len
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService, NotificationService, ServiceCenter } from '../../../services';
import DataSource from 'devextreme/data/data_source';
import { AuthorizedStoreCreator } from '../../../helpers/authorized-store.creator';

@Component({
    selector: 'dialog-addpackets-component',
    templateUrl: './dialog-addpackets.component.html',
    styleUrls: ['./dialog-addpackets.component.scss'],
})
export class DialogAddPacketsComponent implements OnInit {
    public Form: FormGroup;
    public packetTypeSource: DataSource;

    constructor(
        private formBuilder: FormBuilder,
        private center: ServiceCenter,
        private authService: AuthService,
        private notificationService: NotificationService,
        private store: AuthorizedStoreCreator,
        public dialogRef: MatDialogRef<DialogAddPacketsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.Form = this.formBuilder.group({
            code: [''],
            count: [1],
            email: [''],
        });
        this.packetTypeSource = new DataSource(
            this.store.createStore({
                key: 'code',
                loadUrl: '/api/packets/getPacketsType?score=' + data.score,
            }),
        );
    }

    ngOnInit(): void { }

    public AddPacket() {
        this.center.addPacket(this.Form.getRawValue()).subscribe(res => {
            if (res.error) {
                this.notificationService.customDialog(res.error.text);
            } else {
                this.notificationService.customDialog('Успешное добавление пакета');
            }
            this.dialogRef.close();
        });
    }

    public cancelClick() {
        this.dialogRef.close();
    }

    get controls() {
        return this.Form.controls;
    }

    public nerValidator(): boolean {
        let result = false;
        // eslint-disable-next-line max-len
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        result = this.controls.email.value.search(re) !== 0;
        return result;
    }
}
