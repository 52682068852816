export interface IMediatorRequest {
    type: string;
    message?: any;
}
export class MediatorRequest implements IMediatorRequest {
    type: string;
    message?: any;
    constructor(type: string, message? : any) {
        this.type = type;
        this.message = message;
    }
}
