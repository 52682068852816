import { IProfile } from '../../../../../core/models/profileresponse.model';
import { AuthService } from '../../../../../core/services/auth.service';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { PhoneMaskHelper } from '../../../../../core/directives/phone-mask.directive';
import { NullString } from '../../../../../../app/core/helpers/nullStringDecorator';

@Component({
    selector: 'contractor-info',
    templateUrl: './contractor-info.component.html',
    styleUrls: ['./contractor-info.component.scss'],
})
export class ContractorInfoComponent implements OnInit {
    // TODO KIRILL: find out the purpose of this array;
    public subscriptions: Subscription[] = [];
    @Input() contractorForm: FormGroup;
    constructor(private _authService: AuthService) {}

    ngOnInit() {
        const profile = this._authService.getProfile();
        this.setSender(profile);
    }

    // TODO KIRILL Refactor this;
    setSender(profile: IProfile): void {
        const addr = `${NullString(profile.city)} ${NullString(profile.address)}`;

        this.contractorForm.patchValue({
            senderName: profile.contragentFullName,
            senderBin: profile.contragentXin,
        });
        this.contractorForm.get('senderName').disable();
        this.contractorForm.get('senderBin').disable();
        const ctrl = this.contractorForm.get('senderAddress');
        if (addr) {
            ctrl.setValue(addr);
        } else {
            ctrl.reset();
      }
        if (profile.phone) {
            const formattedPhone = PhoneMaskHelper.process(profile.phone, false);
            const senderPhone = this.contractorForm.get('senderPhone');
            if (profile.phone) {
                senderPhone.setValue(formattedPhone, { emitEvent: true });
            } else {
                senderPhone.reset();
            }
        }

    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
    }
}
