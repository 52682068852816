import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AuthService } from '../../../../core/services/auth.service';
import { FileService } from '../../../../core/services/file.service';
import { ActivatedRoute } from '@angular/router';
import { AuthorizedStoreCreator } from '../../../../core/helpers/authorized-store.creator';
import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DocumentPackageService } from '../../../services/document-package.service';
import { DocumentMediator } from "../../../services/document-mediator.service";
@Component({
  selector: 'sc-letter-document-view',
  templateUrl: './letter-document-view.component.html',
  styleUrls: ['./letter-document-view.component.scss']
})
export class LetterDocumentViewComponent implements OnInit {
  public dataSource: DataSource;
  public _documentUniqueId = '';
  public uploadUrl = '';
  public profileUniqueId: string;
  public documentCount: number;
  public documentWithQr = false;
  public signed = false;

  otherDocuments: any[] = [];
  private subscriptions: Subscription[] = [];

  constructor(
    private _documentPackageService: DocumentPackageService,
    private _authService: AuthService,
    private _fileService: FileService,
    private store: AuthorizedStoreCreator,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private mediator: DocumentMediator
  ) { }

  ngOnInit(): void {
    const subs = this.activatedRoute.params.subscribe(x => {
      this._documentUniqueId = x.uniqueCode;
      this.uploadUrl = `/api/fileAttachment/addFileToDocument?uniqueCode=${this._documentUniqueId}`;
      this.refreshAttachmentGrid();
      this.subscribeToCardInfoChanging();
    });
    this.subscriptions.push(subs);
    if (this._authService.isLoggedIn()) {
      this.profileUniqueId = this._authService.currentUserValue.profile.uniqueId;
      this.sendViewConfirmationRequest();
    }
    else {
      this.sendAnonymousViewConfirmationRequest();
    }
  }

  refreshAttachmentGrid() {
    let url, store;
    if (this._authService.isLoggedIn()) {
      url = `/api/document/${this._documentUniqueId}/attachments`;
      store = this.store.createStore({
        key: 'uniqueId',
        loadUrl: url,
        deleteUrl: `/api/fileAttachment?uniqueCode=${this._documentUniqueId}`,
      });
    } else {
      url = `/api/anonymous/${this._documentUniqueId}/attachments`;
      store = this.store.createStoreForAnonymous({
        key: 'uniqueId',
        loadUrl: url,
        deleteUrl: `/api/fileAttachment?uniqueCode=${this._documentUniqueId}`,
      });
    }
    store.on('loaded', (data: any[]) => {
      this.documentCount = data.length;
    });
    this.dataSource = new DataSource(store);
  }

  getCountDocument() {
    return this.documentCount;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
    this.mediator.unHandle('changeSignedStatus');
  }

  getFileUrl(uniqueCode: string, fileId: number, profileUniqueId: string) {
    this._fileService.getFileUrl(uniqueCode, fileId, profileUniqueId).subscribe(s => {
      const res = s.data;
      window.open(res);
    });
  }
  getPackUrl(uniqueCode: string) {
    this._fileService.getPackUrl(uniqueCode, this.profileUniqueId).subscribe(s => {
      const res = s.data;
      this._documentPackageService.getPackage(res, this._documentUniqueId);
    });
  }
  private sendViewConfirmationRequest() {
    let headers = new HttpHeaders();
    headers = headers.set('Document-UniqueNumber', this._documentUniqueId);
    this.httpClient.get<boolean>(`api/document/${this._documentUniqueId}/markAsViewed`,
      {
        headers: headers
      }
    ).subscribe(d => {
      if (d) {
        // TODO make resending the request.
      }
    })
  }

  private sendAnonymousViewConfirmationRequest() {
    console.log(this._documentUniqueId);
    let headers = new HttpHeaders();
    headers = headers.set('Document-UniqueNumber', this._documentUniqueId);
    this.httpClient.get<boolean>(`api/anonymous/${this._documentUniqueId}/markAsAnonymousViewed`,
      {
        headers: headers
      }
    ).subscribe(d => {

    });
  }

  getPdfFileUrl(uniqueCode: string, fileId: number) {
    const subs = this._fileService.getPdfFileUrl(uniqueCode, fileId, this.profileUniqueId).subscribe(s => {
      const res = s.data;
      window.open(res);
    });
    this.subscriptions.push(subs);
  }

  public subscribeToCardInfoChanging(): void {
    this.mediator.handle('changeSignedStatus', (canBeEdited) => {
      this.signed = !canBeEdited;
    });
    this.mediator.subscribe('setCardInfo', (info) => {
      if (info.data) {
        this.documentWithQr = info.data.documentWithQr;
        this.signed = info.data.signers.some(signer => signer.isSigned === true);
      }
    });
  }
}
