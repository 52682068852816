import { Component, ViewChild, ViewContainerRef, OnInit, ComponentFactoryResolver } from '@angular/core';
import { NotificationService, NcalayerService } from '../../../core/services';
import { ActivatedRoute } from '@angular/router';
import { DocumentProcessingService } from '../../../core/services/document-processing.service';
import { DocumentCheckCardViewComponent } from '../../card-types/document-card-component/check/document-check-card-view.component';
import { Subscription, of, Observable } from 'rxjs';
import { SignEntity } from '../../../core/models/signEntity';
import { DocumentMediator } from '../../services/document-mediator.service';
import { MediatorRequest } from '../../../../app/core/mediator/IMediatorRequest';
import { ModalDialogService } from '../../../../app/shared/services/modal-dialog.service';
import { RejectDocumentRequest, RejectDocumentWithSignatureRequest, RejectDocumentDialogComponent } from '../../../../app/core/shared-component/dialogs/reject-document/reject-document.component';
import { switchMap } from 'rxjs/operators';
import { NCAResponse } from '../../../../app/core/models/nca-response.model';
import { ApiResult } from '../../../../app/core/models/apiresult.model';
import { CheckDocumentViewComponent } from '../../document-types/check-document/check-document-view.component';
import {TemplateViewComponent} from "../../document-types/template/view/template-view.component";

@Component({
  selector: 'document-check-page',
  templateUrl: './document-check-page.component.html',
  styleUrls: ['../view/document-view-page.component.scss','./document-check-page.component.scss'],
})
export class DocumentCheckPageWithoutAuthComponent implements OnInit {
  @ViewChild('documentCardViewComponent')
  documentCardViewComponent: DocumentCheckCardViewComponent;
  @ViewChild('checkViewConponent') checkViewComponent: CheckDocumentViewComponent;
  documentTypeViewComponent: ViewContainerRef;
  public canBeSigned: boolean;
  public canBeRejected: boolean;
  private docTypeComponentRef: any;
  private subscriptions: Subscription[] = [];
  private currentDocumentType: string;
  private uniqueId: string;
  constructor(
    private factoryResolver: ComponentFactoryResolver,
    private documentProcessing: DocumentProcessingService,
    private ncalayer: NcalayerService,
    private notification: NotificationService,
    private activatedRoute: ActivatedRoute,
    //public dialog: MatDialog,
    private mediator: DocumentMediator,
    private modalDialogService: ModalDialogService

  ) { }

  ngOnInit(): void {
    const subs = this.activatedRoute.params.subscribe(roueteParams => {
      this.currentDocumentType = roueteParams.documentType;
      this.uniqueId = roueteParams.uniqueCode;
      const cards = this.documentProcessing.loadCardInfoAnonymouslyByExternalLink(this.uniqueId).subscribe(loadCardResult => {
        this.canBeSigned = loadCardResult.data.canBeSigned;
        this.canBeRejected = loadCardResult.data.canBeRejected;
        this.mediator.publish(new MediatorRequest('setCardInfo', loadCardResult));

      });
      this.subscriptions.push(cards);
    });
    this.subscriptions.push(subs);
  }
  public rejectDocument() {
    const modal = this.modalDialogService.show2(RejectDocumentDialogComponent, {
      centered: true, windowClass: 'modal-630',
    }, {
      uniqueId: this.uniqueId
    });
    modal[1].getResult()
      .pipe(switchMap((d: RejectDocumentRequest) => {
        if (d) {
          return this.signAndReject(d)
        } else {
          modal[0].close();
          return of(null)
        }
      })).subscribe((x) => {
        if (x != null) {
          if (x.error != null) {
            this.notification.showNotification(x.error.text);
          }
          else {
            this.canBeSigned = false;
            this.canBeRejected = false;
            this.notification.showNotification('Документ был успешно отклонен');
          }
        }
        modal[0].close();
      })
  }

  private signAndReject(rejectDocumentRequest: RejectDocumentRequest): Observable<ApiResult<boolean>> {
    return this.documentProcessing.getSignEntity(this.uniqueId)
      .pipe(switchMap((apiResult: ApiResult<SignEntity>) => {
        return this.sign(apiResult.data)
      }),
        switchMap((ncaResponse) => {
          const request = new RejectDocumentWithSignatureRequest({
            ... { ...rejectDocumentRequest, ...{ signedCMS: ncaResponse.responseObject } }
          });
          return this.documentProcessing.rejectAnonymously(request);
        }));
  }
  signAndSend() {
    const documentCount = this.docTypeComponentRef.instance.getCountDocument();
    if (this.currentDocumentType !== 'avr') {
      if (documentCount < 1) {
        this.notification.showNotification('Количество файлов не должно быть меньше 1');
        return;
      }
    }
    this.documentProcessing.getSignEntity(this.uniqueId).subscribe(x => {
      if (x.error != null) {
        this.notification.showNotification(x.error.text);
        return;
      }
      this.sign(x.data).subscribe(result => {
        this.send(<string>result.responseObject);
      },
        error => {
          this.notification.showSnackNotification(this.ncalayer.getLocalizedErrorMessage(error));
        })
    });
  }

  sign(entity: SignEntity): Observable<NCAResponse> {
    if (entity.lastChainSignature) {

      return this.ncalayer.addSignatureToExistingOne(entity.xmlData, entity.lastChainSignature)
    } else {
      return this.ncalayer.createCMSSignatureFromData(entity.xmlData)
    }
  }

  send(signedXml: string): void {
    this.documentProcessing.sendToSignAnonymously(this.uniqueId, signedXml).subscribe(response => {
      if (response.error != null) {
        this.notification.showNotification(response.error.text);
        return;
      }
      this.canBeSigned = false;
      this.canBeRejected = false;
      this.notification.showNotification('Документ успешно подписан', () => {
      });
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
  }
}
