import { Component, Injector } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { MainViewerBaseComponent } from '../../base/main-viewer-base.component';

@Component({
  selector: 'main-viewer-template-active',
  templateUrl: './main-viewer-template-active.component.html',
  styleUrls: ['./main-viewer-template-active.component.scss'],
})
export class MainViewerTemplateActiveComponent extends MainViewerBaseComponent {
  constructor(injector: Injector) {
    super(injector);
  }

  public Load() {
    this.dataSource = new DataSource(
      this.store.createStore({
        key: 'uniqueCode',
        loadUrl: '/api/template?templateStatus=Active',
      }),
    );
  }
  public detailSrc(data) {
    return data.uniqueCode;
  }
  public copiedCallback(event) {
    this.notificationService.showNotification(`Ссылка скопирована`);
  }
}
