import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
export function xinValidator(type: 'bin' | 'any'): ValidatorFn {
    if (type === 'bin') {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            const res = checkBin(value);
            if (res) {
                return {xin: res};
            }
        };
    } else {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            const res = checkIin(value);
            if (res) {
                return {xin: res};
            }
        };
    }

}


const checkXin = (mode: 'none' | 'bin' | 'any', required = false) => (xin: string) => {
    if (required && (xin == null || xin == '')) {
        return 'Поле обязательное для заполнения';
    }
    if (xin == null || xin == '') {return undefined; } // если пусто то не проверяем
    const xinLength = 12;
    if (xin.length != xinLength || !/^[0-9]+$/.test(xin)) {
        return 'БИН/ИИН должен состоять из 12 цифр';
        // "БИН/ИИН должен состоять из 12 цифр."
    }

    let controlDigit = getControlDigit(xin, 0);
    if (controlDigit == 10) {
        controlDigit = getControlDigit(xin, 2);
    }
    if (controlDigit != charAtN(xin)) {
        return 'Контрольный разряд БИН/ИНН неверный';
        // "Контрольный разряд БИН/ИНН неверный"
    }
    const month = getNumber(xin, 2, 2);
    if (month < 1 || month > 12) {
        return 'Некорректное значение БИН/ИНН';
        // "Некорректное значение БИН/ИНН"
    }
    const type = getNumber(xin, 4, 1);
    if (mode == 'bin') {
        if (type < 3) {
            return 'Контрольный разряд БИН/ИНН неверный';
        }
    }
    if (mode == 'any' && type > 3) {
        return undefined;
    }
    const metadata = getNumber(xin, 6, 1);
    if (metadata < 0 || metadata > 8) {
        return 'Некорректное значение ИНН';
        // "Некорректное значение ИНН"
    }
    const century = (metadata - 1) / 2;
    const date = new Date((18 + century) * 100 + parseInt(getNumber(xin, 0), 10), parseInt(getNumber(xin, 2, 2), 10) - 1, 1);
    const day = getNumber(xin, 4);
    if (day < 1 || day > getDayInMonthCountDict(date.getFullYear(), date.getMonth() + 1)) {
        return 'Некорректное значение ИНН';
        // "Некорректная дата рождения ИИН"
    }
    return undefined;
};
const getDayInMonthCountDict = (year, month) => {
    const array = [31, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30];
    const yearIsLeap = isLeap(year);
    if (yearIsLeap) {
        array[2] = 29;
    }
    return array[month];
};
const isLeap = year => {
    if (year % 4 !== 0) {
        if (year % 100 === 0) {
            if (year % 400 !== 0) {
                return false;
            }
        }
    }
    return true;
};
const charAtN = (xin: string) => Number.parseInt(xin.charAt(xin.length - 1), 10);
const getNumber = (xin, offset, length?: number) => {
    if (length && isNaN(length)) {
        length = offset + 2;
    } else {
        length = length + offset;
    }
    return xin.substring(offset, length);
};
const getControlDigit = (xin, offset) => {
    const baseLength = 11;
    let controlDigit = 0;
    for (let i = 0; i < baseLength; i++) {
        controlDigit = (controlDigit + (xin[i] - 0) * (((i + offset) % baseLength) + 1)) % 11;
    }
    return controlDigit;
};
const checkBin = checkXin('bin');
const checkIin = checkXin('any');
const requiredIin = checkXin('any', true);
