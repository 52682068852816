import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'document-validation-page',
  templateUrl: './document-validation-page.component.html',
  styleUrls: ['./document-validation-page.component.scss'],
})
export class DocumentValidationPageComponent implements OnInit {
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService ) { }
  ngOnInit(): void {
    this.auth.getTabs().splice(0);
    this.activatedRoute.params.subscribe(x => { });
  }
}
