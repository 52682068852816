import { Component, OnInit } from '@angular/core';
import { ModalDialogBase } from '../../../../shared/services/modal-dialog.service';
import { ExtendPacketRequest, GlobalPacketTypeEnum } from '../../../models/extendPacketRequest';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import DataSource from 'devextreme/data/data_source';
import { AuthorizedStoreCreator } from '../../../helpers/authorized-store.creator';
import { ServiceCenter } from '../../../http.services/service.center.http.service';

@Component({
    selector: 'sc-extend-packet',
    templateUrl: './extend-packet.component.html',
    styleUrls: ['./extend-packet.component.scss'],
})
export class ExtendPacketComponent extends ModalDialogBase<ExtendPacketRequest> implements OnInit {
    public form: FormGroup;
    public clientName: string;
    public clientUniqueId: string;
    public currentPacket: string;
    public recipientDS: DataSource;

    constructor(fb: FormBuilder, private store: AuthorizedStoreCreator, private scService: ServiceCenter) {
        super();
        this.form = fb.group({
            clientEmail: ['', Validators.required],
            currentLimit: [{ value: 0, disabled: true }],
            count: ['', [Validators.required, Validators.min(1)]],
            invoice: [0, [Validators.required, Validators.min(1)]],
            extraUsagePrice: [0],
            organizationUniqueId: [''],
        });
    }

    ngOnInit() {
        this.form.controls['count'].valueChanges.subscribe(r => {
            this.form.controls['invoice'].patchValue(this.calculateInvoice(r));
        });
        this.recipientDS = new DataSource(
            this.store.createStore({
                key: 'uniqueId',
                loadUrl: '/api/serviceCenter/getClients',
            }),
        );
    }

    public onRecipientChanged(data) {
        this.scService.getClientByEmail(data.value).subscribe(r => {
            if (r.error) {
                this.form.controls['clientEmail'].setErrors({ customError: r.error.text });
            }
            if (r.currentActivePacketType != GlobalPacketTypeEnum.Monthly) {
                this.form.controls['clientEmail'].setErrors({
                    customError: 'Текущий активный пакет не является допустимым пакетом для увеличения лимита.',
                });
                return;
            }
            let leftCount = 0;
            if (r.leftCount) {
                leftCount = r.leftCount + r.additionalCount;
            }
            this.form.controls['currentLimit'].patchValue(leftCount);
            this.form.controls['extraUsagePrice'].patchValue(r.priceForExtraUsage);
            this.form.controls['organizationUniqueId'].patchValue(r.email);
        });
    }

    public cancel() {
        this.returnResult(null);
    }

    public formatDisplayedValue(data) {
        return `${data.fullName} (${data.email})`;
    }

    public submit() {
        const data = this.form.getRawValue();
        const uniqueId = data.organizationUniqueId;
        const result = {
            clientUniqueId: uniqueId,
            count: data.count,
            invoice: data.invoice,
        };
        this.returnResult(result);
    }

    private calculateInvoice(count: number): number {
        return count * <number>this.form.controls['extraUsagePrice'].value;
    }
}
