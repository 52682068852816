import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';

@Directive({
    selector: '[appTwoDigitDecimaNumber]',
})
export class TwoDigitDecimaNumberDirective implements OnInit {
    // Allow decimal numbers and negative values
    private regex = new RegExp(/^(-{0,1})\d*\.?\d{0,2}$/g);
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

    constructor(private el: ElementRef) {}

    ngOnInit() {
    // better way to subscribe on event, more safe then @hostlistener
        fromEvent(this.el.nativeElement, 'keydown').subscribe((e: any) => {
            this.onKeyDown(e);
        });
    }
    onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current: string = this.el.nativeElement.value;
        const position = this.el.nativeElement.selectionStart;
        const next: string = [
            current.slice(0, position),
            event.key == 'Decimal' ? '.' : event.key,
            current.slice(position),
        ].join('');
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }
}
