import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../models/apiresult.model';

@Injectable({
    providedIn: 'root',
})
export class RegistrationService {
    public ncaLayerStorageName: string;

    constructor(private http: HttpClient) {}

    getTaxationType() {
        return this.http.get<ApiResult<any>>('/api/reference/getTaxationType');
    }

    getModelTypes() {
        return this.http.get<ApiResult<any>>('/api/reference/getModelTypes');
    }

    requestCode(phone: string) {
        return this.http.post<ApiResult<Boolean>>('/api/registration/getCode', {
            PhoneNumber: phone,
        });
    }

    sendCode(phone: string, code: string) {
        return this.http.post<ApiResult<boolean>>('/api/registration/validateCode', {
            PhoneNumber: phone,
            Code: code,
        });
    }

    getSignedXml() {
        return this.http.get<ApiResult<string>>('/api/registration/GetSignedXml');
    }

    getSubjectData(cmsSignature: any) {
        return this.http.post<ApiResult<any>>('/api/registration/GetSubjectData', cmsSignature);
    }

    register(regData: any) {
        return this.http.post<ApiResult<boolean>>('/api/registration', regData);
    }

    checkCertificate(signingData: any) {
        return this.http.post<ApiResult<boolean>>('/api/registration/checkCertificate', {
            SingingString: signingData,
        });
    }
}
