import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import 'devextreme-intl';

import ruMessages from 'devextreme/localization/messages/ru.json';
import { Injectable } from '@angular/core';

export class Locale {
    Name: string;
    Value: string;
}

@Injectable({
    providedIn: 'root',
})
export class LocalizeService {
    public locale: string;
    public locales: Locale[];
    public formatMessage = formatMessage;

    constructor() {
        this.locale = this.getLocale();
        this.locales = [
            {
                'Name': 'English',
                'Value': 'en',
            },
            {
                'Name': 'Русский',
                'Value': 'ru',
            },
        ];
        locale(this.locale);
    }

    getLocales() {
        return this.locales;
    }
    initMessages(dictionary: any = null) {
        loadMessages(ruMessages);
        if (dictionary != null) {
            loadMessages(dictionary);
        }
    }

    changeLocale(data) {
        this.setLocale(data.value);
        parent.document.location.reload();
    }

    getLocale() {
        const ilocale = sessionStorage.getItem('locale');
        return ilocale != null ? ilocale : 'ru';
    }

    setLocale(ilocale) {
        sessionStorage.setItem('locale', ilocale);
    }
}
