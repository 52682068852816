import { Component, Injector } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AuthorizedStoreCreator } from '../../../../../core/helpers/authorized-store.creator';
import { AuthService } from '../../../../../core/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MainViewerBaseComponent } from '../../base/main-viewer-base.component';
import { DocumentProcessingService } from '../../../../../core/services/document-processing.service';
import { ContractionsHelper } from '../../../../../core/directives/organization-contractions.directive';
import { DocumentPackageService } from '../../../../../documents/services/document-package.service';
import { LetterStatus } from '../../../../../core/models/statuses';

@Component({
  selector: 'sc-main-viewer-out-letters',
  templateUrl: './main-viewer-out-letters.component.html',
  styleUrls: ['./main-viewer-out-letters.component.scss']
})
export class MainViewerOutLettersComponent extends MainViewerBaseComponent {
  public letterStatuses: LetterStatus = new LetterStatus();
  constructor(injector: Injector) {
    super(injector);
  }
  public setContractionForContragent = (val) => ContractionsHelper.transformContraction(val.recipientContragentName);
  public Load() {
    this.dataSource = new DataSource(
      this.store.createStore({
        key: 'uniqueCode',
        loadUrl: '/api/document/getUserEmails?documentGroupType=Outgoing',
      }),
    );
  }
}
