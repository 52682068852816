import { Component, OnInit, EventEmitter, Input, Output, Type } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ProfileService } from '../../../services/profile-operations.service';
import { NotificationService } from '../../../services';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { IProfile } from '../../../models/profileresponse.model';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'select-profile',
    templateUrl: './select-profile.component.html',
    styleUrls: ['./select-profile.component.scss'],
})
export class SelectProfileComponent implements OnInit {
    public profiles: IProfile[] = [];
    public src: any;
    public url = '/api/profile/getavatar?uniqueId=';
    public img: any;
    private currentProfile: IProfile;
    constructor(
        private sanitizer: DomSanitizer,
        private authService: AuthService,
        private notification: NotificationService,
        private router: Router,
        private profileService: ProfileService,
        public dialogRef: MatDialogRef<SelectProfileComponent>,
    ) {
        this.profiles = this.authService.getProfiles();
    }
    ngOnInit() {
        this.profiles = this.authService.getProfiles();
    }
    selectCurrentProfile(uniqueId) {
        this.authService.swithProfile(uniqueId);
        this.authService.redirectTo(this.router.url);
        this.dialogRef.close();
    }
}
