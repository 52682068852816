import {
  Component,
  ViewChild,
  ViewContainerRef,
  OnInit,
  ComponentFactoryResolver,
  Type,
  OnDestroy,
} from '@angular/core';
import { ISaveSupport } from '../../isavesupport';
import { NotificationService } from '../../../core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangesService } from '../../../core/services/changes.service';
import { DocumentMediator } from '../../services/document-mediator.service';
import { MediatorRequest } from '../../../core/mediator/IMediatorRequest';
import { TemplateEditComponent } from '../../document-types/template/edit/template-edit.component';
import { TemplateProcessingService } from '../../../core/services/template-processing.service';
import { AuthService } from '../../../core/services';

@Component({
  selector: 'template-edit-page',
  templateUrl: './template-edit-page.component.html',
  styleUrls: ['./template-edit-page.component.scss'],
})
export class TemplateEditPageComponent implements OnInit, OnDestroy {
  @ViewChild('templateCardEditComponent') templateCardEditComponent: ISaveSupport;
  @ViewChild('templateEditComponent')
  templateEditComponent: TemplateEditComponent;
  private uniqueId: string;
  private hasAnyChanges: boolean;
  constructor(
    private notificationService: NotificationService,
    private templateProcessingService: TemplateProcessingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _changesService: ChangesService,
    private mediator: DocumentMediator,
    private _authService: AuthService,
  ) {}
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(x => {
      this.uniqueId = x.uniqueCode;

      this._changesService.changed = false;
      this.mediator.subscribe('modelChanged', handleChanges => {
        if (handleChanges) {
          this.hasAnyChanges = true;
        } else {
          this.hasAnyChanges = false;
        }
      });
    })
  };

  saveDocument() {
    const isTemplateCardPageValid = this.mediator.send(new MediatorRequest('validateTemplateCard'));
    if (!isTemplateCardPageValid) {
      return;
    }
    const isDetailsPageValid = this.mediator.send(new MediatorRequest('validateDetailsData'));
    if (!isDetailsPageValid) {
      return;
    }
    const documentCount = this.templateEditComponent.getCountDocument();
    if (documentCount < 1) {
      this.notificationService.showNotification('Количество файлов не должно быть меньше 1');
      return;
    }
    const cardData = this.mediator.send<any>(new MediatorRequest('getCardData'));
    const detailsData = this.mediator.send(new MediatorRequest('getDetailsData'));
    this.templateProcessingService
      .editTemplate(cardData, detailsData, this.uniqueId)
      .subscribe(resp => {
        if (resp.data) {
          this.notificationService.showNotification(`Данные успешно сохранены - ${resp.data}`, () => {
            this.router.navigate([`cabinet/template/view/${this.uniqueId}`]);
          });
        } else {
          this.notificationService.showNotification(resp.error.text);
        }
      });
  }
  isChanges() {
    return this._changesService.changed ? this._changesService.changed : this.hasAnyChanges;
  }
  cancelEditingAndGoBack() {
    if (confirm(`Вы действительно хотите отменить редактирование шаблона?`)) {
      const documentCount = this.templateEditComponent.getCountDocument();
      if (documentCount < 1) {
        this.notificationService.showNotification('Количество файлов не должно быть меньше 1');
        return;
      }
      this.router.navigate([`cabinet/template/view/${this.uniqueId}`]);
    }
  }
  ngOnDestroy(): void {
    this.mediator.unsubscribe('modelChanged');
  }
}
