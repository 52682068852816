import { Validators } from '@angular/forms';
import { IMediator } from '../../core/mediator/IMediator';
import { Subject } from 'rxjs';
import { IDocumentStateBase } from './iDocumentStateBase';
import { FormGroup, FormBuilder } from '@angular/forms';
import { untilToday, minDate } from '../../core/validators';
import { TemplateCard } from '../../core/models/template-card.model';

declare type documentType = 'template';
export class TemplateCardState implements IDocumentStateBase<any> {
    hasAnyChanges: boolean;
    today: string;
    onStateChanged: Subject<any> = new Subject();
    public documentType: documentType;
    public templateCardForm: FormGroup;
    constructor(
        private formBuilder: FormBuilder,
         private mediator: IMediator

         ) {
        this.templateCardForm = this.formBuilder.group({
            name: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
            isActive: [false, Validators.required],
            documentWithQr: [false, Validators.required],
            summary: ['', Validators.compose([Validators.required, Validators.maxLength(256)])]
        });
        this.mediator.subscribe('setDocumentType', (docType: documentType) => {
            this.documentType = docType;
        });
        this.mediator.handle('getCardData', () => this.getCardData());
        this.mediator.handle('validateTemplateCard', () => this.validate());

    }
    getRawValues() {
        return this.templateCardForm.getRawValue();
    }

    finalizeState(): void {
        this.mediator.unsubscribe('setDocumentType');
        this.mediator.unHandle('validateTemplateCard');
        this.mediator.unHandle('getCardData');
        // / all unsubscribes here
    }
    private getCardData() {
        const formObj = this.templateCardForm.getRawValue();
        const serializedForm = JSON.stringify(formObj);
        const templateCardData: TemplateCard = <TemplateCard>JSON.parse(serializedForm);
        return templateCardData;
    }
    private validate(): boolean {
        return !this.templateCardForm.invalid;
    }
}
