import { Component } from '@angular/core';
import { AuthService } from '../../../../core/services/auth.service';
import { FormGroup, AbstractControl, FormBuilder } from '@angular/forms';
import { DocumentBaseComponent } from '../../base-documnet/create-document-base.component';
import { AvrDocumentService } from '../../../../core/services/avr-document.service';
import { NotificationService } from '../../../../core/services/notification.service';
import { ChangesService } from '../../../../core/services/changes.service';
import { AvrState } from '../../../state/AvrState';
import { DocumentMediator } from '../../../services/document-mediator.service';
import { AvrDocumentHttpService } from '../../../../../app/core/http.services/avr-document-http.service';

@Component({
    selector: 'avr-document-create',
    templateUrl: './avr-document-create.component.html',
    styleUrls: ['./avr-document-create.component.scss'],
})
export class AvrDocumentCreateComponent extends DocumentBaseComponent {
    public today = '';
    public contractorInfo: FormGroup;
    public authService: AuthService;
    avrServ: AvrDocumentService;
    public docList: any[] = [];

    public contractForm: FormGroup;
    public state: AvrState;

    constructor(
        private fb: FormBuilder,
        private _avrDocumentService: AvrDocumentService,
        private avrDocumentHttpService: AvrDocumentHttpService,
        private _authService: AuthService,
        private notificationService: NotificationService,
        private mediator: DocumentMediator,
    ) {
        super(_authService);
        this.today = new Date().toISOString().substring(0, 10);
        this.avrServ = this._avrDocumentService;
    }
    ngOnInit() {
        this.state = new AvrState(this.avrDocumentHttpService, this.fb, this.mediator, this.notificationService);
        this.contractorInfo = this.state.contractorForm;
        this.contractForm = this.state.contractForm;
        this._authService.setBreadcrumb('', 'Создание акта выполненных работ (АВР)');
        this._authService.getTabs().splice(0);
        this.contractForm.get('inputAppPages').disable();
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.contractForm.reset();
        this.contractForm.enable();
        this.state.finalizeState();
        this.subscriptions.forEach(it => it.unsubscribe());
    }
    getError(control: AbstractControl, error: string) {
        return control.touched && control.errors && error in control.errors;
    }


    onValueChanged = e => {
        const ctrl = this.contractForm.get('inputAppPages');
        if (e.value.length < 1) {
            ctrl.setValue(0);
            ctrl.disable();
        } else {
            ctrl.enable();
        }
    };

}
