import { AuthService } from './../../../../../core/services/auth.service';
import { ApiResult } from './../../../../../core/models/apiresult.model';
import { AvrDocumentService } from './../../../../../core/services/avr-document.service';
import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AvrDocumentHttpService } from '../../../../../core/http.services/avr-document-http.service';
import { DocumentMediator } from '../../../../services/document-mediator.service';
import { MediatorRequest } from '../../../../../core/mediator/IMediatorRequest';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'attached-documents',
  templateUrl: './attached-documents.component.html',
  styleUrls: ['./attached-documents.component.scss'],
})
export class AttachedDocumentsComponent implements OnInit {
  @Input() onValueChanged: any;
  @Input() docList: any[];
  @Input() fileAttachments: IFileAttachmentResponse[];
  @Input() get filesDataSource() {
    return this._fileDataSource;
  }
  @Input() isQrInDocument = false;
  public errorMessage = '';
  public subscriptions: Subscription[] = [];
  set filesDataSource(val) {
    this._fileDataSource = val;
  }
  private _fileDataSource;

  constructor(
    private avrDocumentService: AvrDocumentService,
    private _authService: AuthService,
    private mediator: DocumentMediator,
    private avrDocumentHttpService: AvrDocumentHttpService,
    private activatedRoute: ActivatedRoute,

  ) {

  }

  ngOnInit() {
  }
  // TODO KIRILL combine function with other-document-create.component.ts:98
  clearAllAttachments() {
    const result = this.avrDocumentHttpService.removeAllAttachments(this.fileAttachments).subscribe(resp => {
      if (resp.data) {
        this.fileAttachments.splice(0, this.fileAttachments.length);
        this.docList.splice(0, this.docList.length); // [];
      } else {
        this.errorMessage = resp.error.text;
      }
    });
    this.subscriptions.push(result);
  }

  onInitializedEventHandler(e) {
    e.component.instance()._validateMaxFileSize = function (file) {
      const fileSize = file.value.size;
      const maxFileSize = this.option('maxFileSize');
      const maxFileCount = 10;
      if (this._files.length >= maxFileCount) {
        return false;
      }
      return maxFileSize > 0 ? fileSize <= maxFileSize : true;
    };
  }


  onUploadedEventHandler(e) {
    const fileAttachmentResult: ApiResult<IFileAttachmentResponse> = <ApiResult<IFileAttachmentResponse>>(
      JSON.parse(e.request.response)
    );
    if (fileAttachmentResult.data) {
      this.errorMessage = '';
      const fileAttachment: IFileAttachmentResponse = fileAttachmentResult.data;
      this.fileAttachments.push(fileAttachment);
    } else {
      this.errorMessage = fileAttachmentResult.error.text;
    }
    const element = e.element.getElementsByClassName('dx-fileuploader-files-container');

    for (let index = 0; index < element.length; index++) {
      const item = element[index];
      const elBtn = item.getElementsByClassName('dx-fileuploader-button-container');
      const elName = item.getElementsByClassName('dx-fileuploader-file-name');
      for (let i = 0; i < elName.length; i++) {
        const iElement = elName[i];

        if (e.file.name == iElement.textContent) {
          elBtn[i].addEventListener('click', this.deleteAttachment.bind(this, e.file.name));
        }
      }
    }

    for (let index = 0; index < element.length; index++) {
      const item = element[index];
      const btn = item.getElementsByClassName('dx-state-invisible');

      setTimeout(() => {
        if (item.innerHTML.indexOf('dx-state-invisible') > 0) {
          btn[0].classList.remove('dx-state-invisible');
        }
      }, 500);
    }
  }
  deleteAttachment(name) {
    const oneAttachedFile = this.fileAttachments.filter(f => f.fileName == name);

    const result = this.avrDocumentHttpService.removeAllAttachments(oneAttachedFile).subscribe(resp => {
      if (resp.data) {
        const index = this.docList.map(m => m.name).indexOf(name);
        if (index > -1) {
          this.docList.splice(index, 1);
        }
        const attachmentsToDelete = this.fileAttachments.filter(m => m.fileName === name);
        attachmentsToDelete.forEach(element => {
          const indexOfElement = this.fileAttachments.indexOf(element);
          this.fileAttachments.splice(indexOfElement, 1);
        });
      } else {
        this.errorMessage = resp.error.text;
      }
    });
    this.subscriptions.push(result);
  }
  // TODO KIRILL : think about it;
  onUploadErrorEventHandler(e) {
    if (e.request.status === 401) {
      this._authService.logout();
    }
  }
  getAvailableDocType(): Array<string> {
    if (!this.isQrInDocument) {
      return new Array<string>('.jpg', '.jpeg', '.png', '.img', '.bmp', '.docx', '.doc', '.xlsx', '.xls', '.pptx', '.ppt', '.txt', '.pdf', '.rar', '.zip');
    } else {
      return new Array<string>('.docx', '.pdf');
    }
  }
  getAvailableDocMimeType() {
    if (this.isQrInDocument) {
      return "application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    } else {
      return "image/jpeg,image/jpg,image/png,image/img,image/bmp,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain, application/x-rar-compressed,application/zip,.rar";
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
    this.avrDocumentHttpService.removeAllAttachments(this.fileAttachments).subscribe(resp => {
      if (resp.data) {
        this.fileAttachments = this.fileAttachments.splice(0, this.fileAttachments.length);
      } else {
        this.errorMessage = resp.error.text;
      }
    });
  }
}
