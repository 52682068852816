import { SignerModel } from './signer.model';

export class DocumentCard {
  documentType: number;
  senderFullName: string;
  summary: string;
  internalNumber: string;
  registrationDate: Date;
  systemRegistrationDate: string;
  recipientUniqueId: string;
  recipientFullName: string;
  canBeSigned: boolean;
  canBeEdited: boolean;
  canBeRejected: boolean;
  canBeRetired: boolean;
  reasonForRejection: string;
  isAccessibleWithoutRegistration: boolean;
  status: string;
  documentWithQr: boolean;
  senderContractedName: string;
  signers: SignerModel[];
  hasSignersOrder: boolean;
}
