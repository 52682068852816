import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}

const providers = [{ provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }];
export const initLogger = () => {
    const logger = console as ExtendedConsole;
    logger.logAction = (title: string, data: string) => {
        console.groupCollapsed(`[${title}]`);
        console.log(data, 'color: DodgerBlue');
        console.groupEnd();
    };

};
if (environment.production) {
    enableProdMode();
} else {
    initLogger();
}

platformBrowserDynamic(providers)
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));

type ExtendedConsole = Console & {
    logAction: (actionType: string, message: string) => void;
};
