import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'validator-info',
    templateUrl: './validator-info.component.html',
})
export class ValidatorInfoComponent implements OnInit {
    @Input() model: FormControl;
    @Input() patternErrorKey: string;
    @Input() requredErrorKey: string;

    constructor() {}

    ngOnInit() {}
}
