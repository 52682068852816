import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControl][appPhoneMask], [formControlName][appPhoneMask]',
})
export class PhoneMaskDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event, backspacePressed) {
    event = event || '';
    const newVal = PhoneMaskHelper.process(event, backspacePressed);
    if (event == '+7 ') {
      this.ngControl.control.setValue('');
    }
    if (event && event.length > newVal.length) {
      this.ngControl.control.setValue(newVal);
    } else {
      this.ngControl.valueAccessor.writeValue(newVal);
      if (this.ngControl.control.value && this.ngControl.control.value != newVal) {
        this.ngControl.control.setValue(newVal);
      }
    }
  }
}
export class PhoneMaskHelper {
  public static process(newVal, backspace) {
    newVal = newVal.replace(/(\+7)|(\+7 )|(\D)/g, '');
    if (backspace && newVal.length <= 6) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
    if (newVal.length === 0) {
      newVal = '+7 (';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '+7 ($1)');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '+7 ($1) $2');
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '+7 ($1) $2-$3');
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '+7 ($1) $2-$3');
    }
    return newVal;
  }
}
