import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DsStorageType } from '../../../core/models/ds-storage-type';
import { NcalayerService } from '../../../core/services/ncalayer.service';

@Component({
    selector: 'app-ds-storage-type-select',
    templateUrl: './ds-storage-type-select.component.html',
    styleUrls: ['./ds-storage-type-select.component.scss'],
})
export class DsStorageTypeSelectComponent implements OnInit {
    // коллекция типов хранилищ эцп
    storageTypes: DsStorageType[] = [];
    // объект формы
    form: FormGroup;

    constructor(
        private dialogRef: MatDialogRef<DsStorageTypeSelectComponent>,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.buildForm();
    }

    // инициализация компонента
    ngOnInit() {
        // получаем коллекцию типов хранилищ эцп
        this.storageTypes = this.data.storageTypes;
    }

    // обработчик события кнопки типа хранилища эцп
    onTypeClick(ncaLayerStorageName: string) {
        // закрываем форму с результатом - выбранным типом хранилища
        this.dialogRef.close(ncaLayerStorageName);
    }
    // создание объекта formgroup
    private buildForm() {
        this.form = this.formBuilder.group({
            type: ['', Validators.required],
        });
    }
}
