import CustomStore from 'devextreme/data/custom_store';
import { createStore, Options } from 'devextreme-aspnet-data-nojquery';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpParams, HttpClientModule, HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class AuthorizedStoreCreator {
    constructor(private auth: AuthService, private router: Router, private httpClient: HttpClient) {}

    createStore(options: Options): CustomStore {
        const oldOnBeforeSend = options.onBeforeSend;
        const oldOnAjaxError = options.onAjaxError;
        const _router = this.router;
        options.onAjaxError = e => {
            if (oldOnAjaxError) {
                oldOnAjaxError(e);
            }
            if (e.xhr.status === 401) {
                this.auth.logout();
                _router.navigate(['/auth']);
            }
        };
        options.onBeforeSend = (x, settings) => {
            const token = this.auth.getToken();
            if (token == null) {
                this.auth.logout();
                _router.navigate(['/auth']);
                return;
            }
            if (oldOnBeforeSend) {
                oldOnBeforeSend(x, settings);
            }
            settings.headers = {
                'Authorization': 'Bearer ' + token,
                'PersonUniqueId': this.auth.currentUserValue.profile.uniqueId,
            };
        };
        return createStore(options);
    }

    createStoreForAnonymous(options: Options): CustomStore {
        const oldOnBeforeSend = options.onBeforeSend;
        const oldOnAjaxError = options.onAjaxError;
        const _router = this.router;
        options.onAjaxError = e => {
            if (oldOnAjaxError) {
                oldOnAjaxError(e);
            }
        };
        options.onBeforeSend = (x, settings) => {
            const token = this.auth.getToken();
            if (oldOnBeforeSend) {
                oldOnBeforeSend(x, settings);
            }
        };
        return createStore(options);
    }

    // GET ONLY! I havent tested implementation for all crud operations;
    configureGETFunctionForDataSource(idKey: string, url: string) {
        return {
            key: idKey,
            load: loadOptions => {
                let params: HttpParams = new HttpParams();
                [
                    'skip',
                    'take',
                    'requireTotalCount',
                    'requireGroupCount',
                    'sort',
                    'filter',
                    'totalSummary',
                    'group',
                    'groupSummary',
                ].forEach(function(i) {
                    if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                        params = params.set(i, JSON.stringify(loadOptions[i]));
                    }
                });

                return new Promise((resolve, reject) => {
                    this.httpClient.get(url, { params: params }).subscribe((result: any) => {
                        resolve(result.data);
                        return;
                    });
                });
            },
        };
    }
}
function isNotEmpty(value: any): boolean {
    return value !== undefined && value !== null && value !== '';
}
