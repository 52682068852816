import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AuthorizedStoreCreator } from '../../../../core/helpers/authorized-store.creator';
import { AuthService } from '../../../../core/services/auth.service';
import { ChangesService } from '../../../../core/services/changes.service';
import { Subscription, of } from 'rxjs';
import { AbstractControl, FormBuilder, FormGroup, NgModel } from '@angular/forms';
import { OtherDocumentService } from '../../../../core/services/otherdocument.service';
import { DocumentCard } from '../../../../core/models/document-card.model';
import { ActivatedRoute } from '@angular/router';
import { ISaveSupport } from '../../../isavesupport';
import { MatDialog } from '@angular/material';
import { DialogInviteComponent } from '../../../../core/shared-component/dialogs/dialog-invite/dialog-invite.component';
import { AvrDocumentService } from '../../../../core/services/avr-document.service';
import { DocumentCardState } from '../../../state/DocumentCardState';
import { DocumentMediator } from '../../../services/document-mediator.service';
import { ContractionsHelper } from '../../../../core/directives/organization-contractions.directive';
import { ModalDialogService } from '../../../../shared/services/modal-dialog.service';
import { InviteService, NotificationService } from '../../../../core/services';
import { switchMap } from 'rxjs/operators';
import { InviteRequest } from '../../../../core/models/invite-request.model';
import { MediatorRequest } from '../../../../core/mediator/IMediatorRequest';

@Component({
    selector: 'document-card-create',
    templateUrl: './document-card-create.component.html',
    styleUrls: ['./document-card-create.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentCardCreateComponent implements OnInit, OnDestroy, ISaveSupport {
    public documentCardForm: FormGroup;
    public errorMessage = '';
    public successMessage = '';
    public senderFullName = '';
    public dataSource: DataSource;
    public recipientDS: DataSource;
    public documentCard: DocumentCard = new DocumentCard();
    public today = '';
    public isInvitedUser = false;
    documentHash: string;
    @ViewChild('senderFullNameControl') senderFullNameControl: NgModel;
    private subscriptions: Subscription[] = [];
    private state: DocumentCardState;
    public documentType: string;
    // old
    constructor(
        private formBuilder: FormBuilder,
        private store: AuthorizedStoreCreator,
        private auth: AuthService,
        private _avrDocumentService: AvrDocumentService,
        private _changesService: ChangesService,
        private _otherDocumentService: OtherDocumentService,
        private activatedRoute: ActivatedRoute,
        private mediator: DocumentMediator,
        private dialogService: ModalDialogService,
        private inviteService: InviteService,
        private notificationService: NotificationService,
        private changeDetector: ChangeDetectorRef
        // public dialog: MatDialog,
    ) {
        this.today = new Date().toISOString().substring(0, 10);
        this.senderFullName = this.auth.getProfile().contragentFullName;
        this.mediator.subscribe('setDocumentType', (docType) => {
            this.documentType = docType;
            this.changeDetector.detectChanges();
        });
    }
    ngOnInit(): void {
        this.state = new DocumentCardState(this.formBuilder, this.mediator);
        this.documentCardForm = this.state.documentCardForm;
        this.recipientDS = new DataSource(
            this.store.createStore({
                key: 'uniqueId',
                loadUrl: '/api/profile/recipients',
            }),
        );
        const sub = this.activatedRoute.params.subscribe(x => {
            if (x.uniqueCode != null) {
                this.loadCardByDocumentInfo(x.uniqueCode);
            }
        });
        this.subscriptions.push(sub);
        this.mediator.handle('getQrState', () => { this.getQrState() });
        this.documentCardForm.valueChanges.subscribe(() => {
            if (this.f.recipientUniqueId.errors == null && this.f.internalNumber.errors == null) {
                this._changesService.changed = true;
            } else {
                this._changesService.changed = false;
            }
        });
    }
    getQrState() {
        this.mediator.publish(new MediatorRequest("changeDocWithQr", this.state.documentCardForm.controls["documentWithQr"].value));
    }
    get f() {
        return this.documentCardForm.controls;
    }

  changeDocWithQr(event) {
    const isQrCodeApplicableToAttachments = this.mediator.send(new MediatorRequest('tryChangeQrAndGetValue', event.target.checked));
    this.state.documentCardForm.controls["documentWithQr"].setValue(isQrCodeApplicableToAttachments);
  }
    getError(control: AbstractControl, error: string) {
        if (control.touched && control.errors && error in control.errors) {
            return true;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.mediator.unHandle('getQrState');
        this.state.finalizeState();
    }
    onRecipientChanged(e) {
        if (!e.value) {
            this.mediator.publish({ type: 'disableContractorFields', message: null });
        } else {
            this.mediator.publish({ type: 'setId', message: e.value });
        }
    }
    public openDialog(): void {
        this.isInvitedUser = false;
        const modal = this.dialogService.show(DialogInviteComponent, { centered: true, windowClass: 'modal-630' });
        modal[1].getResult()
            .pipe(switchMap((r: InviteRequest) => {
                if (r) {
                    return this.inviteService.sendInvitation(r);
                } else {
                    modal[0].close();
                    return of(null);
                }
            }))
            .subscribe(resp => {
                if (!resp) {
                    return;
                }
                if (resp.error != null) {
                    this.notificationService.showSnackNotification(resp.error.text);
                    return;
                }
                const param = {
                    'fullName': resp.data.fullName,
                    'uniqueId': resp.data.uniqueId
                };
                modal[0].close();
                this.recipientDS.reload();
                this.isInvitedUser = true;
                this.documentCardForm.get('recipientUniqueId').setValue(param.uniqueId);
                this.notificationService.showSnackNotification('Приглашение было отправлено.');
            });
    }

    setContractionsForOrganizationName(value) {
        if (value) {
            const { fullName } = value;
            const contraction = ContractionsHelper.transformContraction(fullName);
            if (contraction) {
                return contraction;
            }
            return fullName;
        }
    }
    public loadCardByDocumentInfo(uniqueCode: string): void {
        const sub = this._otherDocumentService.loadCardInfo(uniqueCode).subscribe(resp => {
            if (resp.data) {
                this.documentCardForm.setValue({
                    recipientUniqueId: resp.data.recipientUniqueId,
                    internalNumber: resp.data.internalNumber,
                    registrationDate: resp.data.registrationDate,
                    summary: resp.data.summary,
                });
            } else {
                this.errorMessage = resp.error.text;
            }
        });

        this.subscriptions.push(sub);
    }
}
