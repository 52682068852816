export class StatusDocument {
    data = {
        'ru': [
            {
                'id': 0,
                'name': 'в черновике',
            },
            {
                'id': 1,
                'name': 'на подписании',
            },
            {
                'id': 2,
                'name': 'подписан двумя сторонами',
            },
            {
                'id': 3,
                'name': 'отклонен получателем',
            },
            {
                'id': 4,
                'name': 'отозван отправителем',
            },
            {
                'id': 5,
                'name': 'отправлен'
            },
            {
                'id': 6,
                'name': 'просмотрен'
            }
        ],
        'en': [
            {
                'id': 0,
                'name': 'in draft',
            },
            {
                'id': 1,
                'name': 'on signing',
            },
            {
                'id': 2,
                'name': 'signed by two  parties',
            },
            {
                'id': 3,
                'name': 'rejected by the recipient ',
            },
            {
                'id': 4,
                'name': 'recalled by the sender',
            },
            {
              'id': 5,
              'name': 'sent'
            },
            {
              'id': 6,
              'name': 'viewed'
            }
        ],
    };
}
