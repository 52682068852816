import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { custom } from 'devextreme/ui/dialog';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../models/apiresult.model';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(
        public snackBar: MatSnackBar,
        private auth: AuthService,
        private zone: NgZone,
        private router: Router,
        private http: HttpClient,
    ) {}

    showNotification(message: string, callback?: () => void) {
        alert(message);
        if (callback != null) {
            callback();
        }
    }

    showSnackNotification(
        message: string,
        closeText = 'Закрыть',
        duration = 5000,

        callback?: () => void,
    ) {
        const config = new MatSnackBarConfig();
        config.verticalPosition = 'top';
        this.snackBar.open(message, closeText, config);
        if (callback != null) {
            callback();
        }
    }
    customDialog(message: string) {
        const customDialog = custom({
            title: '',
            message: '<p style=\'text-align: center;\'>' + message + '</p>',
            buttons: [{ text: 'Ок' }],
        });

        customDialog
            .show()
            .done(dialogResult => {
                this.auth.redirectTo(this.router.url);
            })
            .fail(x => {
                this.auth.redirectTo(this.router.url);
            });
    }
    getNotification() {
        return this.http.get<ApiResult<any>>('/api/notification');
    }
    clearNotifications() {
        return this.http.post<ApiResult<boolean>>('api/notification', {});
    }
}
