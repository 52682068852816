import { FormGroup, AbstractControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'contract-info',
    templateUrl: './contract-info.component.html',
    styleUrls: ['./contract-info.component.scss'],
})
export class ContractInfoComponent implements OnInit {
    @Input() contractForm: FormGroup;
    constructor() {}

    get today() {
        return new Date().toISOString().substring(0, 10);
    }
    get isContractInputDisabled(): boolean | null {
        return this.contractForm.controls.isContractAbsent.value ? true : null;
    }
    ngOnInit() {
        this.contractForm.get('inputAppPages').disable();
    }
    getError(control: AbstractControl, error: string): boolean {
        return control.touched && control.errors && error in control.errors;
    }
}
