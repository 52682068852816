import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    CanLoad,
    Route,
    UrlSegment,
} from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    private authUrl = '/auth';
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url: string = state.url;
        return this.checkLogin(url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    canLoad(route: Route): boolean {
        const url = location.pathname;

        return this.checkLogin(url);
    }

    checkLogin(url: string): boolean {
        const currentUser = this.authService.currentUserValue;
        if (currentUser) {
            return true;
        }
        if (url == null || url == '/') {
            this.router.navigateByUrl(this.authUrl);
            return false;
        }
        this.router.navigate([this.authUrl], { queryParams: { returnUrl: url } });
        return false;
    }
    resolve() {
        if (this.authService.isLoggedIn()) {
            if (this.authService.currentUserValue.profile != null) {
                this.router.navigate(['/cabinet']);
                this.authService.setBreadcrumb('Исходящие', 'На подписании');
            } else {
                this.authService.logout();
            }
        }
    }
}
