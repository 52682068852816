import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AuthorizedStoreCreator } from '../../../../core/helpers/authorized-store.creator';
import { AuthService } from '../../../../core/services/auth.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OtherDocumentService } from '../../../../core/services/otherdocument.service';
import { ChangesService } from '../../../../core/services/changes.service';
import { DocumentCard } from '../../../../core/models/document-card.model';
import { ActivatedRoute } from '@angular/router';
import { ISaveSupport } from '../../../isavesupport';
import * as deepEqual from 'deep-equal';
import { DocumentHistory } from '../../../../core/models/document-history.model';
import { DocumentCardState } from '../../../state/DocumentCardState';
import { DocumentMediator } from '../../../services/document-mediator.service';
import { ContractionsHelper } from '../../../../core/directives/organization-contractions.directive';
import { MediatorRequest } from "../../../../core/mediator/IMediatorRequest";

@Component({
  selector: 'document-card-edit',
  templateUrl: './document-card-edit.component.html',
  styleUrls: ['./document-card-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentCardEditComponent implements OnInit, OnDestroy, ISaveSupport {
  state: DocumentCardState;
  public documentCardForm: FormGroup;
  public errorMessage = '';
  public successMessage = '';
  public uniqueCode = '';
  public senderName = '';
  public systemRegistrationDate = '';
  public dataSource: DataSource;
  public recipientDS: DataSource;
  public documentCard: DocumentCard = new DocumentCard();
  public documentHistoryItems: DocumentHistory[] = [];
  public today = '';
  public documentHash: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private store: AuthorizedStoreCreator,
    private auth: AuthService,
    private _otherDocumentService: OtherDocumentService,
    private activatedRoute: ActivatedRoute,
    private _changes: ChangesService,
    private mediator: DocumentMediator,
  ) {
    this.today = new Date().toISOString().substring(0, 10);
    this.senderName = this.auth.getProfile().fullName;
  }

  ngOnInit(): void {
    this.state = new DocumentCardState(this.formBuilder, this.mediator);
    this.documentCardForm = this.state.documentCardForm;
    this.recipientDS = new DataSource(
      this.store.createStore({
        key: 'uniqueId',
        loadUrl: '/api/profile/recipients',
      }),
    );

    const sub = this.activatedRoute.params.subscribe(x => {
      this.uniqueCode = x.uniqueCode;
      this.loadCardByDocumentInfo(this.uniqueCode);
    });

    this.subscriptions.push(sub);
    const subs = this.activatedRoute.params.subscribe(x => {
      this.loadHistoryByDocumentInfo(x.uniqueCode);
    });

    this.subscriptions.push(subs);
    this.documentCardForm.controls["recipientUniqueId"].valueChanges.subscribe(x => {
      if (!x) {
        this.mediator.publish({ type: 'disableContractorFields', message: null });
      } else {
        this.mediator.publish({ type: 'setId', message: x });
      }
    });
  }

  getQrState() {
    this.mediator.publish(new MediatorRequest("changeDocWithQr", this.state.documentCardForm.controls["documentWithQr"].value));
  }

  changeDocWithQr(event) {
    const isQrCodeApplicableToAttachments = this.mediator.send(new MediatorRequest('tryChangeQrAndGetValue', event.target.checked));
    this.state.documentCardForm.controls["documentWithQr"].setValue(isQrCodeApplicableToAttachments);
  }
  public loadHistoryByDocumentInfo(uniqueCode: string): void {
    const sub = this._otherDocumentService.loadHistoryInfo(uniqueCode).subscribe(resp => {
      if (resp.data) {
        this.documentHistoryItems = resp.data;
      } else {
      }
    });

    this.subscriptions.push(sub);
  }

  get f() {
    return this.documentCardForm.controls;
  }
  get documentType(): string {
    return this.state.documentType;
  }

  setContractionsForOrganizationName(value) {
    if (value) {
      const { fullName } = value;
      const contraction = ContractionsHelper.transformContraction(fullName);
      if (contraction) {
        return contraction;
      }
      return fullName;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
    this.state.finalizeState();
  }

  public loadCardByDocumentInfo(uniqueCode: string): void {
    const sub = this._otherDocumentService.loadCardInfo(uniqueCode).subscribe(resp => {
      if (resp.data) {
        this.systemRegistrationDate = resp.data.systemRegistrationDate;
        this.documentCardForm.patchValue({
          internalNumber: resp.data.internalNumber,
          registrationDate: resp.data.registrationDate.toString().substring(0, 10),
          summary: resp.data.summary,
          documentWithQr: resp.data.documentWithQr,
          baseDoc: '',
        });
        //emit event - false:  because we don't want to update already saved recipient data when initialized
        this.documentCardForm.controls["recipientUniqueId"].patchValue(resp.data.recipientUniqueId, { emitEvent: false });
        this.senderName = resp.data.senderContractedName ? resp.data.senderContractedName : resp.data.senderFullName;
        const initialForm = this.documentCardForm.value;
        this.mediator.publish(new MediatorRequest("changeDocWithQr", resp.data.documentWithQr));
        this.documentCardForm.valueChanges.subscribe(value => {
          if (!deepEqual(this.documentCardForm.value, initialForm)) {
            this._changes.changed = true;
          } else {
            this._changes.changed = false;
          }
        });
      } else {
        this.errorMessage = resp.error.text;
      }
    });
    this.subscriptions.push(sub);

  }
}
