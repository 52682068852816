import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { OnInit, Component } from '@angular/core';

@Component({
    selector: 'app-not-found',
    template: `
    <h2>
      404 - Страница не найдена
    </h2>
    <p *ngIf="path">
      You might want to go to the <a [routerLink]="path">"{{ path }}" page</a>
    </p>
  `,
})
export class NotFoundComponent implements OnInit {
    path: string;

    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.data.pipe(take(1)).subscribe((data: { path: string }) => {
            this.path = data.path;
        });
    }
}
