import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import {
    ApiResultInterceptor,
    PersonUniqueIdInterceptor,
    LoaderInterceptor,
    TokenInterceptor,
} from './core/interceptors';
import { AppComponent } from './app.component';
import { UserComponentModule } from './core/shared-component/user-component/user.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DsStorageTypeSelectComponent } from './auth/registration-components/ds-storage-type-select/ds-storage-type-select.component';
import { RegistrationComponentsModule } from './auth/registration-components/registration-components.module';
import { DialogComponentModule } from './core/shared-component/dialogs/dialog.module';
import { NotFoundComponent } from './404/NotFoundComponent';
import { AvrDetailComponent } from './documents/document-types/avr-document/create-avr-detail-dialogue/avr-detail.component';
import { SharedModule } from './shared/shared.module';
import { DirectivesModule } from './core/directives/directives.module';
import { ConvertToTSCComponent } from './cabinet/settings/convert-to-tsc/convert-to-tsc.component';
import { EditTscDataComponent } from './support/edit-tsc-data/edit-tsc-data.component';
import { PasswordGuardDialogComponent } from './shared/password-guard-dialog/password-guard-dialog/password-guard-dialog.component';
import { AvrDocumentHttpService } from './core/http.services/avr-document-http.service';
import { LayoutModule } from './layout/layout.module';
import { DialogInviteComponent } from './core/shared-component/dialogs/dialog-invite/dialog-invite.component';
import { DocumentsModule } from './documents/documents.module';
import { OtherDocumentCreateComponent } from './documents/document-types/other-document/create/other-document-create.component';
import { OtherDocumentEditComponent } from './documents/document-types/other-document/edit/other-document-edit.component';
import { OtherDocumentViewComponent } from './documents/document-types/other-document/view/other-document-view.component';
import { AvrDocumentCreateComponent } from './documents/document-types/avr-document/create/avr-document-create.component';
import { AvrDocumentEditComponent } from './documents/document-types/avr-document/edit/avr-document-edit.component';
import { AvrDocumentViewComponent } from './documents/document-types/avr-document/view/avr-document-view.component';
@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent,
        AvrDetailComponent,
        ConvertToTSCComponent,
        EditTscDataComponent,
        PasswordGuardDialogComponent,
        DialogInviteComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UserComponentModule,
        DialogComponentModule,
        NgxSpinnerModule,
        RegistrationComponentsModule,
        NgbModule,
        SharedModule,
        DirectivesModule,
        LayoutModule,
        DocumentsModule
    ],

    providers: [
        HttpClient,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: PersonUniqueIdInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ApiResultInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        CookieService,
        AvrDocumentHttpService
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        DsStorageTypeSelectComponent,
        AvrDetailComponent,
        ConvertToTSCComponent,
        EditTscDataComponent,
        PasswordGuardDialogComponent,
        DialogInviteComponent,
        OtherDocumentCreateComponent,
        OtherDocumentEditComponent,
        OtherDocumentViewComponent,
        AvrDocumentCreateComponent,
        AvrDocumentEditComponent,
        AvrDocumentViewComponent,
    ],
})
export class AppModule {
    constructor(router: Router) { }
}
