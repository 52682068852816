export class ExtendPacketRequest {
    public clientUniqueId: string;
    public count: number;
    public invoice: number;
}
class PacketChangeBaseModel {
    public personUniqueId: string;
    public uniqueId: string;
    public code: string;
    public email: string;
}

export class RevokePacketRequest extends PacketChangeBaseModel {
    /**
   *
   */
    constructor(object: RevokePacketRequest) {
        super();
        Object.assign(this, object);
    }
}
export class ChangePacketTypeRequest {
    public updateOnCode: string;
    public clientEmail: string;
    public packetUniqueId: string;
    public invoice: string;
    public currentPacketCode: string;
    /**
   *
   */
    constructor(object: ChangePacketTypeRequest) {
        Object.assign(this, object);
    }
}

export enum GlobalPacketTypeEnum {
    None = 0,
    Common = 1,
    Monthly = 2,
}
