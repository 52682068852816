import { Component, Injector } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AuthorizedStoreCreator } from '../../../../../core/helpers/authorized-store.creator';
import { AuthService } from '../../../../../core/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MainViewerBaseComponent } from '../../base/main-viewer-base.component';
import { DocumentProcessingService } from '../../../../../core/services/document-processing.service';
import { ContractionsHelper } from '../../../../../core/directives/organization-contractions.directive';
import { DocumentPackageService } from '../../../../../documents/services/document-package.service';

@Component({
  selector: 'main-viewer-retired',
  templateUrl: './main-viewer-retired.component.html',
  styleUrls: ['./main-viewer-retired.component.scss'],
})
export class MainViewerOutgoingRetiredComponent extends MainViewerBaseComponent {
  constructor(injector: Injector) {
    super(injector);
  }

  public Load() {
    this.dataSource = new DataSource(
      this.store.createStore({
        key: 'uniqueCode',
        loadUrl: '/api/document?documentGroupType=Outgoing&documentStatus=Retired',
      }),
    );
  }
  public setContractionForContragent = (val) => ContractionsHelper.transformContraction(val.recipientContragentName);
}
