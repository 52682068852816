import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ISaveSupport } from '../../isavesupport';
import { AuthService } from '../../../../app/core/services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class DocumentBaseComponent implements OnDestroy, ISaveSupport {
    public subscriptions: Subscription[] = [];

    public errorMessage = '';
    public successMessage = '';
    headers: any;
    public documentHash: string;
    constructor(public authService: AuthService) {
        this.headers = {
            'Authorization': `Bearer ${this.authService.getToken()}`,
            'PersonUniqueId': this.authService.currentUserValue.profile.uniqueId,
        };
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
    }
}
