import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ApiResult } from '../models/apiresult.model';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    public src: any;
    public sarc: any;

    public avatarTimeStamp: string = Date.now().toString();
    constructor(private http: HttpClient, private auth: AuthService, private router: Router) {
        this.src = '/api/profile/getavatar?uniqueId=';

    }
    addProfile(param: any) {
        return this.http.post<ApiResult<boolean>>('/api/profile/addprofile', param).pipe(
            map(resp => resp),
        );
    }
    getAvatar() {
        if (this.sarc) {
            return this.sarc;
        }
        return this.src + this.auth.currentUserValue.profile.uniqueId;
    }
    changeAvatarTimeStamp() {
        this.avatarTimeStamp = Date.now().toString();
    }

    removeProfile(uniqueId: string) {
        return this.http.delete<ApiResult<string>>(`/api/profile?uniqueId=${uniqueId}`, {}).pipe(
            map(response => {
                if (response.data) {
                    this.auth.loadProfile().subscribe(x => {
                        this.auth.swithProfile(response.data);
                        this.auth.redirectTo(this.router.url);
                    });
                }
                return response;
            }),
        );
    }
    saveProfileChange(fileToUpload: File, params: any) {
        const formData: FormData = new FormData();
        formData.append('avatar', fileToUpload);
        formData.append('phone', params.phone);
        formData.append('city', params.city);
        formData.append('address', params.address);
        formData.append('uniqueId', params.uniqueId);
        const HttpUploadOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
        };
        return this.http.post<ApiResult<boolean>>('/api/profile/change', formData).pipe(
            map(res => res),
        );
    }

}
