import { Validators } from '@angular/forms';
import { IMediator } from './../../core/mediator/IMediator';
import { Subject } from 'rxjs';
import { IDocumentStateBase } from './iDocumentStateBase';
import { FormGroup, FormBuilder } from '@angular/forms';
import { untilToday, minDate } from '../../../app/core/validators';
import { DocumentCard } from '../../core/models/document-card.model';
import { DocumentProcessingService } from '../../../app/core/services/document-processing.service';

declare type documentType = 'avr' | 'other';
export class DocumentCardState implements IDocumentStateBase<any> {
    hasAnyChanges: boolean;
    today: string;
    onStateChanged: Subject<any> = new Subject();
    public documentType: documentType;
    public documentCardForm: FormGroup;
    private minDateForRegistration = new Date(2007, 0, 7);
    /**
   *
   */
    constructor(
        private formBuilder: FormBuilder,
         private mediator: IMediator

         ) {
        this.today = new Date().toISOString().substring(0, 10);
        this.documentCardForm = this.formBuilder.group({
            recipientUniqueId: [null, Validators.required],
          isAccessibleWithoutRegistration: [false, Validators.required],
          documentWithQr: [false, Validators.required],
            internalNumber: ['', Validators.compose([Validators.required, Validators.maxLength(32)])],
            registrationDate: [
                this.today,
                Validators.compose([
                    Validators.required,
                    untilToday(),
                    minDate(this.minDateForRegistration, 'Дата регистрации документа не может быть ранее 07.01.2007'),
                ]),
            ],
            summary: ['', Validators.maxLength(256)],
            baseDoc: [''],
        });
        this.mediator.subscribe('setDocumentType', (docType: documentType) => {
            this.documentType = docType;
        });
        this.mediator.handle('getCardData', () => this.getCardData());
        this.mediator.handle('validateDocumentCard', () => this.validate());

    }
    getRawValues() {
        return this.documentCardForm.getRawValue();
    }

    finalizeState(): void {
        this.mediator.unsubscribe('setDocumentType');
        this.mediator.unHandle('validateDocumentCard');
        this.mediator.unHandle('getCardData');
        // / all unsubscribes here
    }
  private getCardData() {
        const formObj = this.documentCardForm.getRawValue();
        const serializedForm = JSON.stringify(formObj);
        const documentCardData: DocumentCard = <DocumentCard>JSON.parse(serializedForm);
        return documentCardData;
    }
    private validate(): boolean {
        return !this.documentCardForm.invalid;
    }
}
