import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../models/apiresult.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs';
@Injectable()
export class AvrDocumentHttpService  {
    constructor(private http: HttpClient) {
    }
    getAvrDocument(uniqueId: string) {
        return this.http.get(`/api/document/getAvrDocument?documentUniqueId=${uniqueId}`);
    }
    getRecipientById(recipientUniqueId: String): any {
        return this.http.get<ApiResult<any>>(`/api/profile/getRecipientById?recipientUniqueId=${recipientUniqueId}`);
    }
    getAvrDocuments(): Observable<any> {
        return this.http.get<ApiResult<any[]>>('/api/document/GetAvrBaseDocs');
    }
    removeAllAttachments(attachments: IFileAttachmentResponse[]) {
        return this.http.post<ApiResult<boolean>>('/api/fileattachment/removeattachments', attachments).pipe(
            map(resp => resp),
        );
    }
}
