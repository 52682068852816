import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function equalControl(ctrl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const equal = control.value === ctrl.value;

        if (!equal) {
            return {
                equalControl: {
                    actual: control.value,
                    required: ctrl.value,
                },
            };
        }
    };
}
