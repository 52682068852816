export class NcalayerErrorMessageMapping {
    originalMessage: string;
    localizedMessage: string;

    public constructor(fields?: { originalMessage?: string; localizedMessage?: string }) {
        if (fields) {
            Object.assign(this, fields);
        }
    }
}
