import { Component } from '@angular/core';
import { Router, RouterEvent, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './core/services/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent {
    title = 'SmartContract';
    loading = false;
    constructor(private spinner: NgxSpinnerService, private router: Router, private auth: AuthService) {
        this.router.events.subscribe((event: RouterEvent) => {
            switch (true) {
                case event instanceof RouteConfigLoadStart: {
                    this.spinner.show();
                    break;
                }
                case event instanceof RouteConfigLoadEnd: {
                    this.spinner.hide();
                    break;
                }
                default: {
                    break;
                }
            }
        });
        this.auth.triggerRefreshToken();
    }
}
