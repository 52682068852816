import { ModalDialogBase } from '../../../shared/services/modal-dialog.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'convert-to-tsc',
    templateUrl: './convert-to-tsc.component.html',
    styleUrls: ['./convert-to-tsc.component.scss'],
})
export class ConvertToTSCComponent extends ModalDialogBase<boolean> implements OnInit {
    public organizationName: string;
    public xin: string;
    public type: string;
    constructor() {
        super();
    }

    ngOnInit() {}
    cancelClick() {
        this.returnResult(null);
    }
    submit() {
        this.returnResult(true);
    }
}
