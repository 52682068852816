import { PermissionsEnum } from './../models/PermissionsEnum';
import { Directive, OnInit, Input, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { AuthService } from '../services';

@Directive({
    selector: '[hasAccess]',
})
export class CanAccessDirective implements OnInit {
    @Input('hasAccess') hasAccess: PermissionsEnum | PermissionsEnum[] | HasAccessModel;
    private permissions: PermissionsEnum[];

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.applyPermission();
    }

    private applyPermission(): void {
        this.permissions = this.authService.getPermissionCurrentProfile();
        let authorized = false;
        if (Array.isArray(this.hasAccess)) {
            authorized = this.hasAccess.every(d => this.permissions.includes(d));
        } else if ((<HasAccessModel>this.hasAccess).accessMode) {
            const hasAccessModel = <HasAccessModel>this.hasAccess;
            if (hasAccessModel.accessMode == 'any') {
                authorized = hasAccessModel.permissions.some(d => this.permissions.includes(d));
            } else {
                authorized = hasAccessModel.permissions.every(d => this.permissions.includes(d));
            }
        } else {
            authorized = this.permissions.includes(this.hasAccess as PermissionsEnum);
        }
        if (authorized) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
declare interface HasAccessModel {
    permissions: PermissionsEnum[];
    accessMode: 'any' | 'all';
}
