import { Component, Output, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { User } from '../../core/models/user.model';
import { SettingsType } from '../../core/models/settings-type.model';
import 'devextreme-intl';
import { LocalizeService } from '../../core/services/localize.service';
import { ProfileService } from '../../core/services/profile-operations.service';
import { IProfile } from '../../core/models/profileresponse.model';
import { NotificationType } from '../../core/models/statuses';
import { PermissionsEnum } from '../../core/models/PermissionsEnum';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry, MatDialog, MatDialogConfig } from '@angular/material';
import { DocumentProcessingService } from '../../core/services';
import { SelectProfileComponent } from '../../core/shared-component/dialogs/select-profile/select-profile.component';
import { NotificationService } from '../../core/services/notification.service';
import { EmployeePositionEnum } from '../../core/models/EmployeePositionEnum';

@Component({
    selector: 'header-nav-menu',
    templateUrl: './header-menu.component.html',
    styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent {
    public notificationType = new NotificationType();
    public currentUser: User;
    public notifications: Array<any> = [];
    public currentProfile: IProfile;
    public locales: any;
    public src: any;
    public settingsTypes: SettingsType[] = [];
    public accessServiceCenter: boolean;
    public accessSupportCenter: boolean;
    public tabs: any[] = [];
    public breadcrumb: any;
    public accessProxy: boolean;

    private resolve: Function | null = null;
    constructor(
        private dialog: MatDialog,
        private profileService: ProfileService,
        private authService: AuthService,
        private router: Router,
        private localizeService: LocalizeService,
        private changeDetector: ChangeDetectorRef,
        private notification: NotificationService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        private baseDocumentService: DocumentProcessingService,
    ) {
        const usr = this.authService.currentUserValue;
        this.currentUser = usr;
        this.currentProfile = usr.profile;
        this.src = this.profileService.getAvatar();
        this.InitializerSettingsTypes();
        this.localizeService.initMessages();
        this.locales = localizeService.getLocales();
        const permissions = this.authService.getPermissionCurrentProfile();
        if (permissions != null) {
            this.accessServiceCenter = permissions.includes(PermissionsEnum.AccessCabinetServiceCenter);
            this.accessSupportCenter = permissions.includes(PermissionsEnum.AccessCabinetSupportCenter);
        }
        iconRegistry.addSvgIcon('bell', sanitizer.bypassSecurityTrustResourceUrl('assets/image/fontawesome/bell-ring.svg'));
        iconRegistry.addSvgIcon(
            'list',
            sanitizer.bypassSecurityTrustResourceUrl('assets/image/fontawesome/package-check.svg'),
        );
        iconRegistry.addSvgIcon(
            'history',
            sanitizer.bypassSecurityTrustResourceUrl('assets/image/fontawesome/history.svg'),
        );
        this.tabs = this.authService.getTabs();
        this.accessProxy = this.currentProfile.employeePosition == EmployeePositionEnum.FirstHead;
    }
    ngOnInit(): void {
        this.authService.getBreadcrumb().subscribe(data => {
            this.breadcrumb = data;
            if (!this.changeDetector['destroyed']) {
                this.changeDetector.detectChanges();
            }
        });
        this.notification.getNotification().subscribe(x => {
            if (x.error != null) {
                return;
            }
            x.data.forEach(y => {
                const obj = {
                    'notificationType': this.notificationType.data['ru'][y.notificationType].name,
                    'information': y.information,
                    'date': y.dateTime,
                };
                this.notifications.push(obj);
            });
        });
    }
    settingsSelect(event: any) {
        const url = event.target.value;
        this.router.navigate([url]);
        this.InitializerSettingsTypes();
    }
    logout() {
        this.authService.logout();
        this.router.navigate(['/auth']);
    }
    InitializerSettingsTypes() {
        this.settingsTypes = [
            new SettingsType('Настройка профилей', '/cabinet/settings-profile'),
            new SettingsType('Настройка уведомлений', '/cabinet/settings-notification'),
        ];
    }
    changeLocale(data) {
        this.localizeService.changeLocale(data);
    }
    changeCurrentProfile(uniqueId) {
        this.authService.swithProfile(uniqueId);
        this.authService.redirectTo(this.router.url);
    }
    closeTab(index: number) {
        if (index == 0) {
            if (this.tabs.length == 2) {
                this.tabs.splice(index, 1);
                this.ChangeValue(0);
            } else if (this.tabs.length == 1) {
                this.tabs.splice(index, 1);
                this.baseDocumentService.OpenDocument('/cabinet');
            } else {
                this.tabs.splice(index, 1);
                this.ChangeValue(index);
            }
        } else {
            this.tabs.splice(index, 1);
            this.authService.selectedTab = this.tabs.length - 1;
        }
    }
    ChangeValue(selected) {
    // todo: - 1 приходит по умолчанию
        if (selected != this.authService.selectedTab) {
            this.authService.selectedTab = selected;
        }
        let url = '/cabinet/view/';
        const key = this.tabs[selected].code;
        const docType = this.tabs[selected].docType;

        if (docType === 0) {
            url += 'other/' + key;
        } else {
            url += 'avr/' + key;
        }
        this.baseDocumentService.OpenDocument(url);
    }
    selectProfile() {
        let config = new MatDialogConfig();
      config = {
          autoFocus: false
        };
        this.dialog.open(SelectProfileComponent, config);
    }
    clearNotifications() {
        this.notification.clearNotifications().subscribe(x => {
            if (x.data) {
                this.notifications = [];
                return;
            }
        });
    }
  changeLanguage(i) {
    switch (i) {
      case 1:
        location.href = "https://en.smartcontract.kz/";
        break;
      case 2:
        location.href = "https://smartcontract.kz/";
        break;
    }
  }

}
