import { ModalDialogService } from './modal-dialog.service';
import { PasswordGuardDialogComponent } from '../password-guard-dialog/password-guard-dialog/password-guard-dialog.component';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable()
export class PasswordGuardService {
    /**
   *
   */
    constructor(private modalDialogService: ModalDialogService, private http: HttpClient) {}
    public confirmAction(): Observable<string> {
        const dialog = this.modalDialogService.show(PasswordGuardDialogComponent, {
            centered: true,
            windowClass: 'modal-400',
        });
        return dialog[1].getResult().pipe(
            tap(r => {
                dialog[0].close();
            }),
        ) as Observable<string>;
    }
}
export class PasswordModel<T> {
    password: string;
    data: T;
}
