/* eslint-disable */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { DsStorageTypeSelectComponent } from '../../auth/registration-components/ds-storage-type-select/ds-storage-type-select.component';
import { DsStorageType } from '../models/ds-storage-type';
import { NCAResponse } from '../models/nca-response.model';
import { NcalayerErrorMessageMapping } from '../models/ncalayer-error-message-mapping';
import { Subject } from '../models/subject.model';
import { SignEntity } from '../models/signEntity';

@Injectable({
    providedIn: 'root',
})
export class NcalayerService {
    // Константа - тип хранилища ЭЦП из файла
    public static readonly fileStorageName = 'PKCS12';
    // Константа - тип хранилища ЭЦП на УЛ
    public static readonly idCardStorageName = 'AKKZIDCardStore';
    private errorMessageMapping: NcalayerErrorMessageMapping[];

    private webSocket: WebSocket;
    private nextAction: Function;
    private defaults = {
        storageName: NcalayerService.fileStorageName,
        keyType: 'SIGNATURE',
        additionDataFlag: false,
        currentDirectory: '',
    };

    constructor(private dialog: MatDialog) {
        this.webSocket = new WebSocket('wss://127.0.0.1:13579/');
        this.webSocket.onopen = event => {
            if (this.nextAction) {
                this.nextAction();
            }
        };

        this.webSocket.onclose = event => {
            this.onWebSocketClose(event);
        };

        this.webSocket.onmessage = event => {
            this.onWebSocketMessage(event);
        };

        this.errorMessageMapping = this.getErrorMessageMapping();
    }

    private onWebSocketClose(event) {
        if (event.wasClean) {
            console.log('connection has been closed');
        } else {
            console.log('Connection error');

            const confirmMessage = 'Ошибка при подключении к NCALayer. ' + 'Запустите NCALayer и нажмите ОК';

            if (confirm(confirmMessage) === true) {
                location.reload();
            }
        }
        console.log('Code: ' + event.code + ' Reason: ' + event.reason);
    }

    private onWebSocketMessage(event) {
        const result: NCAResponse = JSON.parse(event.data);

        if (result == null) {
            return;
        }

        if (this.nextAction) {
            this.nextAction(result);
        }
    }

    clearNextAction() {
        this.nextAction = null;
    }
    getData2(ncaModule: string, method: string, params?:{}): Observable<NCAResponse> {
        const that = this;
        const object = {
            module: ncaModule,
            method:method,
            params:params,
        }
        const paramString = JSON.stringify(object);

        return new Observable(observer => {
            let subscription: Subscription;
            if (that.webSocket.readyState !== 1) {
                setTimeout(() => {
                    subscription = that.getData2(ncaModule, method, params).subscribe(
                        response => {
                            observer.next(response);
                        },
                        error => {
                            observer.error(error);
                        },
                    );
                }, 1000);
            } else {
                that.nextAction = (response: NCAResponse) => {
                    if(response.errorCode === "MODULE_NOT_FOUND"){
                        this.downloadModule();
                        return;
                    }
                    if (response.code === '500') {
                        observer.error(response);
                    }

                    if (response.code === '200') {
                        observer.next(response);
                        that.clearNextAction();
                    }
                };

                that.webSocket.send(paramString);
            }
            return {
                unsubscribe() {
                    if (subscription) {
                        subscription.unsubscribe();
                    }

                    that.clearNextAction();
                },
            };
        });
    }
    downloadModule(){
        const model = {
            module: "kz.gov.pki.ncalayerservices.accessory",
            method: "installBundle",
            symname: "kz.webkassa.osgi.SignerBundle"
        };
        this.webSocket.send(JSON.stringify(model));
    }
    getData(ncaModule: string, method: string, args?: Array<string | boolean | number>): Observable<NCAResponse> {
        const that = this;
        const params = { method, args };

        if (ncaModule) {
            Object.assign(params, {
                module: ncaModule,
            });
        }

        const paramString = JSON.stringify(params);

        return new Observable(observer => {
            let subscription: Subscription;
            if (that.webSocket.readyState !== 1) {
                setTimeout(() => {
                    subscription = that.getData(ncaModule, method, args).subscribe(
                        response => {
                            observer.next(response);
                        },
                        error => {
                            observer.error(error);
                        },
                    );
                }, 1000);
            } else {
                that.nextAction = (response: NCAResponse) => {
                    if (response.code === '500') {
                        observer.error(response);
                    }

                    if (response.code === '200') {
                        observer.next(response);
                        that.clearNextAction();
                    }
                };

                that.webSocket.send(paramString);
            }
            return {
                unsubscribe() {
                    if (subscription) {
                        subscription.unsubscribe();
                    }

                    that.clearNextAction();
                },
            };
        });
    }

    isValidityDate(response: NCAResponse): boolean {
        if (!response.responseObject) {
            return;
        }

        const { certNotBefore: start, certNotAfter: end } = response.responseObject;

        const curDate = new Date().valueOf();

        if (curDate > +start && curDate < +end) {
            return true;
        }

        response.message = 'Срок действия ключа истек или еще не наступил.';
    }

    getActiveTokens(): Observable<NCAResponse> {
        const ncaModule = 'kz.gov.pki.knca.commonUtils';
        const method = 'getActiveTokens';

        return this.getData(ncaModule, method);
    }

    getKeyInfo(): Observable<any> {
        return this.chooseStorage()
            .pipe(
                map(selectedStorageName => selectedStorageName),
            )
            .pipe(
                switchMap(storageName => {
                    const ncaModule = 'kz.gov.pki.knca.commonUtils';
                    const method = 'getKeyInfo';
                    const args = [storageName];

                    return this.getData(ncaModule, method, args).pipe(
                        mergeMap(response => {
                            const result = response.responseObject;
                            result.subjectDn = this.normalizeSubjectInfo(result.subjectDn);
                            if (!this.isValidityDate(response)) {
                                return throwError(response);
                            }
                            return of(result);
                        }),
                    );
                }),
            );
    }

    private normalizeSubjectInfo(sdn: string): Subject {
        const sdnRawAttr = sdn.split(',');
        const sdnAttr: any = {};
        const attrMap = {
            SERIALNUMBER: 'iin',
            SURNAME: 'lastName',
            CN: 'cn',
            OU: 'bin',
            E: 'email',
            G: 'middleName',
            O: 'orgName',
        };

        sdnRawAttr.forEach(it => {
            const [key, val] = it.split('=');
            if (key in attrMap) {
                sdnAttr[attrMap[key]] = val;
            }
        });

        sdnAttr.iin = sdnAttr.iin.substr(3);
        sdnAttr.userName = [sdnAttr.cn, sdnAttr.middleName].join(' ');

        if (sdnAttr.bin) {
            if (sdnAttr.bin.length > 3) {
                sdnAttr.bin = sdnAttr.bin.substr(3);
            }

            sdnAttr.orgName = sdnAttr.orgName.replace(/\\\"/g, '"');
        }

        return sdnAttr as Subject;
    }

    signXml(xmlToSign: string, keyType: string = this.defaults.keyType): Observable<NCAResponse> {
        return this.chooseStorage()
            .pipe(
                map(selectedStorageName => selectedStorageName),
            )
            .pipe(
                switchMap(storageName => {
                    const ncaModule = 'kz.gov.pki.knca.commonUtils';
                    const method = 'signXml';
                    const args = [storageName, keyType, xmlToSign, '', ''];

                    return this.getData(ncaModule, method, args);
                }),
            );
    }

    createCMSSignatureFromFile(filePath: string, flag?: boolean, keyType?: string): Observable<NCAResponse> {
        return this.chooseStorage()
            .pipe(
                map(selectedStorageName => selectedStorageName),
            )
            .pipe(
                switchMap(storageName => {
                    const ncaModule = 'kz.gov.pki.knca.commonUtils';
                    const method = 'createCMSSignatureFromFile';
                    const args = [storageName, keyType, flag, filePath];
                    return this.getData(ncaModule, method, args);
                }),
            );
    }
    createCMSSignatureFromData(
        dataToSign:string,
        flag: boolean = this.defaults.additionDataFlag,
        keyType: string = this.defaults.keyType,
    ): Observable<NCAResponse> {
        return this.chooseStorage()
            .pipe(
                map(selectedStorageName => selectedStorageName),
            )
            .pipe(
                switchMap(storageName => {
                    const ncaModule = 'kz.webkassa.signerbundle';
                    const method = 'createCMS';
                    const args = {
                        storageName:storageName,
                        ncaManifest:dataToSign,
                        cms:null,
                    }
                    return this.getData2(ncaModule, method, args);
                }),
            );
    }

    addSignatureToExistingOne(
        dataToSign:string,
        existingCms:string,
        flag: boolean = this.defaults.additionDataFlag,
        keyType: string = this.defaults.keyType,
    ): Observable<NCAResponse> {
        return this.chooseStorage()
            .pipe(
                map(selectedStorageName => selectedStorageName),
            )
            .pipe(
                switchMap(storageName => {
                    const ncaModule = 'kz.webkassa.signerbundle';
                    const method = 'addSignerToCMS';
                    const args = {
                        storageName:storageName,
                        ncaManifest:dataToSign,
                        cms:existingCms,
                    }
                    return this.getData2(ncaModule, method, args);
                }),
            );
    }
    createCMSSignatureFromBase64(
        base64ToSign: string,
        flag: boolean = this.defaults.additionDataFlag,
        keyType: string = this.defaults.keyType,
    ): Observable<NCAResponse> {
        return this.chooseStorage()
            .pipe(
                map(selectedStorageName => selectedStorageName),
            )
            .pipe(
                switchMap(storageName => {
                    const ncaModule = 'kz.gov.pki.knca.commonUtils';
                    const method = 'createCMSSignatureFromBase64';
                    const args = [storageName, keyType, base64ToSign, flag];
                    return this.getData(ncaModule, method, args);
                }),
            );
    }

    createCMSSignatureFromFileInput(
        base64ToSign: string,
        flag: boolean = this.defaults.additionDataFlag,
        keyType: string = this.defaults.keyType,
    ): Observable<NCAResponse> {
        const ncaModule = 'kz.gov.pki.knca.commonUtils';
        const method = 'createCMSSignatureFromBase64';
        const args = ['PKCS12', keyType, base64ToSign, flag];
        return this.getData(ncaModule, method, args);
    }

    createCMSSignatureFromIdentityInput(
        base64ToSign: string,
        flag: boolean = this.defaults.additionDataFlag,
        keyType: string = this.defaults.keyType,
    ): Observable<NCAResponse> {
        const ncaModule = 'kz.gov.pki.knca.commonUtils';
        const method = 'createCMSSignatureFromBase64';
        const args = ['AKKZIDCardStore', keyType, base64ToSign, flag];
        return this.getData(ncaModule, method, args);
    }

    showFileChooser(fileExtension = 'ALL', currentDirectory = ''): Observable<NCAResponse> {
        const ncaModule = 'kz.gov.pki.knca.commonUtils';
        const method = 'showFileChooser';
        const args = [fileExtension, currentDirectory];

        return this.getData(ncaModule, method, args);
    }

    changeLocale(language: string): void {
        const ncaModule = 'kz.gov.pki.knca.commonUtils';
        const method = 'changeLocale';
        const args = [language];

        this.getData(ncaModule, method, args).subscribe(() => {});
    }

    convertBase64ToArrayBuffer(base64) {
        if (!base64) {
            return;
        }

        const binary_string = window.atob(base64);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }

        return bytes.buffer as ArrayBuffer;
    }

    private getStorageTypes(): DsStorageType[] {
        return [
            new DsStorageType({
                displayName: 'Из файла',
                ncaLayerStorageName: NcalayerService.fileStorageName,
            }),
            new DsStorageType({
                displayName: 'Из удостоверения личности',
                ncaLayerStorageName: NcalayerService.idCardStorageName,
            }),
        ];
    }

    // Локализация сообщения об ошибке ncalayer
    getLocalizedErrorMessage(error: any) {
        if (!!error.message) {
            const thrownError = this.errorMessageMapping.find(e => e.originalMessage === error.message);
            if (!!throwError) {
                if (thrownError) {
                    return thrownError.localizedMessage;
                }
            }
            // return error.message;
            return 'Ошибка прослойки';
        }
        return '';
    }

    // Возвращает коллекцию переведенных сообщений об ошибках от ncalayer
    private getErrorMessageMapping(language = 'ru'): NcalayerErrorMessageMapping[] {
    // todo: после локализации проекта добавить значения для соотвествующиъ языков (сейчас по умолчанию русский).
    // todo: в зависимости от того, как будет реализована локализация проекта, поменять механизм получения сообщений в зависимости от текущего языка
    // todo: по мере пополнения списка сообщений об ошибках ncalayer пополнять коллекцию локализации
        switch (language) {
            case 'ru':
                return [
                    new NcalayerErrorMessageMapping({
                        originalMessage: 'storage.empty',
                        localizedMessage: 'Не найден ЭЦП. Проверьте подключение устройства или вставьте другую карту.',
                    }),
                    new NcalayerErrorMessageMapping({
                        originalMessage: 'action.canceled',
                        localizedMessage: 'Действие отменено',
                    }),
                    new NcalayerErrorMessageMapping({
                        originalMessage: 'storage.unknown',
                        localizedMessage: 'Выбран неизвестный тип хранилища ЭЦП',
                    }),
                ];
        }
    }

    // вызов модалки выбора источника эцп
    private chooseStorage(): Observable<string> {
        const dialogRef = this.dialog.open(DsStorageTypeSelectComponent, {
            width: '500px',
            data: { storageTypes: this.getStorageTypes() },
        });

        return dialogRef.afterClosed();
    }
}
