import { Component, ViewChild } from '@angular/core';
import { DocumentBaseComponent } from '../../base-documnet/create-document-base.component';
import { Router } from '@angular/router';
import { OtherDocumentState } from '../../../state/OtherDocumentState';
import { DocumentMediator } from '../../../services/document-mediator.service';
import { OtherDocumentService } from '../../../../../app/core/services/otherdocument.service';
import { ApiResult } from '../../../../../app/core/models/apiresult.model';
import { AuthService } from '../../../../../app/core/services/auth.service';
import { NotificationService } from '../../../../../app/core/services/notification.service';
import { DxFileUploaderComponent } from 'devextreme-angular';

@Component({
    selector: 'other-document-create',
    templateUrl: './other-document-create.component.html',
    styleUrls: ['./other-document-create.component.scss'],
})
export class OtherDocumentCreateComponent extends DocumentBaseComponent {
    otherDocuments: any[];
    private state: OtherDocumentState;

    constructor(
        private _authService: AuthService,
        private _otherDocumentService: OtherDocumentService,
        private _router: Router,
        private mediator: DocumentMediator,
        private notificationService: NotificationService
    ) {
        super(_authService);
    }

    ngOnInit() {
        this._authService.setBreadcrumb('', 'Создание документа');
        this._authService.getTabs().splice(0);
        this.state = new OtherDocumentState(this.mediator, this.notificationService);
        this.otherDocuments = this.state.otherDocuments;


    }
    onUploadErrorEventHandler(e) {
        if (e.request.status === 401) {
            this._authService.logout();
            this._router.navigate(['/auth']);
        }
    }
    onInitializedEventHandler(e) {
        e.component.instance()._validateMaxFileSize = function (file) {
            const fileSize = file.value.size;
            const maxFileSize = this.option('maxFileSize');
            const maxFileCount = 10;
            if (this._files.length >= maxFileCount) {
                return false;
            }
            return maxFileSize > 0 ? fileSize <= maxFileSize : true;
        };
    }
    getAvailableDocType(): Array<string> {
        if (!this.state.isQrInDocument) {
            return new Array<string>('.jpg', '.jpeg', '.png', '.img', '.bmp', '.docx', '.doc', '.xlsx', '.xls', '.pptx', '.ppt', '.txt', '.pdf', '.rar', '.zip');
        } else {
            return new Array<string>('.docx', '.pdf');
        }
    }
    onUploadedEventHandler(e) {
        const fileAttachmentResult: ApiResult<IFileAttachmentResponse> = <ApiResult<IFileAttachmentResponse>>(
            JSON.parse(e.request.response)
        );
        if (fileAttachmentResult.data) {
            this.errorMessage = '';
            const fileAttachment: IFileAttachmentResponse = fileAttachmentResult.data;
            this.state._fileAttachments.push(fileAttachment);
        } else {
            this.errorMessage = fileAttachmentResult.error.text;
        }
        const element = e.element.getElementsByClassName('dx-fileuploader-files-container');

        for (let index = 0; index < element.length; index++) {
            const item = element[index];
            const elBtn = item.getElementsByClassName('dx-fileuploader-button-container');
            const elName = item.getElementsByClassName('dx-fileuploader-file-name');
            for (let i = 0; i < elName.length; i++) {
                const iElement = elName[i];

                if (e.file.name == iElement.textContent) {
                    elBtn[i].addEventListener('click', this.onAttachmentClicked.bind(this, e.file.name));
                }
            }
        }

        for (let index = 0; index < element.length; index++) {
            const item = element[index];
            const btn = element[index].getElementsByClassName('dx-state-invisible');

            setTimeout(() => {
                if (item.innerHTML.indexOf('dx-state-invisible') > 0) {
                    btn[0].classList.remove('dx-state-invisible');
                }
            }, 500);
        }
    }
    onAttachmentClicked(name) {
        const oneAttachedFile = this.state._fileAttachments.filter(f => f.fileName == name);

        const result = this._otherDocumentService.removeAllAttachments(oneAttachedFile).subscribe(resp => {
            if (resp.data) {
                const index = this.otherDocuments.map(m => m.name).indexOf(name);
                if (index > -1) {
                    this.otherDocuments.splice(index, 1);
                }
                const attachmentsToDelete = this.state._fileAttachments.filter(m => m.fileName === name);
                attachmentsToDelete.forEach(element => {
                    const indexOfElement = this.state._fileAttachments.indexOf(element);
                    this.state._fileAttachments.splice(indexOfElement, 1);
                });
            } else {
                this.errorMessage = resp.error.text;
            }
        });
        this.subscriptions.push(result);
    }

    clearAllAttachments() {
        const result = this._otherDocumentService.removeAllAttachments(this.state._fileAttachments).subscribe(resp => {
            if (resp.data) {
                this.state._fileAttachments.splice(0, this.state._fileAttachments.length);
                this.otherDocuments.splice(0, this.otherDocuments.length);
            } else {
                this.errorMessage = resp.error.text;
            }
        });
        this.subscriptions.push(result);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.state.finalizeState();
    }
    getAvailableDocMimeType() {
        if (this.state.isQrInDocument) {
            return "application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        } else {
            return "image/jpeg,image/jpg,image/png,image/img,image/bmp,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain, application/x-rar-compressed,application/zip,.rar";
        }
    }
}
