import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile-operations.service';
import { NotificationService } from '../../services';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { IProfile } from '../../models/profileresponse.model';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
    @Input() userName: string;
    @Input() showAngle: boolean;
    @Input() showSelectBox = false;
    public src: any;
    public img: any;
    private profiles: IProfile[] = [];
    private currentProfile: IProfile;
    constructor(
        private sanitizer: DomSanitizer,
        private authService: AuthService,
        private notification: NotificationService,
        private router: Router,
        private profileService: ProfileService,
    ) {
        const user = authService.getProfile();
        if (user) {
            this.userName = user.fullName;
            this.currentProfile = user;
            this.src = this.profileService.getAvatar();
        }
    }
    ngOnInit() {
        this.profiles = this.authService.getProfiles();
    }
}
