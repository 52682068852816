import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { email } from '../../../validators';
import { InviteService, NotificationService, AuthService, DocumentProcessingService } from '../../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDialogBase } from '../../../../../app/shared/services/modal-dialog.service';

@Component({
    selector: 'reject-document-component',
    templateUrl: './reject-document.component.html',
    styleUrls: ['./reject-document.component.scss'],
})
export class RejectDocumentDialogComponent extends ModalDialogBase<RejectDocumentRequest> implements OnInit {
    public causeForm: FormGroup;
    public uniqueId = '';
    constructor(
        private formBuilder: FormBuilder,
    ) {
        super();
        this.causeForm = this.formBuilder.group({
            comments: ['', [Validators.maxLength(500)]],
        });
    }
    ngOnInit(): void {
        //throw new Error("Method not implemented.");
    }

    getError(control: AbstractControl, error: string): boolean {
        if (control.touched && control.errors && error in control.errors) {
            return true;
        }
    }
    public cancelClick(): void {
        this.returnResult(null);
    }
    public submitClick(): void {
        const rawValue = this.causeForm.getRawValue();
        const request = new RejectDocumentRequest();
        request.cause = rawValue.comments;
        request.uniqueId = this.uniqueId;
        this.returnResult(request);
    }
}
export class RejectDocumentRequest {
    public cause: string;
    public uniqueId: string;
}
export class RejectDocumentWithSignatureRequest extends RejectDocumentRequest{ 
    public signedCMS: string;
    /**
     *
     */
    constructor(data : RejectDocumentWithSignatureRequest) {
        super();
        Object.assign(this, data);
    }
}
