import { Injectable } from '@angular/core';
import { MediatorBase } from '../../../app/core/mediator/MediatorBase';

@Injectable({
    providedIn: 'root',
})
export class DocumentMediator extends MediatorBase {
    /**
   *
   */
    constructor() {
        super();
        /*        setInterval(() => {
            console.log(this.handlers);
        }, 3000);*/
    }
}
