import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../models/apiresult.model';

@Injectable({
    providedIn: 'root',
})
export class ConsultationService {
    constructor(private http: HttpClient) {}

    consultation(params: { name: string; phone: string }) {
        return this.http.post<ApiResult<boolean>>('/api/consultation/getconsult', params).pipe(
            map(resp => resp),
        );
    }
    consultationPartner(params: { name: string; phone: string }) {
        return this.http.post<ApiResult<boolean>>('/api/consultation/getconsultpartner', params).pipe(
            map(resp => resp),
        );
    }
}
