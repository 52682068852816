import { FormGroup } from '@angular/forms';

export class AvrDetail {
    id: number;
    name: string;
    date: Date;
    info: string;
    unit: string;
    count: number;
    price: string;
    cost: string;
    costWithoutIndirectTaxes: string;
    productionTurnoverRate: string;
    vat: string;
    vatSum: string;
    static createFromForm(formGroup: FormGroup) {
        const avrDetail = new AvrDetail();
        const rawValues = formGroup.getRawValue();
        // TODO KIRILL: create type checking somehow;
        return Object.assign(avrDetail, rawValues);
    }
}
