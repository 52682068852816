import { Directive, OnInit, PipeTransform, Pipe } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[org-contraction]',
})
export class OrganizationContractionsDirective implements OnInit {
    /**
   *
   */
    constructor(private control: NgControl) {}
    ngOnInit(): void {
        this.control.valueChanges.subscribe(r => {
            if (r) {
                const contracted = ContractionsHelper.transformContraction(r);
                if (contracted) {
                    this.control.valueAccessor.writeValue(contracted);
                }
            }
        });
    }
}

@Pipe({
    name: 'orgContractionsPipe',
})
export class OrganizationContractionsPipe implements PipeTransform {
    transform(value: any) {
        const contracted = ContractionsHelper.transformContraction(value);
        if (contracted) {
            return contracted;
        }
        return value;
    }
}

export class ContractionsHelper {
    private static contractions: Contraction[] = [
        { from: 'товарищество с ограниченной ответственностью', to: 'ТОО' },
        { from: 'жауапкершілігі шектеулі серіктестіг', to: 'ЖШС' },
        { from: 'акционерное общество', to: 'АО' },
        { from: 'акционерлік қоғам', to: 'АҚ' },
    ];
    public static transformContraction(data: string) {
        if (!data) {
            return data;
        }
        const lowerCaseValue = data.toLocaleLowerCase();
        const match = ContractionsHelper.contractions.find(d => lowerCaseValue.indexOf(d.from) > -1);
        if (match) {
            return data.replace(new RegExp(match.from, 'i'), match.to);
        }
        return data;
    }
}
class Contraction {
    from: string;
    to: string;
}
