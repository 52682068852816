import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../core/directives/directives.module';
import { LayoutComponent } from './layout/layout.component';
import { HeaderMenuComponent } from './header/header-menu.component';
import { MainViewerNavigatorComponent } from './main-viewer-navigator-component/main-viewer-navigator.component';
import { RouterModule } from '@angular/router';
import {
    MatTabsModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatToolbarModule,
} from '@angular/material';
@NgModule({
    declarations: [LayoutComponent, HeaderMenuComponent, MainViewerNavigatorComponent],
    imports: [CommonModule, DirectivesModule, RouterModule,
        MatTabsModule,
        MatIconModule,
        MatToolbarModule,
        MatMenuModule,
        MatButtonModule,
        MatSelectModule,
        MatFormFieldModule,


    ],
    exports: [LayoutComponent, HeaderMenuComponent, MainViewerNavigatorComponent
    ],
    providers: [],
})
export class LayoutModule {}
