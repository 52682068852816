import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../../../app/core/models/apiresult.model';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class DocumentPackageService {
    /**
     *
     */
    constructor(private http: HttpClient) {

    }

    public downloadDocumentPackage(documentUniqueId: string) {
        return this.http.get<ApiResult<string>>(
            `/api/document/${documentUniqueId}/downloadPackage`,
        ).pipe(switchMap(res => this.http.get(res.data,
            {responseType: 'arraybuffer'}))).subscribe(r => this.downLoadFile(r, 'application/zip', documentUniqueId));
    }
    private downLoadFile(data: any, type: string, documentUniqueId: string) {
        const dataType = type;
        const binaryData = [];
        binaryData.push(data);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download', `${documentUniqueId}.zip`);
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }
    public getPackage(url: string, documentUniqueId: string) {
        this.http.get(url,
            { responseType: 'arraybuffer' }).subscribe(r => this.downLoadFile(r, 'application/zip', documentUniqueId));
    }
}
