import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ModalDialogBase } from '../../../app/shared/services/modal-dialog.service';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { nonZero } from '../../../app/core/validators/nonZero';

@Component({
    selector: 'modal-edit-tsc-data',
    templateUrl: './edit-tsc-data.component.html',
    styleUrls: ['./edit-tsc-data.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class EditTscDataComponent extends ModalDialogBase<EditTscData> implements OnInit {
    public editDataForm: FormGroup;
    public title: string;
    public editingFieldName: string;
    public ctoName: string;
    public errorText: string;
    private balanceInitialValue: number;
    private overdraftInitialValue: number;
    constructor(fb: FormBuilder) {
        super();
        this.editDataForm = fb.group({
            balance: [''],
            overdraft: [''],
            operationSum: ['', [Validators.required, nonZero()]],
            commentary: [''],
        });
    }
    ngOnInit() {
        this.editDataForm.patchValue({
            balance: this.balanceInitialValue,
            overdraft: this.overdraftInitialValue,
        });
    }
    submit() {
        this.editDataForm.markAsTouched();
        if (!this.editDataForm.valid) {
            this.editDataForm.markAsTouched();
            return;
        }
        const value = <EditTscData>this.editDataForm.getRawValue();
        this.returnResult(value);
    }
    cancel() {
        this.returnResult(null);
    }
    setErrorText(errorText: string) {
        this.errorText = errorText;
    }
}
export class EditTscData {
    id: number;
    balance: number;
    overdraft: number;
}
