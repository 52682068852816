import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../models/apiresult.model';

@Injectable({
    providedIn: 'root',
})
export class FileService {
    constructor(private http: HttpClient) {
    }
    getPackUrl(docUniqueId: string, profileId:string) {
        let profileQuery = profileId == null ? "" : `?personUniqueId=${profileId}`;
        return this.http.get<ApiResult<string>>(
            `/api/document/${docUniqueId}/downloadPackage`+ profileQuery,
        );
    }
  getPackByExternalIdUrl(docUniqueId: string, profileId:string) {
    let profileQuery = profileId == null ? "" : `?personUniqueId=${profileId}`;
    return this.http.get<ApiResult<string>>(
      `/api/document/${docUniqueId}/downloadPackageByExternalId`+ profileQuery,
    );
  }
    getFileUrl(uniqueCode: string, fileId: number,profileId:string) {
        let profileQuery = profileId == null ? "" : `personUniqueId=${profileId}&`;
        return this.http.get<ApiResult<string>>(
            `/api/document/${uniqueCode}/downloadFile?${profileQuery}fileId=${fileId}`,
        );
    }
  getPdfFileUrl(uniqueCode: string, fileId: number,profileId:string) {
    let profileQuery = profileId == null ? "" : `personUniqueId=${profileId}&`;
    return this.http.get<ApiResult<string>>(
      `/api/document/${uniqueCode}/downloadPdfFile?${profileQuery}fileId=${fileId}`,
    );
  }
  getFileUrlByExternalId(uniqueCode: string, fileId: number,profileId:string) {
    let profileQuery = profileId == null ? "" : `personUniqueId=${profileId}&`;
    return this.http.get<ApiResult<string>>(
      `/api/document/${uniqueCode}/downloadFileByExternalId?${profileQuery}fileId=${fileId}`,
    );
  }
  getPdfFileUrlByExternalId(uniqueCode: string, fileId: number,profileId:string) {
    let profileQuery = profileId == null ? "" : `personUniqueId=${profileId}&`;
    return this.http.get<ApiResult<string>>(
      `/api/document/${uniqueCode}/downloadPdfFileByExternalId?${profileQuery}fileId=${fileId}`,
    );
  }
    getFileUrlForTemplate(uniqueCode: string, fileId: number,profileId:string) {
        let profileQuery = profileId == null ? "" : `personUniqueId=${profileId}&`;
        return this.http.get<ApiResult<string>>(
            `/api/template/downloadFile?templateUniqueId=${uniqueCode}&${profileQuery}fileId=${fileId}`,
        );
    }
    getPdfFileUrlForTemplate(uniqueCode: string, fileId: number,profileId:string) {
        let profileQuery = profileId == null ? "" : `personUniqueId=${profileId}&`;
        return this.http.get<ApiResult<string>>(
            `/api/template/downloadPdfFile?templateUniqueId=${uniqueCode}&${profileQuery}fileId=${fileId}`,
        );
    }
}
