export enum PermissionsEnum {
    ManageSelfInformation = 0,
    ManageSettings = 1,
    ViewMainPage = 2,
    AccessCabinetServiceCenter = 3,
    AccessCabinetSupportCenter = 4,
    ConvertingToTSC = 5,
    EditingBalance = 6,
    EditingOverdraft = 7,
    AddingMonthlyLimitedPackets = 8,
    ExtendingMonthlyPacketsLimit = 9,
    ChangingPacketType = 10,
}

export enum PacketStatusEnum {
    Created = 0,
    Await = 1,
    Active = 2,
    Expired = 3,
    Revoked = 4,
}
