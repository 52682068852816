import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import {AuthorizedStoreCreator} from '../../../../core/helpers/authorized-store.creator';
import {AuthService} from '../../../../core/services/auth.service';
import {ChangesService} from '../../../../core/services/changes.service';
import {Subscription, of} from 'rxjs';
import {AbstractControl, FormBuilder, FormGroup, NgModel} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ISaveSupport} from '../../../isavesupport';
import {MatDialog} from '@angular/material';
import {DocumentMediator} from '../../../services/document-mediator.service';
import {ContractionsHelper} from '../../../../core/directives/organization-contractions.directive';
import {ModalDialogService} from '../../../../shared/services/modal-dialog.service';
import {InviteService, NotificationService} from '../../../../core/services';
import {switchMap} from 'rxjs/operators';
import {TemplateProcessingService} from '../../../../core/services/template-processing.service';
import {TemplateCard} from '../../../../core/models/template-card.model';
import {TemplateCardState} from '../../../../documents/state/TemplateCardState';
import {MediatorRequest} from "../../../../core/mediator/IMediatorRequest";

@Component({
  selector: 'template-card-create',
  templateUrl: './template-card-create.component.html',
  styleUrls: ['./template-card-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateCardCreateComponent implements OnInit, OnDestroy, ISaveSupport {
  public templateCardForm: FormGroup;
  public errorMessage = '';
  public successMessage = '';
  public dataSource: DataSource;
  public templateCard: TemplateCard = new TemplateCard();
  public today = '';
  documentHash: string;
  private subscriptions: Subscription[] = [];
  private state: TemplateCardState;

  // old
  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private _changesService: ChangesService,
    private _templateProcessingService: TemplateProcessingService,
    private activatedRoute: ActivatedRoute,
    private mediator: DocumentMediator,
    private dialogService: ModalDialogService,
    private notificationService: NotificationService,
    // public dialog: MatDialog,
  ) {
    this.today = new Date().toISOString().substring(0, 10);
  }

  ngOnInit(): void {
    this.state = new TemplateCardState(this.formBuilder, this.mediator);
    this.templateCardForm = this.state.templateCardForm;
    const sub = this.activatedRoute.params.subscribe(x => {
      if (x.uniqueCode != null) {
        this.loadCardByTemplateInfo(x.uniqueCode);
      }
    });
    this.subscriptions.push(sub);
    this.templateCardForm.valueChanges.subscribe(() => {
      if (this.f.name.errors == null && this.f.summary.errors == null) {
        this._changesService.changed = true;
      } else {
        this._changesService.changed = false;
      }
    });
  }

  get documentType(): string {
    return this.state.documentType;
  }

  get f() {
    return this.templateCardForm.controls;
  }

  changeDocWithQr(event) {
    const isQrCodeApplicableToAttachments = this.mediator.send(new MediatorRequest('tryChangeQrAndGetValue', event.target.checked));
    this.templateCardForm.controls["documentWithQr"].setValue(isQrCodeApplicableToAttachments);
  }

  getError(control: AbstractControl, error: string) {
    if (control.touched && control.errors && error in control.errors) {
      return true;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
    this.state.finalizeState();
  }

  public loadCardByTemplateInfo(uniqueCode: string): void {
    const sub = this._templateProcessingService.loadCardInfo(uniqueCode).subscribe(resp => {
      if (resp.data) {
        this.templateCardForm.setValue({
          name: resp.data.name,
          summary: resp.data.summary,
        });
      } else {
        this.errorMessage = resp.error.text;
      }
    });

    this.subscriptions.push(sub);
  }
}
