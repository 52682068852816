import { Component, OnInit } from '@angular/core';
import { ModalDialogBase } from '../../services/modal-dialog.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { password } from '../../../../app/core/validators/password.validator';
@Component({
    selector: 'app-password-guard-dialog',
    templateUrl: './password-guard-dialog.component.html',
    styleUrls: ['./password-guard-dialog.component.scss'],
})
export class PasswordGuardDialogComponent extends ModalDialogBase<string> implements OnInit {
    public passwordConfirmationForm: FormGroup;
    constructor(fb: FormBuilder) {
        super();
        this.passwordConfirmationForm = fb.group({
            password: ['', [Validators.required, password]],
        });
    }

    ngOnInit() {}
    submit() {
        if (this.passwordConfirmationForm.valid) {
            this.returnResult(this.passwordConfirmationForm.get('password').value);
        }
    }
    cancel() {
        this.returnResult(null);
    }
}
