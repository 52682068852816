import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResult } from '../models/apiresult.model';
import { DocumentCard } from '../../core/models/document-card.model';
import { DocumentHistory } from '../../core/models/document-history.model';
import { OtherDocumentCreateComponent } from '../../documents/document-types/other-document/create/other-document-create.component';
import { OtherDocumentEditComponent } from '../../documents/document-types/other-document/edit/other-document-edit.component';
import { OtherDocumentViewComponent } from '../../documents/document-types/other-document/view/other-document-view.component';
import { AvrDocumentCreateComponent } from '../../documents/document-types/avr-document/create/avr-document-create.component';
import { AvrDocumentEditComponent } from '../../documents/document-types/avr-document/edit/avr-document-edit.component';
import { AvrDocumentViewComponent } from '../../documents/document-types/avr-document/view/avr-document-view.component';
import { Router } from '@angular/router';
import { SignEntity } from '../models/signEntity';
import { LetterDocumentCreateComponent } from '../../documents/document-types/letter-document/create/letter-document-create.component';
import { LetterDocumentEditComponent } from '../../documents/document-types/letter-document/edit/letter-document-edit.component';
import { LetterDocumentViewComponent } from '../../documents/document-types/letter-document/view/letter-document-view.component';
import { RejectDocumentRequest, RejectDocumentWithSignatureRequest } from '../shared-component/dialogs/reject-document/reject-document.component';

@Injectable({
    providedIn: 'root',
})
export class DocumentProcessingService {
    constructor(private _http: HttpClient, private router: Router) { }

    getDocumentPrefix(documentType: number) {
        switch (documentType) {
            case 0:
            case 4:
                {
                    return 'other';
                }
            case 2: {
                return 'avr';
            }
            default: {
                // statements;
                break;
            }
        }
    }
    getCreateDocumentDetailsComponent(documentType: string) {
        switch (documentType) {
            case 'other':
                return OtherDocumentCreateComponent;
            case 'avr':
                return AvrDocumentCreateComponent;
            case 'letter':
                return LetterDocumentCreateComponent
            case 'something':
                return null;
        }
    }

    getEditDocumentDetailsComponent(documentType: string) {
        switch (documentType) {
            case 'other':
                return OtherDocumentEditComponent;
            case 'avr':
            return AvrDocumentEditComponent;
            case 'letter':
            return LetterDocumentEditComponent;
            case 'something':
                return null;
        }
    }

    getViewDocumentDetailsComponent(documentType: string) {
        switch (documentType) {
            case 'other':
                return OtherDocumentViewComponent;
            case 'avr':
                return AvrDocumentViewComponent;
            case 'letter':
                return LetterDocumentViewComponent
            case 'something':
                return null;
        }
    }

    createDocument(card: DocumentCard, detail: any, currentDocumentType: string) {
        const request = { card: card, detail: detail };
        console.log('createDocument');
        console.log(request);
        return this._http.post<ApiResult<string>>(`/api/document/create${currentDocumentType}`, request).pipe(
            map(resp => resp),
        );
    }

    editDocument(card: DocumentCard, details: any, currentDocumentType: string, uniqueId: string) {
        const request = { card: card, detail: details };
        const url = `/api/document/edit${currentDocumentType}?uniqueId=${uniqueId}`;
        return this._http.patch<ApiResult<string>>(url, request).pipe(
            map(resp => resp),
        );
    }

    loadCardInfo(uniqueCode: string) {
        return this._http.get<ApiResult<DocumentCard>>(`/api/document/${uniqueCode}/card`).pipe(
            map(resp => resp),
        );
    }

    loadCardInfoAnonymously(uniqueCode: string) {
        return this._http.get<ApiResult<DocumentCard>>(`/api/anonymous/${uniqueCode}/card`).pipe(
            map(resp => resp),
        );
    }

    loadCardInfoAnonymouslyByExternalLink(uniqueCode: string) {
        return this._http.get<ApiResult<DocumentCard>>(`/api/anonymous/${uniqueCode}/CardByExternalId`).pipe(
            map(resp => resp),
        );
    }

    loadHistoryInfo(uniqueCode: string) {
        return this._http.get<ApiResult<DocumentHistory[]>>(`/api/document/${uniqueCode}/history`).pipe(
            map(resp => resp),
        );
    }

    loadHistoryInfoAnonymously(uniqueCode: string) {
        return this._http.get<ApiResult<DocumentHistory[]>>(`/api/anonymous/${uniqueCode}/history`).pipe(
            map(resp => resp),
        );
    }

    getPackUrl(docUniqueId: string, profileUniqueId: string) {
        return this._http.get<ApiResult<string>>(
            `/api/document/${docUniqueId}/downloadPackage?personUniqueId=${profileUniqueId}`,
        );
    }

    getCardUrl(docUniqueId: string) {
        return this._http.get<ApiResult<string>>(`/api/document/GetCardUrl?documentUniqueCode=${docUniqueId}`);
    }

    getAvrUrl(docUniqueId: string) {
        return this._http.get<ApiResult<string>>(`/api/document/GetAvrUrl?documentUniqueCode=${docUniqueId}`);
    }

    getXml(uniqueId: string): Observable<ApiResult<string>> {
        return this._http.get<ApiResult<string>>(`/api/document/getxml?documentUniqueId=${uniqueId}`);
    }

    reject(request: RejectDocumentRequest): Observable<ApiResult<boolean>> {
        return this._http.post<ApiResult<boolean>>(`/api/document/${request.uniqueId}/reject`, request);
    }

    rejectAnonymously(request: RejectDocumentWithSignatureRequest): Observable<ApiResult<boolean>> {
        return this._http.post<ApiResult<boolean>>(`/api/anonymous/${request.uniqueId}/reject`, request);
    }

    retired(uniqueId: string): Observable<ApiResult<boolean>> {
        return this._http.post<ApiResult<boolean>>(`/api/document/retired?documentUniqueId=${uniqueId}`, {});
    }

    public downloadPackage(uniqueCode: string) {
        const url = `/api/document/${uniqueCode}/downloadPackage`;
        const body = { filename: `${uniqueCode}.zip` };

        return this._http.post(url, body, {
            responseType: 'blob',
            headers: new HttpHeaders().append('Content-Type', 'application/json'),
        });
    }

    getSignEntity(uniqueId: string): Observable<ApiResult<SignEntity>> {
        return this._http.get<ApiResult<SignEntity>>(`/api/sign/${uniqueId}`);
    }

    sendToSign2(uniqueId: string, signedXml: string): Observable<ApiResult<boolean>> {
        return this._http.post<ApiResult<boolean>>(`/api/sign/${uniqueId}`, {
            Signature: signedXml,
        });
    }

    sendToSignAnonymously(uniqueId: string, signedXml: string): Observable<ApiResult<boolean>> {
        return this._http.post<ApiResult<boolean>>(`/api/sign/${uniqueId}/anonymously`, {
            Signature: signedXml,
        });
    }


    public downloadDocumentCard(uniqueCode: string): Observable<Blob> {
        // const url = `/api/reporting/documentCard?documentUniqueCode=${uniqueCode}`;
        const url = `http://localhost:5008/api/reporting/documentCard?documentUniqueCode=${uniqueCode}`;
        return this._http.get(url, { responseType: 'blob' }).pipe(
            map(x => x),
        );
    }

    public openDownloaded(blob: Blob) {
        const fileURL = URL.createObjectURL(blob);
        location.href = fileURL;
    }


    public OpenDocument(url: string) {
        this.router.navigateByUrl(url);
    }

    downloadDocumentCardByExternalId(uniqueCode: string) {
        return this._http.get<ApiResult<string>>(`/api/document/GetCardUrlByExternalId?documentUniqueCode=${uniqueCode}`);

    }
}
