import { Component, OnInit, OnDestroy, ViewContainerRef, ViewChild } from '@angular/core';
import { ApiResult } from '../../core/models/apiresult.model';
import { NavigatorResponse } from '../../core/models/navigator.model';
import { NavMenuService } from '../../core/services/navmenu.service';
import { AuthService } from '../../core/services/auth.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
enum DocumentStatus {
    drafts,
    onsigning,
    signed,
    rejected,
    retired,
}

@Component({
    selector: 'main-viewer-navigator',
    templateUrl: './main-viewer-navigator.component.html',
    styleUrls: ['./main-viewer-navigator.component.scss'],
})
export class MainViewerNavigatorComponent implements OnInit, OnDestroy {
    @ViewChild('mainViewerTypeComponent', { read: ViewContainerRef }) mainViewerTypeComponent: ViewContainerRef;
    public inboxItems: NavigatorResponse[] = [];
    public outgoingItems: NavigatorResponse[] = [];
    public templateItems: NavigatorResponse[] = [];
    public profileUniqueId: string;
    public accessServiceCenter: boolean;
    public box: any = { in: 'Входящие', out: 'Иcходящие', templates: 'Шаблоны' };
    public menuBoxInIsOpen = false;
    public menuBoxOutIsOpen = false;
    public menuBoxTemplatesIsOpen = false;
    private subscriptions: Subscription[] = [];
    private _hub: HubConnection;

    constructor(
        private navMenuService: NavMenuService,
        private authService: AuthService,
        private titleService: Title,
        private activatedRoute: ActivatedRoute,
        private http: HttpClient,
        private router: Router,
    ) {
        if (this.authService.getProfile()) {
            this.profileUniqueId = this.authService.getProfile().uniqueId;
        }
    }

    public startConnection = () => {
        this._hub = new HubConnectionBuilder()
            .withUrl('/navHub?profile=' + this.authService.getProfile().uniqueId, {
                accessTokenFactory: () => this.authService.getToken(),
            })
            .build();
        this._hub
            .start()
            .then()
            .catch(err => console.log('Error while starting connection: ' + err));
    };

    ngOnInit(): void {
        this.startConnection();
        this.startHttpRequest();
        this.addDataListener();
    }

    public addDataListener = () => {
        this._hub.on('Inbox', resp => {
            this.updateItem(resp, this.inboxItems);
        });

        this._hub.on('Outgoing', resp => {
            this.updateItem(resp, this.outgoingItems);
        });
        this._hub.on('Template', resp => {
            this.updateItem(resp, this.templateItems);
        });
    };

    scrollToTop() {
      window.scroll(0,0);
    };

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        this._hub.off('navData');
        this._hub.stop();
    }
    public gotoCabinet(): void {
        this.router.navigate(['/cabinet']);
        this.authService.setBreadcrumb('Исходящие', 'На подписании');
    }

    questionClick() { }
    onClickNav(header, text, status) {
        if (this.authService.breadcrumb.getValue().header != header) {
            this.authService.getTabs().splice(0);
            if (this.authService.breadcrumb.getValue().name != text) {
                this.authService.setBreadcrumb(header, text);
            }
        } else {
            if (this.authService.breadcrumb.getValue().name != text) {
                this.authService.setBreadcrumb(header, text);
                this.authService.getTabs().splice(0);
            }
        }
    }

    private updateItem(incomeData: NavigatorResponse, fillData: NavigatorResponse[]): NavigatorResponse[] {
        if (!incomeData) {
            return;
        }
        incomeData.status = incomeData.status.toLowerCase();
        const navItem = fillData.find(f => f.status == incomeData.status);
        navItem.statusText = this.getStatusText(navItem.status);
        navItem.activeClass = navItem.newItemsCount > 0 ? 'active' : '';
        navItem.itemsCount = incomeData.itemsCount;
        navItem.newItemsCount = incomeData.newItemsCount;

        return fillData;
    }
    private startHttpRequest = () => {
        const sub = this.http.get<any>('/api/navigator').subscribe(res => {
            this.inboxItems = this.fillItems(res.data.inDocs);
            this.outgoingItems = this.fillItems(res.data.outDocs);
            this.templateItems = this.fillItems(res.data.tempDocs);
        });
        this.subscriptions.push(sub);
    };


    private fillItems(incomeData: NavigatorResponse[]): NavigatorResponse[] {
        const fillData: NavigatorResponse[] = [];
        if (!incomeData) {
            return;
        }
        for (let i = 0; i < incomeData.length; i++) {
            const navItem: NavigatorResponse = incomeData[i];
            navItem.statusText = this.getStatusText(navItem.status);
            navItem.activeClass = navItem.newItemsCount > 0 ? 'active' : '';

            fillData.push(navItem);
        }
        return fillData;
    }

    private getStatusText(statusCode: string): string {
        switch (statusCode) {
            case 'drafts':
                return 'Черновик';
            case 'onsigning':
                return 'На подписании';
            case 'signed':
                return 'Подписанные';
            case 'rejected':
                return 'Отклоненные';
            case 'retired':
                return 'Отозванные';
            case 'active':
                return 'Активные';
            case 'archive':
                return 'В архиве';
            case 'lettersent':
                return 'Письма'
            default:
                return '';
        }
    }

}
