import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class PersonUniqueIdInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.auth.isLoggedIn() && this.auth.currentUserValue.profile != null) {
            request = request.clone({
                setHeaders: {
                    PersonUniqueId: this.auth.currentUserValue.profile.uniqueId,
                },
            });
        }
        return next.handle(request);
    }
}
