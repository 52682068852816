import { IDocumentStateBase } from './iDocumentStateBase';
import { Subject } from 'rxjs';
import { IMediator } from '../../../app/core/mediator/IMediator';
import { NotificationService } from '../../../app/core/services';
import { MediatorRequest } from '../../core/mediator/IMediatorRequest';

// this is full copy of other document state because they are identical so far.
// I'd prefer to left it as is because it might get changes later
export class LetterDocumentState implements IDocumentStateBase<any> {
  hasAnyChanges: boolean;
  public _fileAttachments: IFileAttachmentResponse[] = [];
  onStateChanged: Subject<any>;
  public otherDocuments: any[] = [];
  isQrInDocument: boolean;

  constructor(private mediator: IMediator, private notificationService: NotificationService) {
    mediator.publish({ type: 'setDocumentType', message: 'other' });
    mediator.handle('getDetailsData', () => this.getAttachments());
    mediator.handle('validateDetailsData', () => this.validate());
    mediator.handle('tryChangeQrAndGetValue', (isQr: boolean) => {
      if (this.validateAttachmentsForQr(isQr)) {
        this.isQrInDocument = isQr;
      }
      return this.isQrInDocument;
    });
    let cardData = this.mediator.send<any>(new MediatorRequest('getCardData'));
    this.isQrInDocument = cardData.documentWithQr;
  }
  getRawValues() {
    const rawValue = {
      _fileAttachments: this._fileAttachments,
      otherDocuments: this.otherDocuments,
    };
    return rawValue;
  }
  finalizeState(): void {
    this.mediator.unHandle('getDetailsData');
    this.mediator.unHandle('validateDetailsData');
    this.mediator.unHandle('tryChangeQrAndGetValue');
  }
  private getAttachments() {
    return { files: this._fileAttachments };
  }

  private validateAttachmentsForQr(qrAttached: boolean) {
    if (qrAttached) {
      var allExtensionAllowed = this._fileAttachments.every(x => x.fileName.endsWith(".pdf") || x.fileName.endsWith(".docx"));
      var allExtensionAllowedInOtherDocs = this.otherDocuments.every(x => x.fileName.endsWith(".pdf") || x.fileName.endsWith(".docx"));
      if (!allExtensionAllowed || !allExtensionAllowedInOtherDocs) {
        this.notificationService.showNotification('Размещение QR-кода возможно только на файлах формата docx и pdf');
        return false;
      }
    }
    return true;
  }

  private validate() {
    if (!this.validateAttachmentsForQr(this.isQrInDocument)) {
      return false;
    }
    if (this.otherDocuments && this.otherDocuments.length > 0) {
      return true;
    }
    if (!this._fileAttachments || !this._fileAttachments.length) {
      this.notificationService.showNotification('Необходимо приложить как минимум один файл');
      return false;
    }
    return true;
  }
}
