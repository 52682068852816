import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../models/apiresult.model';
import { ActivatedRoute } from '@angular/router';
// import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';

@Injectable({
    providedIn: 'root',
})
export class NavMenuService {
    public data: any[];
    constructor(private http: HttpClient, private route: ActivatedRoute) {}
    // public startConnection = () => {
    //   this._hub = new HubConnectionBuilder()
    //       .withUrl('/nerHub')
    //       .build();
    //   this._hub
    //     .start()
    //     .then(() => console.log('Connection started'))
    //     .catch(err => console.log('Error while starting connection: ' + err))
    // }

    // public addDataListener = () => {
    //   this._hub.on('navData', (data) => {
    //     this.data = data;
    //     console.log(data);
    //   });
    // }

    getInbox(profileUniqueId: string) {
        return this.http.get<ApiResult<any>>('/api/navigator?documentGroupType=Inbox');
    }

    getOutgoing(profileUniqueId: string) {
        return this.http.get<ApiResult<any>>('/api/navigator?documentGroupType=Outgoing'); // , { withCredentials: true }
    }
}
