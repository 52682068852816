import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogInviteComponent } from './dialog-invite/dialog-invite.component';
import { DialogProfileChangeComponent } from './dialog-profile-change/dialog-profile-change.component';
import { DialogProfileCreateComponent } from './dialog-profile-create/dialog-profile-create.component';
import { MatSnackBarModule, MatDialogModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipe } from '../../helpers/filter-pipe';
import { DxSelectBoxModule, DxTextBoxModule, DxTemplateModule } from 'devextreme-angular';
import { TypeaheadModule } from 'ngx-bootstrap';
import { DialogAddPacketsComponent } from './dialog-addpackets/dialog-addpackets.component';
import { SelectProfileComponent } from './select-profile/select-profile.component';
import { DirectivesModule } from '../../directives/directives.module';
import { ExtendPacketComponent } from './dialog-extend-packet/extend-packet.component';
import { DialogUpdatePacketTypeComponent } from './dialog-update-packet-type/dialog-update-packet-type.component';

import { SharedModule } from '../../../../app/shared/shared.module';
import { RejectDocumentDialogComponent } from './reject-document/reject-document.component';
@NgModule({
    imports: [
        CommonModule,
        MatSnackBarModule,
        MatDialogModule,
        TypeaheadModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        DxTemplateModule,
        DirectivesModule,
        SharedModule,
    ],
    declarations: [
        FilterPipe,
        DialogProfileCreateComponent,
        DialogProfileChangeComponent,
        // DialogInviteComponent,
        DialogAddPacketsComponent,
        SelectProfileComponent,
        ExtendPacketComponent,
        DialogUpdatePacketTypeComponent,
        RejectDocumentDialogComponent
    ],
    exports: [
        DialogProfileCreateComponent,
        DialogProfileChangeComponent,
        // DialogInviteComponent,
        DialogAddPacketsComponent,
        SelectProfileComponent,
    ],
    entryComponents: [
        DialogProfileCreateComponent,
        DialogProfileChangeComponent,
        // DialogInviteComponent,
        DialogAddPacketsComponent,
        SelectProfileComponent,
        ExtendPacketComponent,
        DialogUpdatePacketTypeComponent,
        RejectDocumentDialogComponent
    ],
})
export class DialogComponentModule {}
