import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, ExtraOptions } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { NotFoundComponent } from './404/NotFoundComponent';
import { DocumentViewPageWithoutAuthComponent } from './documents/page/anonymous-view/document-view-page-without-auth.component';
import { TemplateSignerViewPageComponent } from './documents/template-page/template-signer-view/template-signer-view-page.component';
import { DocumentCheckPageWithoutAuthComponent } from './documents/page/check/document-check-page.component';
const routes: Routes = [
  {
    path: '',
    loadChildren: './landing/landing.module#LandingModule',
  },
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule',
  },
  {
    path: 'cabinet',
    canLoad: [AuthGuard],
    loadChildren: './cabinet/cabinet.module#CabinetModule',
  },
  {
    path: 'anonymousview/:documentType/:uniqueCode',
    data: { title: 'Просмотр документа - SmartContract' },
    component: DocumentViewPageWithoutAuthComponent,
  },
  {
    path: 'check/:documentType/:uniqueCode',
    data: { title: 'Просмотр документа - SmartContract' },
    component: DocumentCheckPageWithoutAuthComponent,
  },
  {
    path: 'template/:uniqueCode',
    data: { title: 'Подписание документа' },
    component: TemplateSignerViewPageComponent,
  },
  {
    path: 'support',
    canLoad: [AuthGuard],
    loadChildren: './support/support.module#SupportModule',
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];
const routesConfiguration: ExtraOptions = {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
};
@NgModule({
  imports: [RouterModule.forRoot(routes, routesConfiguration)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
