import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable, of, throwError, Subscriber, Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { ApiResult } from '../models/apiresult.model';
import { HttpClient } from '@angular/common/http';

interface CallerRequest {
    subscriber: Subscriber<any>;
    failedRequest: HttpRequest<any>;
}

@Injectable()
export class ApiResultInterceptor implements HttpInterceptor {
    private refreshInProgress = false;
    private requests: CallerRequest[] = [];
    constructor(public auth: AuthService, private router: Router, private http: HttpClient) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const observable = new Observable<HttpEvent<any>>(subscriber => {
            const originalRequestSubscription = next
                .handle(req)
                .pipe(
                    map((event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            if (event.status === 200) {
                                const apiResult = <ApiResult<any>>event.body;
                                if (apiResult && apiResult.error) {
                                    switch (+apiResult.error.code) {
                                        case -1:
                                            return throwError(apiResult.error.text);
                                        case 2:
                                            this.handleUnAuthorizedAccess();
                                            break;
                                        case 7:
                                            return throwError('Страница не найдена');
                                        case 12:
                                            this.handleNeedSignOutError();
                                            return throwError(apiResult.error.text);
                                        default:
                                            return event;
                                    }
                                }
                            }
                        }
                        return event;
                    }),
                    catchError(error => {
                        this.handleUnauthorizedError(subscriber, req);
                        return of(error);
                    }),
                )
                .subscribe(
                    response => {
                        subscriber.next(response);
                    },
                    error => {
                        if (error.status == 401) {
                            this.handleUnauthorizedError(subscriber, req);
                        }
                    },
                );

            return () => {
                originalRequestSubscription.unsubscribe();
            };
        });
        return observable;
    }
    private handleNeedSignOutError() {
        this.auth.forceLogout();
    }
    private handleUnAuthorizedAccess() {
        location.href = '/cabinet';
    }
    private handleNotFoundError() {
        this.router.navigate(['/404']);
    }
    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401 || err.status === 403) {
            this.auth.refreshToken().subscribe(resp => {
                if (resp.error) {
                    this.unAuth();
                }
            });
            return of(err.message);
        }

        return throwError(err);
    }
    private unAuth(): void {
        this.auth.logout();
        this.router.navigateByUrl('/auth');
    }

    private handleUnauthorizedError(subscriber: Subscriber<any>, request: HttpRequest<any>) {
        this.requests.push({ subscriber, failedRequest: request });
        if (!this.refreshInProgress) {
            this.refreshInProgress = true;
            this.auth.refreshToken().subscribe(resp => {
                if (resp.data) {
                    this.repeatFailedRequests(resp.data.jwt);
                } else {
                    this.unAuth();
                }

                this.router.events.subscribe(evt => {
                    if (evt instanceof NavigationEnd) {
                        this.router.navigated = false;
                        window.scrollTo(0, 0);
                    }
                });
            });
        }
    }

    private repeatFailedRequests(authHeader) {
        this.requests.forEach(c => {
            const requestWithNewToken = c.failedRequest.clone({
                headers: c.failedRequest.headers.set('Authorization', `Bearer ${authHeader}`),
            });
            this.repeatRequest(requestWithNewToken, c.subscriber);
        });
        this.requests = [];
    }

    private repeatRequest(requestWithNewToken: HttpRequest<any>, subscriber: Subscriber<any>) {
        this.http.request(requestWithNewToken).subscribe(
            res => {
                subscriber.next(res);
            },
            err => {
                if (err.status === 401) {
                    this.auth.logout();
                }
                subscriber.error(err);
            },
            () => {
                subscriber.complete();
            },
        );
    }
}
