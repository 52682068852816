export class DsStorageType {
    displayName: string;
    ncaLayerStorageName: string;

    public constructor(fields?: { displayName?: string; ncaLayerStorageName?: string }) {
        if (fields) {
            Object.assign(this, fields);
        }
    }
}
