export class InviteRequest {
  public senderFullName: string;
  public senderXin: string;
  public inviteClientRequest: InviteClientRequestModel;
  /**
   *
   */
  constructor(data: InviteRequest) {
    Object.assign(this, data);
  }
}
class InviteClientRequestModel {
  email: string;
  xin: string;
  fullName: string;
  comment: string;
  isJuridic: Boolean;
  contragentType: Number;
}
