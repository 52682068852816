import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { email } from '../../../validators';
import { InviteService, NotificationService, AuthService, DocumentProcessingService } from '../../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApiResult } from '../../../models/apiresult.model';
import { ProfileService } from '../../../services/profile-operations.service';
import { custom } from 'devextreme/ui/dialog';

@Component({
    selector: 'dialog-profile-create-component',
    templateUrl: './dialog-profile-create.component.html',
    styleUrls: ['./dialog-profile-create.component.scss'],
})
export class DialogProfileCreateComponent {
    public Form: FormGroup;
    public legal = false;
    constructor(
        private formBuilder: FormBuilder,
        private profileService: ProfileService,
        private authService: AuthService,
        private notification: NotificationService,
        public dialogRef: MatDialogRef<DialogProfileCreateComponent>,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        public dialogInit: MatDialogRef<DialogProfileCreateComponent>,
        @Inject(MAT_DIALOG_DATA) public subject: any,
    ) {
        this.Form = this.formBuilder.group({
            organizationXin: [{ value: '', disabled: true }],
            organizationName: [''],
            xin: [subject.data.serialNumber, [Validators.required, Validators.minLength(12), Validators.pattern('^[0-9]*$')]],
            fio: [subject.data.fullName, Validators.required],
            isIe: [false, Validators.required],
        });
        if (subject.data.bin != null) {
            this.legal = true;
            this.Form.get('organizationXin').setValue(subject.data.bin);
            this.Form.get('organizationName').setValue(subject.data.companyName);
            this.Form.get('organizationName').setValidators([Validators.required, Validators.maxLength(150),
                this.whitespaceValidator]);
            this.Form.get('organizationXin').setValidators([Validators.required]);
        }
    }

    getError(control: AbstractControl, error: string): boolean {
        if (control.touched && control.errors && error in control.errors) {
            return true;
        }
    }
    public cancelClick(): void {
        this.dialogRef.close();
    }
    whitespaceValidator(control: AbstractControl): { whitespace: boolean } {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    getFirstName() {
        if (this.subject != null) {
            const commonName = this.subject.data.commonName;
            if (commonName == null) {
                return null;
            }
            if (commonName.split(' ').length == 2) {
                return commonName.split(' ')[1];
            }
        }
        return null;
    }
    getLastName() {
        if (this.subject != null) {
            const commonName = this.subject.data.commonName;
            if (commonName == null) {
                return null;
            }
            if (commonName.split(' ').length == 2) {
                return commonName.split(' ')[0];
            }
        }
        return null;
    }
    getOrganizationXin() {
        if (this.Form.get('isIe').value === true) {
            return this.subject.data.serialNumber;
        }
        return this.subject.data.bin;
    }
    clickIp() {
        if (this.Form.get('isIe').value === true) {
            this.Form.get('organizationName').setValidators([Validators.required, Validators.maxLength(150), this.whitespaceValidator]);
        } else {
            this.Form.get('organizationName').clearValidators();
            this.Form.get('organizationName').updateValueAndValidity();
        }
    }
    addProfile() {
        const param = {
            'personIin': this.Form.get('xin').value,
            'personLastName': this.getLastName(),
            'personFirstName': this.getFirstName(),
            'personSecondName': this.subject.data.middleName,
            'organizationXin': this.getOrganizationXin(),
            'organizationFullName': this.Form.get('organizationName').value,
        };

        this.profileService.addProfile(param).subscribe(response => {
            if (response.data) {
                this.authService.loadProfile().subscribe(x => {});
                this.notification.customDialog('Профиль добавлен');
                this.dialogRef.close();
            } else {
                this.notification.showSnackNotification(response.error.text);
                this.dialogRef.close();
                return;
            }
        });
    }
}
