import { OnInit, Injector } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AuthService } from '../../../../core/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ILoadSupport } from '../../page/iloadsupported';
import { DocumentProcessingService } from '../../../../core/services/document-processing.service';
import { DocumentPackageService } from '../../../../documents/services/document-package.service';
import CustomStore from 'devextreme/data/custom_store';
import { AuthorizedStoreCreator } from '../../../../core/helpers/authorized-store.creator';
import { NotificationService } from '../../../../core/services';
import { ContractionsHelper } from '../../../../core/directives/organization-contractions.directive';

export abstract class MainViewerBaseComponent implements OnInit, ILoadSupport {
    public dataSource: DataSource;
    public profileUniqueId: string;
    protected authService: AuthService;
    protected titleService: Title;
    protected activatedRoute: ActivatedRoute;
    protected documentService: DocumentProcessingService;
    protected documentPackageService: DocumentPackageService;
    protected store: AuthorizedStoreCreator;
    protected notificationService: NotificationService;

    constructor(injector: Injector) {
        this.authService = injector.get(AuthService)
        this.titleService = injector.get(Title)
        this.activatedRoute = injector.get(ActivatedRoute)
        this.documentService = injector.get(DocumentProcessingService)
        this.documentPackageService = injector.get(DocumentPackageService)
        this.store = injector.get(AuthorizedStoreCreator)
        this.notificationService = injector.get(NotificationService)
        this.profileUniqueId = this.authService.getProfile().uniqueId;
    }

    ngOnInit(): void {
        this.activatedRoute.data.subscribe(data => {
            this.titleService.setTitle(data.title);
        });
    }

    public Load() { }

    onToolbarPreparing(e) {
        const toolbarItems = e.toolbarOptions.items;
        const searchPanel = toolbarItems.filter(item => item.name === 'searchPanel');

        if (searchPanel && searchPanel[0]) {
            searchPanel[0].location = 'before';
        }
    }
    cellClickEvent(e) {
        if (e.row) {
            const notDetail: number = e.row.cells.length - 1;
            const columnIndex = Number(e.columnIndex);
            if (columnIndex !== notDetail) {
                this.goToDocumentCard(e.data);
            }
        }
    }
    goToDocumentCard(data) {
        let url = '/cabinet/view/';
        const docType = Number(data.documentType);
        if (data.documentType !== undefined) {
            const docStatus = Number(data.status);
            const id = this.authService
                .getTabs()
                .map(function (n) {
                    return n.code;
                })
                .indexOf(data.uniqueCode);
            if (id >= 0) {
                this.authService.selectedTab = id;
            } else {
                this.authService.getTabs().push({ 'code': data.uniqueCode, 'docType': docType, 'status': docStatus });
                this.authService.selectedTab = this.authService.getTabs().length - 1;
            }
            switch (docType) {
                case 4:
                case 0:
                    url += 'other/' + data.uniqueCode;
                    break;
                case 2:
                    url += 'avr/' + data.uniqueCode;
                    break;
                case 3:
                    url += 'letter/' + data.uniqueCode;
                    break;
            }
        }
        else {
            url = `/cabinet/template/view/${data.uniqueCode}`;
        }
        this.documentService.OpenDocument(url);
    }
    public detailSrc(data) {
        switch (data.documentType) {
            case 0: {
                return 'other/' + data.uniqueCode;
            }
            case 2: {
                return 'avr/' + data.uniqueCode;
            }
            case 3: {
                return 'letter/' + data.uniqueCode;
            }
            default: {
                // statements;
                break;
            }
        }
    }

    getCardUrl(uniqueCode: string) {
        this.documentService.getCardUrl(uniqueCode).subscribe(s => {
            const res = s.data;
            window.open(res);
        });
    }
    getAvrUrl(uniqueCode: string) {
        this.documentService.getAvrUrl(uniqueCode).subscribe(s => {
            const res = s.data;
            window.open(res);
        });
    }
    getPackUrl(uniqueCode: string) {
        this.documentPackageService.downloadDocumentPackage(uniqueCode);
    }
    getRecipientsStore(value): CustomStore {
        const key = value.data.uniqueCode;
        return this.getOrCreateStore('recipientsDataSource', key);
    }
    private getOrCreateStore(storeName: string, key: string): CustomStore {
        if (!this[storeName]) {
            this[storeName] = [this.createRecipientsStore(key)];
        }
        let item = this[storeName].find(i => i.key === key);
        if (!item) {
            item = this.createRecipientsStore(key);
            this[storeName].push(item);
        }
        return item.dataSourceInstance;
    }
    private createRecipientsStore(key: string) {
        return {
            key: key,
            dataSourceInstance: this.store.createStore({
                key: 'xin',
                loadUrl: `/api/document/getRecipients?documentUniqueCode=` + key,
            }),
        };
    }
    public setSenderContractionForContragent = (val) => ContractionsHelper.transformContraction(val.senderContragentName);
}
