import { NgModule } from '@angular/core';
import { ValidatorInfoComponent } from './validator-info/validator-info.component';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../core/directives/directives.module';
import { PasswordGuardService } from './services/password-guard.service';
import { BsDropdownModule } from 'ngx-bootstrap';
import { AuthGuard } from '../core/guards/auth.guard';
import { OfertPopupComponent } from '../shared/popup/ofert.popup.component';
import { DxPopupModule, DxButtonModule, DxTemplateModule } from 'devextreme-angular';

@NgModule({
  declarations: [ValidatorInfoComponent, OfertPopupComponent],
  imports: [CommonModule, DirectivesModule, DxPopupModule, DxButtonModule, DxTemplateModule],
  exports: [ValidatorInfoComponent, OfertPopupComponent],
  providers: [PasswordGuardService, AuthGuard],
})
export class SharedModule {}
