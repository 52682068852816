import { ApiResult } from './../../../../core/models/apiresult.model';
import { AvrDetail } from './../../../models/avrDetail';
import { Component, OnInit, OnDestroy } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AuthService } from '../../../../core/services/auth.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuthorizedStoreCreator } from '../../../../core/helpers/authorized-store.creator';
import { ISaveSupport } from '../../../isavesupport';
import { custom } from 'devextreme/ui/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AvrState } from '../../../state/AvrState';
import { DocumentMediator } from '../../../services/document-mediator.service';
import { AvrDocumentHttpService } from '../../../../core/http.services/avr-document-http.service';
import { NotificationService } from '../../../../../app/core/services/notification.service';

@Component({
  selector: 'avr-document-edit',
  templateUrl: './avr-document-edit.component.html',
  styleUrls: ['./avr-document-edit.component.scss'],
})
export class AvrDocumentEditComponent implements OnInit, OnDestroy, ISaveSupport {

  public errorMessage = '';
  public successMessage = '';
  public dataSource: DataSource;
  public uploadUrl = '';
  public headers: any;
  otherDocuments: any[] = [];
  public documentCount: number;
  public isEdited = false;
  public contractorInfo: FormGroup;
  public authService: AuthService;
  workList: AvrDetail[] = [];
  public docList: any[];
  public fileAttachments: IFileAttachmentResponse[];
  public contractForm: FormGroup;
  public documentHash: string;
  private state: AvrState;
  private subscriptions: Subscription[] = [];
  private _documentUniqueId = '';

  constructor(
    private _authService: AuthService,
    private fb: FormBuilder,
    private store: AuthorizedStoreCreator,
    private activatedRoute: ActivatedRoute,
    private avrDocumentHttpService: AvrDocumentHttpService,
    private mediator: DocumentMediator,
    private notificationService: NotificationService
  ) {
    this.headers = {
      'Authorization': `Bearer ${this._authService.getToken()}`,
      'PersonUniqueId': this._authService.currentUserValue.profile.uniqueId,
    };
  }

  ngOnInit(): void {
    this.state = new AvrState(this.avrDocumentHttpService, this.fb, this.mediator, this.notificationService);
    this.contractForm = this.state.contractForm;
    this.contractorInfo = this.state.contractorForm;
    this.workList = this.state.completedWorkList;
    this.docList = this.state.docList;
    this.fileAttachments = this.state.fileAttachments;
    const sub = this.activatedRoute.params.subscribe(x => {
      this._documentUniqueId = x.uniqueCode;
      this.uploadUrl = `/api/fileAttachment/addFileToDocument?uniqueCode=${this._documentUniqueId}`;
      this.refreshAttachmentGrid();
    });
    this.subscriptions.push(sub);
    this.avrDocumentHttpService.getAvrDocument(this._documentUniqueId).subscribe(r => {
      this.mediator.publish({ type: 'avrDocumentDataReceived', message: r });
    });
  }

  onUploadedEventHandler(e) {
    const fileAttachmentResult: ApiResult<IFileAttachmentResponse> = <ApiResult<IFileAttachmentResponse>>(
      JSON.parse(e.request.response)
    );
    if (fileAttachmentResult.data) {
      this.errorMessage = '';
      const fileAttachment: IFileAttachmentResponse = fileAttachmentResult.data;
      this.fileAttachments.push(fileAttachment);
    } else {
      this.errorMessage = fileAttachmentResult.error.text;
    }
    const element = e.element.getElementsByClassName('dx-fileuploader-files-container');

    for (let index = 0; index < element.length; index++) {
      const item = element[index];
      const btn = item.getElementsByClassName('dx-state-invisible');

      setTimeout(() => {
        if (item.innerHTML.indexOf('dx-state-invisible') > 0) {
          btn[0].classList.remove('dx-state-invisible');
        }
        this.refreshAttachmentGrid();
        this.isEdited = true;
      }, 500);
      const elBtn = item.getElementsByClassName('dx-fileuploader-button-container');
      const elName = item.getElementsByClassName('dx-fileuploader-file-name');
      for (let i = 0; i < elName.length; i++) {
        const iElement = elName[i];

        if (e.file.name == iElement.textContent) {
          elBtn[i].addEventListener('click', this.deleteAttachment.bind(this, e.file.name));
        }
      }
    }
    this.mediator.publish({ type: 'modelChanged', message: true });
  }

  refreshAttachmentGrid() {
    const store = this.store.createStore({
      key: 'uniqueId',
      loadUrl: `/api/document/${this._documentUniqueId}/attachments`,
      deleteUrl: `/api/fileAttachment?uniqueCode=${this._documentUniqueId}`,
    });
    store.on('loaded', (data: any[]) => {
      this.documentCount = data.length;
    });
    this.dataSource = new DataSource(store);
  }

  deleteAttachment(name) {
    const oneAttachedFile = this.fileAttachments.filter(f => f.fileName == name);

    const result = this.avrDocumentHttpService.removeAllAttachments(oneAttachedFile).subscribe(resp => {
      if (resp.data) {
        const index = this.docList.map(m => m.name).indexOf(name);
        if (index > -1) {
          this.docList.splice(index, 1);
        }
        const attachmentsToDelete = this.fileAttachments.filter(m => m.fileName === name);
        attachmentsToDelete.forEach(element => {
          const indexOfElement = this.fileAttachments.indexOf(element);
          this.fileAttachments.splice(indexOfElement, 1);
        });
      } else {
        this.errorMessage = resp.error.text;
      }
    });
    this.subscriptions.push(result);
  }

  getCountDocument() {
    return this.documentCount;
  }

  ngOnDestroy(): void {
    this.state.finalizeState();
    this.subscriptions.forEach(it => it.unsubscribe());
  }

  onInitializedEventHandler(e) {
    const component = this;
    e.component.instance()._validateMaxFileSize = function (file) {
      const fileSize = file.value.size;
      const maxFileSize = this.option('maxFileSize');
      const maxFileCount = 10;
      const filesSum = (component.documentCount + this._files.length);
      if (filesSum >= maxFileCount) {
        return false;
      }
      return maxFileSize > 0 ? fileSize <= maxFileSize : true;
    };
  }

  onRowRemoving(e) {
    const fileName = e.data.fileName;
    const customDialog = custom({
      title: 'Внимание',
      message: 'Вы действительно хотите удалить файл: ' + fileName + '?',
      buttons: [
        {
          text: 'Да',
          onClick: d => {
            this.isEdited = true;
            return true;
          },
        },
        { text: 'Нет', onClick: d => false },
      ],
    });

    e.cancel = new Promise((resolve, reject) => {
      customDialog
        .show()
        .done(dialogResult => {
          resolve(!dialogResult);
        })
        .fail(x => {
          resolve(true);
        });
    });
    this.mediator.publish({ type: 'modelChanged', message: true });
  }
  onValueChanged = e => {
    const ctrl = this.contractForm.get('inputAppPages');
    if (e.value.length < 1) {
      ctrl.setValue(0);
      ctrl.disable();
    } else {
      ctrl.enable();
    }
  };

  getAvailableDocType(): Array<string> {
    if (!this.state.isQrInDocument) {
      return new Array<string>('.jpg', '.jpeg', '.png', '.img', '.bmp', '.docx', '.doc', '.xlsx', '.xls', '.pptx', '.ppt', '.txt', '.pdf', '.rar', '.zip');
    } else {
      return new Array<string>('.docx', '.pdf');
    }
  }

  getAvailableDocMimeType() {
    if (this.state.isQrInDocument) {
      return "application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    } else {
      return "image/jpeg,image/jpg,image/png,image/img,image/bmp,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain, application/x-rar-compressed,application/zip,.rar";
    }
  }
}
