import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResult } from '../models/apiresult.model';
import { DocumentHistory } from '../../core/models/document-history.model';
import { Router } from '@angular/router';
import { SignEntity } from '../models/signEntity';
import { TemplateCard } from '../models/template-card.model';

@Injectable({
    providedIn: 'root',
})
export class TemplateProcessingService {
    constructor(private _http: HttpClient, private router: Router) {}

    createTemplate(card: TemplateCard, detail: any) {
        const request = { card: card, detail: detail };
        return this._http.post<ApiResult<string>>(`/api/template/createtemplate`, request).pipe(
            map(resp => resp),
        );
    }

    editTemplate(card: TemplateCard, details: any,  uniqueId: string) {
        const request = { card: card, detail: details };
        const url = `/api/template/edittemplate?uniqueId=${uniqueId}`;
        return this._http.post<ApiResult<string>>(url, request).pipe(
            map(resp => resp),
        );
    }

    loadCardInfo(uniqueCode: string) {
        return this._http.get<ApiResult<TemplateCard>>(`/api/template/card?templateUniqueId=${uniqueCode}`).pipe(
            map(resp => resp),
        );
    }

    makeActive(uniqueCode: string){
        return this._http.get<ApiResult<boolean>>(`/api/template/makeactive?uniqueId=${uniqueCode}`).pipe(
            map(resp => resp),
        );
    }
    makeArchive(uniqueCode: string){
        return this._http.get<ApiResult<boolean>>(`/api/template/makearchive?uniqueId=${uniqueCode}`).pipe(
            map(resp => resp),
        );
    }
    getSignEntity(uniqueId: string): Observable<ApiResult<SignEntity>> {
        return this._http.get<ApiResult<SignEntity>>(`/api/sign/templateentity/${uniqueId}`);
    }
    sendToSignTemplate(uniqueId: string, signedXml: string, signerEmail: string): Observable<ApiResult<boolean>> {
        return this._http.post<ApiResult<boolean>>(`/api/sign/${uniqueId}/template?signerEmail=${signerEmail}`, {
            Signature: signedXml,
        });
    }

    public openDownloaded(blob: Blob) {
        const fileURL = URL.createObjectURL(blob);
        location.href = fileURL;
    }


    public OpenDocument(url: string) {
        this.router.navigateByUrl(url);
    }
}
