import { DocumentMediator } from './../../../../services/document-mediator.service';
import { Component, OnInit, Input } from '@angular/core';
import { AvrDetailComponent } from '../../create-avr-detail-dialogue/avr-detail.component';
import { AvrDetail } from '../../../../../../app/cabinet/models/avrDetail';
import { ModalDialogService } from '../../../../../../app/shared/services/modal-dialog.service';

@Component({
    selector: 'avr-info',
    templateUrl: './avr-info.component.html',
    styleUrls: ['./avr-info.component.scss'],
})
export class AvrInfoComponent implements OnInit {
    @Input() completedWorkList: AvrDetail[];
    private today = new Date().toISOString().substring(0, 10);
    constructor(private modalDialogueService: ModalDialogService, private mediator: DocumentMediator) {}

    ngOnInit() {}

    percentageCustomizer(cellInfo) {
        switch (cellInfo.value.toString()) {
            case '0':
                return 'Без НДС';
            case '1':
                return '0%';
            case '2':
                return '12%';
        }
    }
    addWork = () => {
        const modal = this.modalDialogueService.show(AvrDetailComponent, { centered: true, windowClass: 'modal-630' });
        const instance = modal[1] as AvrDetailComponent;
        instance.today = this.today;
        instance.getResult().subscribe((res: AvrDetail) => {
            if (res != null) {
                this.completedWorkList.push(res);
                this.mediator.publish({ type: 'modelChanged', message: true });
            }
            modal[0].close();
        });
    };
    editWork = context => {
        const modelToEdit = {};
        (context.row.cells as any[])
            .map(x => ({ columnName: x.column.dataField, value: x.value }))
            .filter(x => x.columnName)
            .forEach((item: { columnName: string; value: any }) => {
                Object.defineProperty(modelToEdit, item.columnName, {
                    enumerable: true,
                    configurable: false,
                    writable: true,
                    value: item.value,
                });
            });
      const modal = this.modalDialogueService.show2(
        AvrDetailComponent,
        { centered: true, windowClass: 'modal-630' },
        {
          initialData: modelToEdit,
          isEditing: true
        },
      );
        const instance = modal[1] as AvrDetailComponent;
        instance.today = this.today;
        instance.getResult().subscribe((res: AvrDetail) => {
            if (res != null) {
                res.id = this.completedWorkList[context.row.rowIndex].id;
                this.completedWorkList[context.row.rowIndex] = res;
                this.mediator.publish({ type: 'modelChanged', message: true });
            }
            modal[0].close();
        });
    };
}
