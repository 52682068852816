import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
export function untilToday(message?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const date = new Date(value);
    const today = new Date();
    date.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    if (date > today) {
      return { untilToday: message ? message : 'Некорректная дата' };
    }
  };
}
export function minDate(minDateValue: Date, message?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const date = new Date(value);
    if (date < minDateValue) {
      return {
        minDate: message ? message : 'Некорректная дата',
      };
    }
  };
}

export function maxDate(maxDateValue: Date, message?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const date = new Date(value);
    if (date > maxDateValue) {
      return {
        maxDate: message ? message : 'Некорректная дата',
      };
    }
  };
}
