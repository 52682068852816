import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { FileService } from '../../../core/services/file.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { AuthorizedStoreCreator } from '../../../core/helpers/authorized-store.creator';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../../app/core/services/auth.service';
import { DocumentPackageService } from '../../services/document-package.service';
import {DocumentProcessingService} from "../../../core/services";
import {DocumentMediator} from "../../services/document-mediator.service";
@Component({
  selector: 'check-document-view',
  templateUrl: './check-document-view.component.html',
  styleUrls: ['./check-document-view.component.scss'],
})
export class CheckDocumentViewComponent implements OnInit, OnDestroy {
  public dataSource: DataSource;
  public _documentUniqueId = '';
  public uploadUrl = '';
  public isSigned=false;
  public  isSignedByRecepient=false;
  public profileUniqueId: string;
  public documentCount: number;
  otherDocuments: any[] = [];
  private subscriptions: Subscription[] = [];

  constructor(
    private _documentPackageService: DocumentPackageService,
    private store: AuthorizedStoreCreator,
    private baseDocumentService: DocumentProcessingService,
    private fileService:FileService,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private _authService: AuthService,
    private mediator: DocumentMediator,
  ) { }

  ngOnInit(): void {
    const subs = this.activatedRoute.params.subscribe(x => {
      this._documentUniqueId = x.uniqueCode;
      this.uploadUrl = `/api/fileAttachmentTemplate/addFileToTemplate?uniqueCode=${this._documentUniqueId}`;
      this.refreshAttachmentGrid();
     this.subscribeToCardInfoChanging();
    });
    this.subscriptions.push(subs);
    if(this._authService.isLoggedIn()) {
      this.profileUniqueId = this._authService.currentUserValue.profile.uniqueId;
    }
  }

  refreshAttachmentGrid() {
    const store = this.store.createStoreForAnonymous({
      key: 'uniqueId',
      loadUrl: `/api/anonymous/${this._documentUniqueId}/attachmentsByExternalId`,
      deleteUrl: `/api/fileAttachmentTemplate?uniqueCode=${this._documentUniqueId}`,
    });
    store.on('loaded', (data: any[]) => {
      this.documentCount = data.length;
    });
    this.dataSource = new DataSource(store);
  }

  getCountDocument() {
    return this.documentCount;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
  }

  getFileUrl(uniqueCode: string, fileId: number) {
    this.fileService.getFileUrlByExternalId(uniqueCode, fileId, this.profileUniqueId).subscribe(s => {
      const res = s.data;
      window.open(res);
    });
  }
  getDocumentCard(uniqueCode: string){
    this.baseDocumentService.downloadDocumentCardByExternalId(uniqueCode).subscribe(s => {
      const res = s.data;
      window.open(res);
    });

  }
  getEzPackage(uniqueCode:string){
    this.fileService.getPackByExternalIdUrl(uniqueCode,null).subscribe(s => {
      const res = s.data;
      window.open(res);
    });
  }
  public subscribeToCardInfoChanging(): void {
    this.mediator.subscribe('setCardInfo', (info) => {
      if (info.data) {
        this.isSigned =info.data.senderSigned;
        this.isSignedByRecepient=info.data.senderSigned && info.data.recepientSigned;
      }
    })

  }
  getPacByExternalIdkUrl(uniqueCode: string) {
    this.fileService.getPackByExternalIdUrl(uniqueCode,null).subscribe(s => {
      const res = s.data;
      window.open(res);
    });
  }
}
