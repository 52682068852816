import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function email(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const emailVal = control.value;
        const maxLength = 50;
        const regular = !/^([-_0-9a-zA-Z]([.]{0,1}[-_0-9a-zA-Z]){0,63}@([0-9a-zA-Z][-0-9a-zA-Z]{0,61}[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/.exec(
            emailVal,
        );
        if ((emailVal.length && regular) || emailVal.length > maxLength) {
            return { email: 'некорректный адрес электронной почты' };
        }
    };
}
