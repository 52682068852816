import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { FileService } from '../../../../core/services/file.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { AuthorizedStoreCreator } from '../../../../core/helpers/authorized-store.creator';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../../../app/core/services/auth.service';
import { DocumentPackageService } from '../../../services/document-package.service';
import { DocumentMediator } from "../../../services/document-mediator.service";
@Component({
  selector: 'other-document-view',
  templateUrl: './other-document-view.component.html',
  styleUrls: ['./other-document-view.component.scss'],
})
export class OtherDocumentViewComponent implements OnInit, OnDestroy {
  public dataSource: DataSource;
  public _documentUniqueId = '';
  public uploadUrl = '';
  public profileUniqueId: string;
  public documentWithQr = false;
  public documentCount: number;
  public signed = false;
  otherDocuments: any[] = [];
  private subscriptions: Subscription[] = [];

  constructor(
    private _fileService: FileService,
    private _documentPackageService: DocumentPackageService,
    private store: AuthorizedStoreCreator,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private _authService: AuthService,
    private mediator: DocumentMediator,
  ) { }

  ngOnInit(): void {
    const subs = this.activatedRoute.params.subscribe(x => {
      this._documentUniqueId = x.uniqueCode;
      this.uploadUrl = `/api/fileAttachment/addFileToDocument?uniqueCode=${this._documentUniqueId}`;
      this.refreshAttachmentGrid();
      this.subscribeToCardInfoChanging();
    });
    this.subscriptions.push(subs);
    if (this._authService.isLoggedIn()) {
      this.profileUniqueId = this._authService.currentUserValue.profile.uniqueId;
    }
  }

  refreshAttachmentGrid() {
    let url, store;
    if (this._authService.isLoggedIn()) {
      url = `/api/document/${this._documentUniqueId}/attachments`;
      store = this.store.createStore({
        key: 'uniqueId',
        loadUrl: url,
        deleteUrl: `/api/fileAttachment?uniqueCode=${this._documentUniqueId}`,
      });
    } else {
      url = `/api/anonymous/${this._documentUniqueId}/attachments`;
      store = this.store.createStoreForAnonymous({
        key: 'uniqueId',
        loadUrl: url,
        deleteUrl: `/api/fileAttachment?uniqueCode=${this._documentUniqueId}`,
      });
    }

    store.on('loaded', (data: any[]) => {
      this.documentCount = data.length;
    });
    this.dataSource = new DataSource(store);
  }

  getCountDocument() {
    return this.documentCount;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
    this.mediator.unHandle('changeSignedStatus');
  }

  getFileUrl(uniqueCode: string, fileId: number) {
    this._fileService.getFileUrl(uniqueCode, fileId, this.profileUniqueId).subscribe(s => {
      const res = s.data;
      window.open(res);
    });
  }
  getPdfFileUrl(uniqueCode: string, fileId: number) {
    this._fileService.getPdfFileUrl(uniqueCode, fileId, this.profileUniqueId).subscribe(s => {
      const res = s.data;
      window.open(res);
    });
  }
  getPackUrl(uniqueCode: string) {
    this._fileService.getPackUrl(uniqueCode, this.profileUniqueId).subscribe(s => {
      const res = s.data;
      this._documentPackageService.getPackage(res, this._documentUniqueId);
    });
  }
  public subscribeToCardInfoChanging(): void {
    this.mediator.handle('changeSignedStatus', (canBeEdited) => {
      this.signed = !canBeEdited;
    });
    this.mediator.subscribe('setCardInfo', (info) => {
      if (info.data) {
        this.documentWithQr = info.data.documentWithQr;
        this.signed = info.data.signers.some(signer => signer.isSigned === true);
      }
    });
  }
}
