import { Component, OnInit } from '@angular/core';
import { ModalDialogBase } from '../../../../../app/shared/services/modal-dialog.service';
import { ChangePacketTypeRequest } from '../../../../../app/core/models/extendPacketRequest';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import DataSource from 'devextreme/data/data_source';
import { AuthorizedStoreCreator } from '../../../../../app/core/helpers/authorized-store.creator';
import { ServiceCenter } from '../../../../../app/core/services';

@Component({
    selector: 'sc-dialog-update-packet-type',
    templateUrl: './dialog-update-packet-type.component.html',
    styleUrls: ['./dialog-update-packet-type.component.scss'],
})
export class DialogUpdatePacketTypeComponent extends ModalDialogBase<ChangePacketTypeRequest> implements OnInit {
    public form: FormGroup;
    public packetTypeSource: DataSource;
    public clientEmail: string;
    public currentPacket: string;
    public score: string;
    public packetCode: string;
    public currentPacketUniqueId: string;
    public infoText: string;
    private oldPacketCost: any;
    /**
   *
   */
    constructor(fb: FormBuilder, private store: AuthorizedStoreCreator, private serviceCenterService: ServiceCenter) {
        super();
        this.form = fb.group({
            currentPacket: [{ value: '', disabled: true }],
            clientEmail: [{ value: '', disabled: true }],
            invoice: [{ value: '', disabled: true }],
            currentPacketUniqueId: ['', Validators.required],
            code: [''],
            updateOnCode: [''],
        });
    }
    ngOnInit() {
        this.form.controls['clientEmail'].setValue(this.clientEmail);
        this.form.controls['currentPacket'].setValue(this.currentPacket);
        this.form.controls['currentPacketUniqueId'].setValue(this.currentPacketUniqueId);
        this.form.controls['code'].valueChanges.subscribe(d => {
            if (d) {
                const price = d.price;
                const delta = price - this.oldPacketCost.price;

                this.form.controls['updateOnCode'].patchValue(d.code, { emitEvent: false });

                this.form.controls['invoice'].patchValue(delta, { emitEvent: false });
            }
        });

        this.packetTypeSource = new DataSource(
            this.store.createStore({
                key: 'code',
                loadUrl: `/api/packets/getAvailableForChange/${this.packetCode}`,
            }),
        );
        setTimeout(() => {
            this.serviceCenterService.getPacketPrice(this.packetCode).subscribe(r => {
                this.oldPacketCost = r;
            });
        });
    }
    public submit() {
        const value = this.form.getRawValue();
        const resultValue = new ChangePacketTypeRequest({
            clientEmail: value.clientEmail,
            packetUniqueId: value.currentPacketUniqueId,
            updateOnCode: value.updateOnCode,
            invoice: value.invoice,
            currentPacketCode: this.packetCode,
        });
        this.returnResult(resultValue);
    }
    public cancel() {
        this.returnResult(null);
    }
}
