import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../models/apiresult.model';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { EditTscData } from '../../../app/support/edit-tsc-data/edit-tsc-data.component';
import { PasswordModel } from '../../../app/shared/services/password-guard.service';
import { Observable } from 'rxjs';
import { ExtendPacketRequest, RevokePacketRequest, ChangePacketTypeRequest } from '../models/extendPacketRequest';

@Injectable({
    providedIn: 'root',
})
export class ServiceCenter {
    public score: number;
    constructor(private cookieService: CookieService, private http: HttpClient, private router: Router) {}
    public addPacket(param) {
        return this.http.post<ApiResult<boolean>>('/api/packets', param);
    }
    public getInfo() {
        return this.http.get<ApiResult<any>>('/api/serviceCenter/getOrganization');
    }
    public revoke(param: RevokePacketRequest) {
        return this.http.post<ApiResult<boolean>>('/api/packets/revoke', param);
    }
    public getDocumentInfo() {
        return this.http.get<ApiResult<any>>('/api/Support/getDocuments');
    }
    public addScStatus(profileId) {
        return this.http.put<ApiResult<any>>('/api/Support/addScStatus/' + profileId, null);
    }
    public removeScStatus(profileId) {
        return this.http.put<ApiResult<any>>('/api/Support/removeScStatus/' + profileId, null);
    }
    public changeOverdraft(params: PasswordModel<EditTscData>) {
        return this.http.put('/api/Support/editOverdraft', params);
    }
    public changeBalance(params: PasswordModel<EditTscData>) {
        return this.http.put('/api/Support/editBalance', params);
    }
    public extendPacket(extendPacketRequest: ExtendPacketRequest): Observable<any> {
        return this.http.patch('/api/packets/extendPacket', extendPacketRequest);
    }

    public getClientByEmail(email: string): Observable<any> {
        return this.http.get(`/api/serviceCenter/getClientByEmail/${email}`);
    }
    public changePacketType(params: ChangePacketTypeRequest): Observable<any> {
        return this.http.patch('/api/packets/changePacketType', params);
    }
    public getPacketPrice(packetType: string): Observable<any> {
        return this.http.get(`/api/packets/getPriceForPacket/${packetType}`);
    }
}
