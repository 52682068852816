export class TemplateCard {
  summary: string;
  systemRegistrationDate: string;
  name: string;
  updateDate: string;
  authorContragentXin: string;
  authorContragentFullName: string;
  isActive: boolean;
  documentWithQr: boolean;
  version: number;
  canBeSigned: boolean;
  canBeEdited: boolean;
}
