import { Component, OnInit, OnDestroy } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { AuthService } from '../../../../core/services/auth.service';
import { FileService } from '../../../../core/services/file.service';
import { ActivatedRoute } from '@angular/router';
import { AuthorizedStoreCreator } from '../../../../core/helpers/authorized-store.creator';
import { Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DocumentPackageService } from '../../../services/document-package.service';
import {DocumentMediator} from "../../../services/document-mediator.service";
@Component({
  selector: 'template-view',
  templateUrl: './template-view.component.html',
  styleUrls: ['./template-view.component.scss']
})
export class TemplateViewComponent implements OnInit {
  public dataSource: DataSource;
  public _templateUniqueId = '';
  public uploadUrl = '';
  public profileUniqueId: string;
  public documentCount: number;
  private downloadedSet = new Set();
  public documentWithQr=false;
  otherDocuments: any[] = [];
  private subscriptions: Subscription[] = [];
  constructor(
    private _documentPackageService: DocumentPackageService,
    private _authService: AuthService,
    private _fileService: FileService,
    private store: AuthorizedStoreCreator,
    private mediator:DocumentMediator,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    const subs = this.activatedRoute.params.subscribe(x => {
      this._templateUniqueId = x.uniqueCode;
      this.uploadUrl = `/api/fileAttachmentTemplate/addFileToTemplate?uniqueCode=${this._templateUniqueId}`;
      this.refreshAttachmentGrid();
      this.subscribeToCardInfoChanging();
    });
    this.subscriptions.push(subs);
    if(this._authService.isLoggedIn()) {
      this.profileUniqueId = this._authService.currentUserValue.profile.uniqueId;
  }
  }
  refreshAttachmentGrid() {
    const store = this.store.createStoreForAnonymous({
      key: 'uniqueId',
      loadUrl: `/api/template/attachments?templateUniqueId=${this._templateUniqueId}`,
      deleteUrl: `/api/fileAttachmentTemplate?uniqueCode=${this._templateUniqueId}`,
    });
    store.on('loaded', (data: any[]) => {
      this.documentCount = data.length;
    });
    this.dataSource = new DataSource(store);
  }

  getCountDocument() {
    return this.documentCount;
  }
  isAllFilesDownloaded(){
    return this.downloadedSet.size == this.documentCount;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
  }


  getFileUrl(uniqueCode: string, fileId: number, profileUniqueId: string) {
    this._fileService.getFileUrlForTemplate(uniqueCode, fileId, profileUniqueId).subscribe(s => {
      const res = s.data;
      this.markAsDownloaded(fileId);
      window.open(res);
    });
  }
  getPdfFileUrl(uniqueCode: string, fileId: number, profileUniqueId: string) {
    this._fileService.getPdfFileUrlForTemplate(uniqueCode, fileId, profileUniqueId).subscribe(s => {
      const res = s.data;
      this.markAsDownloaded(fileId);
      window.open(res);
    });
  }
  private markAsDownloaded(fileId: number){
      this.downloadedSet.add(fileId);
  }
  public subscribeToCardInfoChanging(): void {
    this.mediator.subscribe('setCardInfo', (info) => {
      if (info.data) {
        this.documentWithQr = info.data.documentWithQr;
      }
    })

  }
}
